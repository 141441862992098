import React, { useEffect, useState } from "react";
import { authService } from "../../fbase";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  margin-top: 20vh;
  margin-bottom: 20vh;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media (min-width: 1500px) {
    margin-top: 30vh;
    margin-bottom: 30vh;
  }

  @media (min-width: 2500px) {
    margin-top: 35vh;
    margin-bottom: 35vh;
  }

  @media (min-width: 3000px) {
    margin-top: 45vh;
    margin-bottom: 45vh;
  }
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 430px;

  .title {
    font-size: 1.9rem;
    font-family: "Pretendard-ExtraBold";
    margin-top: 100px;
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
  }

  .form-content {
    display: flex;
    flex-direction: column;

    label {
      font-family: "Pretendard-Medium";
      margin-bottom: 12px;
    }

    .input-box {
      display: flex;
      flex-direction: column;

      input {
        font-size: 1.1rem;
        color: #848484;
        padding: 0px 10px;
        height: 35px;
        border: 0;
        outline: 1px solid #d6d6d6;
      }

      &:nth-child(1) {
        margin-bottom: 32px;
      }
    }
  }

  input {
    font-size: 1.6rem;
    font-family: "Pretendard-Medium";
    height: 60px;
  }

  .error-message {
    font-size: 1.1rem;
    font-family: "Pretendard-Medium";
    color: red;
    opacity: 0.5;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const SectionButtonsContainer = styled.section`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .button-part {
    width: 100%;

    .submit-button {
      font-size: 1.3rem;
      font-family: "Pretendard-ExtraBold";
      width: 100%;
      height: 62px;
      border-radius: 8px;
      border: 0;
      color: #ffffff;
      background-color: #242424;
      outline: 0;
      cursor: pointer;
    }
  }
`;

const SignUp = () => {
  const history = useHistory();
  const { userInfo } = useSelector((state) => state.user);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      await authService.signInWithEmailAndPassword(email, password);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    if (userInfo) {
      history.goBack();
    }
  }, [history, userInfo]);

  return (
    <Wrapper>
      <Container>
        <div className="title">
          <span>몬스솔라 로그인</span>
        </div>
        <form className="form-content" onSubmit={onSubmit}>
          <div className="input-box">
            <label>아이디</label>
            <input name="email" type="text" placeholder="" required value={email} onChange={onChange}></input>
          </div>

          <div className="input-box">
            <label>비밀번호</label>
            <input name="password" type="password" placeholder="" required value={password} onChange={onChange}></input>
          </div>

          <SectionButtonsContainer>
            <div className="button-part">
              <button className="submit-button" type="submit">
                <span>로그인</span>
              </button>
            </div>
          </SectionButtonsContainer>

          {error && <span className="error-message">이메일, 패스워드를 올바로 기입하여 주십시오</span>}
        </form>
      </Container>
    </Wrapper>
  );
};

export default SignUp;
