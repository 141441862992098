import React from "react";
import ConsultingForm from "../../components/ConsultingForm";
import styled from "@emotion/styled";
import Meta from "../../components/Meta";
import { metaContactData } from "../../utils/metaData";

const DivWrapper = styled.div`
  margin-top: 120px;
  width: 100%;
`;

const Contact = () => {
  return (
    <>
    <Meta data={metaContactData} />
    <DivWrapper>
      <ConsultingForm />
    </DivWrapper>
    </>
  );
};

export default Contact;
