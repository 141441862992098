import React, { useRef, useState, useCallback } from "react";

import styled from "@emotion/styled";

import * as Ani from "./animation";
import ScrollTrigger from "react-scroll-trigger";
import ViewMoreButton from "../../components/ViewMoreButton/ViewMoreButton";
import SunImage from "../../assets/mainpage/HomeAtHome.jpg";

const Wrapper = styled.div`

  .mobile-ani {
    animation-duration: 1.5s;
    animation-name: ${Ani.firstOpacityAndTranslateY};
  }

  .image-container {
    margin-top: 30px;
    width: 100%;
  }

  .first-business-ani {
    animation-duration: 1.5s;
    animation-name: ${Ani.firstOpacityAndTranslateY};
  }

  .image-container-ani {
    animation-duration: 1.5s;
    animation-name: ${Ani.firstOpacityAndTranslateY};
  }

  .first-business {
    margin-top: 60px;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    color: #222222;

    .first-child {
      font-size: 1.7rem;
      font-family: "Pretendard-ExtraBold";
      margin-bottom: 8px;
    }
    .second-child {
      font-size: 1.4rem;
      font-family: "Pretendard-ExtraBold";
      margin-bottom: 24px;
    }
    .third-child {
      font-size: 1.1rem;
      font-family: "Pretendard-Light";
      color: #505050;
      margin-bottom: 24px;
      line-height: 25px;
    }

    .first-ani {
      animation-duration: 1.5s;
      animation-name: ${Ani.firstOpacityAndTranslateY};
    }
    .second-ani {
      animation-duration: 1.7s;
      animation-name: ${Ani.secondOpacityAndTranslateY};
    }
    .third-ani {
      animation-duration: 1.7s;
      animation-name: ${Ani.thirdOpacityAndTranslateY};
    }
  }

  @media (min-width: 865px) {
    border: none;
    width: 400px;
    height: 600px;
    z-index: 1;

    .image-container {
      margin-top: 0px;
    }

    .first-business-ani {
      animation-duration: 1.5s;
      animation-name: ${Ani.minWidthFirstBusiness};
    }
    .image-container-ani {
      animation-duration: 1.5s;
      animation-name: ${Ani.minWidthFirstBusiness};
    }

    .first-business {
      height: 45%;
      margin-top: 30px;
      margin-bottom: 0px;
      padding-bottom: 0px;

      .third-child {
        margin-bottom: 30px;
      }
    }
  }
`;

const DivImageContainer = styled.div`
  width: 100%;
  overflow: hidden;

  @media (min-width: 865px) {
    height: 50%;
  }
`;

const SunAtHome = ({ refSun }) => {
  const testRef = useRef();
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const onTriggerAnimate = useCallback(() => {
    if (!triggerAnimation) setTriggerAnimation((prev) => !prev);
  }, [triggerAnimation]);

  return (
    <ScrollTrigger onEnter={onTriggerAnimate}>
      <Wrapper ref={testRef} triggerAnimation={triggerAnimation}>
        <div
          ref={refSun}
          className={triggerAnimation ? "first-business first-business-ani" : "first-business"}
          style={{ width: "100%" }}
        >
          <span className={triggerAnimation ? "first-child first-ani" : "first-child"}>태양광발전사업</span>
          <span className={triggerAnimation ? "second-child second-ani" : "second-child"}>(RPS/FIT)</span>
          <div className={triggerAnimation ? "third-child third-ani" : "third-child"}>
            <span>
              저렴한 유지관리 비용으로 안정적인<br></br>수익을 얻을 수 있는 사업입니다.
            </span>
          </div>

          {/* css 버튼 만들기 */}
          <ViewMoreButton triggerAnimation={triggerAnimation} pagePath={"business"} componentPath={"sun"} />
        </div>

        <DivImageContainer>
          <img
            src={SunImage}
            className={triggerAnimation ? "image-container image-container-ani" : "image-container"}
          />
        </DivImageContainer>
      </Wrapper>
    </ScrollTrigger>
  );
};

export default SunAtHome;
