import React from "react";
import styled from "@emotion/styled";

import ViewMoreButton from "../ViewMoreButton/ViewMoreButton";

import { buildingDataContainer, homeDataContainer, sunDataContainer } from "./businessExampleRawData";

const DivWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .main-container {
    width: 100%;

    .desktop {
      display: none;
    }

    .main-title {
      font-size: 1.4rem;
      font-family: "Pretendard-ExtraBold";
      margin-top: 35px;
      margin-bottom: 30px;
      padding-left: 1rem;

      .big-title {
        font-size: 1.4rem;
        font-family: "Pretendard-ExtraBold";
        margin-bottom: 12px;
      }
      .small-title {
        display: none;
      }
    }

    .index-more-rendering {
      display: flex;
      justify-content: center;
    }

    .main-contents {
      display: flex;
      flex-direction: column;

      .content {
        margin-bottom: 40px;
        padding-right: 1rem;
        padding-left: 1rem;

        .content-text {
          margin-top: 25px;
          display: flex;
          flex-direction: column;

          .content-main-title {
            font-size: 1.3rem;
            font-family: "Pretendard-Medium";
            margin-bottom: 10px;
          }

          .content-sub-title {
            font-size: 1rem;
            font-family: "Pretendard-Medium";
            color: #747474;
          }
        }
      }
    }
  }

  @media (min-width: 765px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;

    .main-container {
      .desktop {
        display: flex;
      }

      .main-title {
        padding-left: 0rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .small-title {
          font-size: 1.1rem;
          font-family: "Pretendard-Medium";
          margin-bottom: 30px;
          display: block;
        }
      }

      .main-contents {
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        justify-content: center;

        .content {
          width: 27%;

          .content-text {
            .content-main-title {
              font-size: 1.1rem;
              margin-bottom: 10px;
            }

            .contetn-sub-title {
              font-size: 0.95rem;
              color: #747474;
            }
          }
        }
      }

      .index-more-rendering {
        display: flex;
        justify-content: center;
      }

      .mobile {
        display: none;
      }
    }
  }
`;

const BusinessExample = ({ query }) => {
  return (
    <DivWrapper>
      <div className="main-container">
        <div className="main-title">
          <span className="big-title">시공사례</span>
          <span className="small-title">몬스솔라에서 설치한 시공사례입니다.</span>
        </div>

        {/* 모바일과 데스크톱 분기처리  */}
        <div className="main-contents">
          {query === "sun"
            ? sunDataContainer
            : query === "home"
            ? homeDataContainer
            : buildingDataContainer.map((renderingItem) => {
                return renderingItem;
              })}
        </div>

        <div className="index-more-rendering">
          <div>
            <ViewMoreButton pagePath={"project"} />
          </div>
        </div>
      </div>
    </DivWrapper>
  );
};

export default BusinessExample;
