import React, { useCallback, useEffect, useRef, useState } from "react";
import ToastEditor from "../../components/ToastEditor";
import { dbService } from "../../fbase";
import styled from "@emotion/styled";
import PostsContainer from "../../components/Project/PostsContainer";
import { useSelector } from "react-redux";

import MainBannerCarousel from "../../components/Project/MainBannerCarousel";

import Modal from "react-modal";
import Arrow from "../../components/Generals/Arrow";
import { isTablet } from "react-device-detect";
import Meta from "../../components/Meta";
import { metaProjectData } from "../../utils/metaData";
import blackArrow from "../../assets/contactpage/blackArrow.png";

const DivWrapper = styled.div`
  /* 헤더와 간격을 띄우기 위해  */
  padding-bottom: 100px;
`;

const DivBannerText = styled.div`
  margin-bottom: 7.5vh;
  position: ${(props) => (props.editorOpen ? "static" : "relative")};
  width: 100%;
  height: 100vh;
  z-index: 99;

  .text-container {
    height: 100%;
    padding-left: 1.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;

    .first-text {
      font-size: 1.1rem;
      font-family: "Pretendard-Medium";
      margin-bottom: 0.5em;
    }
    .second-text {
      font-size: 2.1rem;
      font-family: "Pretendard-Bold";
    }
    .routing-button {
      border: 2px solid gray;
      width: 130px;
    }
  }

  @media (min-width: 765px) {
    margin-bottom: calc(10vh + 48px);

    height: 95vh;

    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .first-text {
        font-size: 1.33rem;
        font-family: "Pretendard-Medium";
        margin-bottom: 15px;
        color: #ffffff;
      }

      .second-text {
        font-size: 2.8rem;
        font-family: "Pretendard-Bold";
        .mobile-br {
          display: none;
        }
      }

      .routing-button {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`;

const SectionMainContainer = styled.section`
  position: relative;

  .project-title {
    font-size: 1.4rem;
    font-family: "Pretendard-Bold";
    padding-left: 1rem;
  }

  .only-admin-see-editor {
    font-family: "Pretendard-Medium";
    padding: 0.5rem;
    position: absolute;
    top: 0px;
    right: 20px;
    color: #ffffff;
    border-radius: 10px;
    background-color: #898989;
    cursor: pointer;
  }

  .category-filter {
    margin-top: 20px;
    padding-left: 1rem;
    padding-right: 1rem;

    & select {
      font-family: "Pretendard-Medium";
      width: 100%;
      padding-left: 10px;
      height: 35px;
      outline: 1px solid #d6d6d6;
      border: 0;

      -webkit-appearance: none; /* for chrome */

      -moz-appearance: none; /*for firefox*/

      appearance: none;
    }

    & select::-ms-expand {
      display: none; /*for IE10,11*/
    }

    & select {
      background: url(${blackArrow}) no-repeat 98.5% 50%/15px auto;
    }

    .category-ul {
      margin-top: 30px;
      display: flex;

      li {
        cursor: pointer;
        font-family: "Pretendard-Light";
        padding-right: 0.5rem;
        color: #b2abaa;
      }

      .li-bold {
        color: black;
        font-family: "Pretendard-ExtraBold";
      }
    }

    .desktop-selector {
      display: none;
    }
  }

  @media (min-width: 765px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
    position: relative;
    height: 70px;

    .only-dt-justify-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .project-title {
      font-size: 1.9rem;
      font-family: "Pretendard-Bold";
    }

    .only-admin-see-editor {
      padding: 0.5rem 1.2rem;
      position: absolute;

      top: 50px;
      right: 15px;
      color: #ffffff;
      border-radius: 5px;
      background-color: #898989;
    }

    .category-filter {
      margin-top: 0px;

      .category-ul {
        margin-top: 0px;

        li {
          padding: 0 1rem;
        }
      }
      .desktop-selector {
        display: flex;
      }
      .mobile-selector {
        display: none;
      }
    }
  }
`;

const SectionPostConatiner = styled.section`
  margin-top: 50px;

  .post-wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(100px, auto);

    gap: 30px 14px;
  }

  @media (min-width: 965px) {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;

    .post-wrapper {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 71px;
    }
  }
`;

const StyleArrow = styled(Arrow)``;

const Project = () => {
  const refScrollToProject = useRef();

  const userInfo = useSelector((state) => state.user.userInfo);
  const [modalEditorOpen, setModalEditorOpen] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState("전체");

  const toggleModalEditorOpen = useCallback(async () => {
    const currentOffset = refScrollToProject.current.offsetTop;
    const policyAboutHeight = 80; //임의대로 100을 더 뺐음. //currentWidth > 1023 ? -600 :

    window.scrollTo({ top: currentOffset - policyAboutHeight, behavior: "smooth" });

    setTimeout(() => {
      setModalEditorOpen((prev) => !prev);
    }, 500);
  }, []);

  const [posts, setPosts] = useState([]); // 이게 기준점이 되고, 얘를 렌더링하지 않을거다.
  const [selectedPosts, setSelectedPosts] = useState([]); // 애를 렌더링할거다

  const getPostsByFirebase = useCallback(() => {
    dbService
      .collection("projects")
      .orderBy("createdAt", "desc")
      .onSnapshot((snapShot) => {
        const postArray = snapShot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPosts(postArray);
        if (selectedPosts.length === 0) {
          setSelectedPosts(postArray);
        }
      });
  }, [selectedPosts]);

  useEffect(() => {
    getPostsByFirebase();
  }, []);

  const onCategoryFilter = useCallback(
    (e) => {
      let temp_category = e.currentTarget.dataset.id ? e.currentTarget.dataset.id : e.target.value;
      setCategoryFilter(temp_category);
      if (temp_category === "전체") {
        setSelectedPosts(posts);
      } else {
        let temp_arr = posts.filter((post) => post.category === temp_category);
        setSelectedPosts(temp_arr);
      }
    },
    [posts]
  );

  return (
    <>
      <Meta data={metaProjectData} />
      <DivWrapper>
        {/* 이미지 섹션 */}
        <DivBannerText editorOpen={modalEditorOpen}>
          <div className="text-container">
            <span className="first-text mobile">몬스솔라에서 진행한 프로젝트입니다.</span>
            <span className="second-text mobile">
              Monssolar<br className="mobile-br"></br> Project
            </span>

            <StyleArrow
              editorOpen={modalEditorOpen}
              place={"project"}
              isTablet={isTablet}
              refObj={refScrollToProject}
            />
          </div>
        </DivBannerText>

        <MainBannerCarousel />

        {/* 포스틍 작성 섹션 */}
        <SectionMainContainer ref={refScrollToProject}>
          <div className="only-dt-justify-content">
            <span className="project-title">Monssolar Project</span>
            {/* 어드민만 볼 수 있는 에디터 버튼 - 글쓰기 */}
            {userInfo && (
              <div className="only-admin-see-editor" onClick={toggleModalEditorOpen}>
                에디터
              </div>
            )}
            {modalEditorOpen && (
              <div>
                <Modal
                  shouldCloseOnOverlayClick={false}
                  shouldCloseOnEsc={false}
                  preventScroll={true}
                  isOpen={modalEditorOpen}
                  onRequestClose={toggleModalEditorOpen}
                  style={customStyles}
                >
                  <ToastEditor initValue={null} postId={null} toggleModalEditorOpen={toggleModalEditorOpen} />
                </Modal>
              </div>
            )}
            <div className="category-filter">
              <select className="mobile-selector" value={categoryFilter} onChange={onCategoryFilter}>
                <option value="전체">전체</option>
                <option value="태양광발전사업(RPS/FIT)">태양광발전사업</option>
                <option value="주택지원사업">주택지원사업</option>
                <option value="건물지원사업">건물지원사업</option>
              </select>
              <ul className="category-ul desktop-selector">
                <li onClick={onCategoryFilter} data-id="전체" className={categoryFilter === "전체" ? "li-bold" : ""}>
                  전체
                </li>
                <li
                  onClick={onCategoryFilter}
                  data-id="태양광발전사업(RPS/FIT)"
                  className={categoryFilter === "태양광발전사업(RPS/FIT)" ? "li-bold" : ""}
                >
                  태양광발전사업
                </li>
                <li
                  onClick={onCategoryFilter}
                  data-id="주택지원사업"
                  className={categoryFilter === "주택지원사업" ? "li-bold" : ""}
                >
                  주택지원사업
                </li>
                <li
                  onClick={onCategoryFilter}
                  data-id="건물지원사업"
                  className={categoryFilter === "건물지원사업" ? "li-bold" : ""}
                >
                  건물지원사업
                </li>
              </ul>
            </div>
          </div>
        </SectionMainContainer>

        {/* 포스팅 렌더링 섹션 */}
        <SectionPostConatiner>
          <div className="post-wrapper">
            {selectedPosts.map((post, index) => {
              return <PostsContainer key={post.id} index={index} post={post} />;
            })}
          </div>
        </SectionPostConatiner>
      </DivWrapper>
    </>
  );
};

export default Project;

// 모달 스타일
const customStyles = {
  content: {
    zIndex: "998888888849382572390845708943257098324750982347508923457998899",
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
