import React from "react";
import { useLocation } from "react-router";
import styled from "@emotion/styled";

import Sun from "../../components/Business/Sun";
import Building from "../../components/Business/Building";
import Home from "../../components/Business/Home";

import DesktopMainBannerText from "../../components/Business/DesktopMainBannerText";
import MobileImageCarousel from "../../components/Business/MobileImageCarousel";
import SunAtBusiness from "../../components/Business/SunAtBusiness";
import BuildingAtBusiness from "../../components/Business/BuildingAtBusiness";
import HomeAtBusiness from "../../components/Business/HomeAtBusiness";
import MainBannerCarousel from "../../components/Business/MainBannerCarousel";

import bannerImage from "../../assets/businesspage/solution_banner.jpg";
import Meta from "../../components/Meta";
import { metaBusinessData } from "../../utils/metaData";

const DivWrapper = styled.div`
  overflow: hidden;
  /* 헤더와 간격을 띄우기 위해  */
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DivImage = styled.div`
  position: relative;
  width: 100%;
  height: 70vh;

  .styled-dt-main-banner-text {
    position: absolute;
  }

  .text-container {
    z-index: 989898;

    position: absolute;
    top: 30%;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    transform: translate(-50%, -50%);

    .first-text {
      font-size: 1.1rem;
      font-family: "Pretendard-Medium";
      margin-bottom: 4px;
      color: #ffffff;
    }
    .second-text {
      font-size: 2.1rem;
      font-family: "Pretendard-Bold";
      
      color: #ffffff;
    }
  }

  @media (min-width: 765px) {
    height: 100vh;

    .text-container {
      z-index: 99999;
      top: 36%;

      .first-text {
        font-size: 1.33rem;
        font-family: "Pretendard-Light";
        margin-bottom: 7px;
        color: #ffffff;
      }
      .second-text {
        font-size: 2.9rem;
        font-family: "Pretendard-Bold";
        color: #ffffff;
      }
    }
  }
`;

const SectionImageContainer = styled.section`
  position: relative;

  @media (min-width: 765px) {
    display: none;
  }
`;

const DivImageContainer = styled.div`
  position: absolute;
  z-index: 98989898;
  width: 100%;
  height: 100%;

  @media (min-width: 765px) {
    .image-wrapper {
      display: none;
    }
  }
`;

const SectionTextContainer = styled.section`
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;

  max-width: 1000px;

  .desktop-text-container {
    display: none;
  }

  /* 모바일 일 떄 */
  .text-container {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;

    .first-text {
      font-size: 1.45rem;
      font-family: "Pretendard-ExtraBold";
      color: #222222;
      line-height: 30px;
    }

    .first-bottom {
      margin-bottom: 34px;
    }
    .second-text {
      font-size: 1.06rem;
      font-family: "Pretendard-Light";
      font-weight: 200;
      line-height: 25px;
      color: #222222;
    }

    hr {
      margin-top: 50px;
      margin-bottom: 50px;
      color: #e5e5e5;
      width: 100%;
      height: 1px;
    }
  }

  @media (min-width: 765px) {
    margin-top: 160px;

    /* 데스크톱일 때 */
    .desktop-text-container {
      margin-top: 100px;
      margin-bottom: 100px;
      display: flex;
      justify-content: space-between;

      .desktop-first-content {
        padding-top: 5px;
        width: 50%;
        display: flex;
        flex-direction: column;

        .dt-first-text {
          font-size: 1.6rem;
          font-family: "Pretendard-ExtraBold";
          line-height: 35px;
          padding-bottom: 12px;
          color: #222222;
        }
      }

      .desktop-text-wrapper {
        margin-top: 0px;
        padding-top: 0px;
        font-size: 1.1rem;
        font-family: "Pretendard-Medium";
        width: 60%;
        color: #222222;
        line-height: 29px;
        margin-left: 40px;
      }
    }

    .text-container {
      display: none;
    }
  }
`;

const SectionBusinessContainer = styled.section`
  margin: 0 auto;
  max-width: 1000px;

  @media (min-width: 840px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const StyledDesktopMainBannerText = styled(DesktopMainBannerText)`
  z-index: 98989898989898989898989898989;
`;
//

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Business = () => {
  let query = useQuery();

  // 다시 쿼리를 넣은 이유는 테이블도 구분지어서 분기처리하기 위함임
  if (query.get("name") === "sun") {
    return <Sun query={query.get("name")} />;
  } else if (query.get("name") === "building") {
    return <Building />;
  } else if (query.get("name") === "home") {
    return <Home />;
  } else {
    return (
      <>
        <Meta data={metaBusinessData} />
        <DivWrapper>
          {/* 메인 배너 */}
          <div>
            <DivImage>
              <div className="text-container">
                <span className="first-text">몬스솔라 사업영역을 소개합니다</span>
                <span className="second-text">사업영역</span>
              </div>

              <StyledDesktopMainBannerText className="styled-dt-main-banner-text" />
            </DivImage>
            <MainBannerCarousel />
          </div>

          {/* 이놈은 모바일 사이즈에서만 나오는 거임 */}
          <SectionImageContainer>
            {/* 뒤에 전구 이미지 - 캐러셀 되는 게 아님 */}
            <DivImageContainer>
              <div
                className="image-wrapper"
                style={{
                  background: `url(${bannerImage}) no-repeat center`,
                  backgroundSize: "cover",
                  width: "100%",
                  height: "100%",
                }}
              />
            </DivImageContainer>

            {/* 텍스트만 캐러셀 됨 */}
            <MobileImageCarousel />
          </SectionImageContainer>

          <SectionTextContainer>
            <div className="text-container">
              <span className="first-text">고객의 니즈에 적합한</span>
              <span className="first-text first-bottom">최적의 발전시스템을 제공합니다.</span>
              <span className="second-text">
                태양광 발전소에서 생산한 전기를 상업적인 목적으로 한국전력 및 공급의무자에게 판매하여 수익을 창출하는
                사업입니다. 설치 지역의 조건에 맞는 적합한 제품과 설치형태를 검토하여 최적의 비용을 산출함과 동시에
                최상의 수익률을 제공해 드립니다.
              </span>
              <hr />
            </div>

            <div className="desktop-text-container">
              <div className="desktop-first-content">
                <span className="dt-first-text">고객의 니즈에 적합한</span>
                <span className="dt-first-text">최적의 발전시스템을 제공합니다.</span>
              </div>

              <div className="desktop-text-wrapper">
                <span>
                  주식회사 몬스솔라는 태양광 발전사업 기획부터 시공 후 점검까지 태양광 발전사업 전반에 걸친 솔루션을
                  제공하며, 다년간의 노하우를 통해 고객의 니즈에 적합한 최적의 발전시스템 설계는 고객만족을 목표로 하는
                  주식회사 몬스솔라의 핵심역량입니다.
                </span>
              </div>
            </div>
          </SectionTextContainer>

          <div>
            <SectionBusinessContainer>
              <SunAtBusiness />
              <BuildingAtBusiness />
              <HomeAtBusiness />
            </SectionBusinessContainer>
          </div>
        </DivWrapper>
      </>
    );
  }
};

export default Business;
