import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import LinkContainer from "../LinkContainer/LinkContainer";

const Wrapper = styled.div`
  display: flex;

  max-width: 1090px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  justify-content: space-between;
  align-items: center;
  font-family: "Pretendard-Light";

  .sub-nav-container {
    display: flex;
    margin-top: 17px;
    color: #ffffff;
  }

  .apply-padding {
    padding-right: ${(props) => (props.pathname === "/business" ? "8rem" : "-1rem")};
  }

  .list {
    margin-right: 40px;
  }

  span {
    &:nth-last-of-type(1) {
      margin-right: 0px;
    }
  }
`;

const SpanText = styled.span`
  font-size: 15.5px;

  color: ${(props) => (props.pathname === "/business" ? "gray" : "#ffffff")};

  .hover-item {
    &:hover {
      font-family: "Pretendard-Bold";
    }
  }

  .clicked-0 {
    font-family: ${(props) => (props.clickedMenu === 0 ? "Pretendard-Bold" : "Pretendard-Light")};
  }
  .clicked-1 {
    font-family: ${(props) => (props.clickedMenu === 1 ? "Pretendard-Bold" : "Pretendard-Light")};
  }
  .clicked-2 {
    font-family: ${(props) => (props.clickedMenu === 2 ? "Pretendard-Bold" : "Pretendard-Light")};
  }
  .clicked-3 {
    font-family: ${(props) => (props.clickedMenu === 3 ? "Pretendard-Bold" : "Pretendard-Light")};
  }
  .clicked-4 {
    font-family: ${(props) => (props.clickedMenu === 4 ? "Pretendard-Bold" : "Pretendard-Light")};
  }
`;

const queryIndex = ["kra-krb", "krd", "kra", "50k", "500k"];
const queryIndexBusiness = ["sun", "home", "building"];
const businessLists = ["태양광발전사업(RPS/FIT)", "주택지원사업", "건물지원사업"];
const productLists = ["ESP3K5-KRA/KRB", "ESP3K5-KRD", "ESP-KRA", "ESP50K", "ESP500K"];

const SubNavHeader = ({ lists, pathname, search }) => {
  const [clickedMenu, setClickedMenu] = useState("");

  const onClickedMenu = useCallback(
    (e) => {
      let standLists = businessLists;
      if (pathname !== "/business") {
        standLists = productLists;
      }

      setClickedMenu(standLists.indexOf(e.target.innerText));
    },
    [pathname]
  );

  useEffect(() => {
    let initialState = pathname === "/business" ? queryIndexBusiness.indexOf(search) : queryIndex.indexOf(search);
    setClickedMenu((prev) => initialState);
  }, [pathname, search]);
  return (
    <Wrapper pathname={pathname}>
      <div className="fake"></div>

      <div className="sub-nav-container apply-padding product-padding">
        {lists.map((el, index) => {
          return (
            <span className="list" key={index + el}>
              <LinkContainer
                pagePath={pathname.slice(1)}
                componentPath={pathname === "/business" ? queryIndexBusiness[index] : queryIndex[index]}
              >
                <SpanText
                  onClick={onClickedMenu}
                  clickedMenu={clickedMenu}
                  search={search}
                  identifier={index}
                  pathname={pathname}
                >
                  <span className={`hover-item clicked-${index}`}>{el}</span>
                </SpanText>
              </LinkContainer>
            </span>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default SubNavHeader;
