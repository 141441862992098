import monsLogo from "../../assets/header/monslogo.png";
// import ico from "../../assets/meta/2.ico";
const ico =
  "https://firebasestorage.googleapis.com/v0/b/temp-mons-solar.appspot.com/o/kwjvLmm5dfQ9Tff533OilePuAZC3%2F1627864615546?alt=media&token=4a9e5bee-aa14-4cc9-a482-ed544d7e86ad";

export const metaHomeData = {
  title: "태양광전문업체 몬스솔라｜MONSSOLAR",
  description: "내일을 위한 현명한 선택. 몬스솔라입니다.",
  image: ico,
};

export const metaBusinessData = {
  title: "몬스솔라 MONSSOLAR | 사업영역",
  description: "몬스솔라 사업영역을 소개합니다",
  image: ico,
};

export const metaProjectData = {
  title: "몬스솔라 MONSSOLAR | 프로젝트",
  description: "몬스솔라에서 진행한 프로젝트입니다",
  image: ico,
};

export const metaProductData = {
  title: "몬스솔라 MONSSOLAR | 제품안내",
  description: "몬스솔라의 제품을 소개합니다",
  image: ico,
};

export const metaBlogData = {
  title: "몬스솔라 MONSSOLAR | 블로그",
  description: "몬스솔라 소식입니다",
  image: ico,
};

export const metaContactData = {
  title: "몬스솔라 MONSSOLAR | 고객문의",
  description: "몬스솔라에 문의하세요",
  image: ico,
};

export const metaMonsInfoData = {
  title: "몬스솔라 MONSSOLAR | 몬스소개",
  description: "몬스솔라를 소개합니다",
  image: ico,
};
