import React, { useState } from "react";
import styled from "@emotion/styled";

import ViewMoreButton from "../../components/ViewMoreButton/ViewMoreButton";
import buildingAtBusiness from "../../assets/monsinfopage/f_info_building.jpg";
import buildingAtMbBusiness from "../../assets/monsinfopage/f_info_mb_building.jpg";

const MonsWrapper = styled.div`
  margin-top: 61px;
  overflow-x: hidden;

  .desktop-img {
    display: none;
  }

  @media (min-width: 1100px) {
    .desktop-img {
      display: block;
    }
    .mobile-img {
      display: none;
    }
  }

  @media (min-width: 900px) {
    margin-top: 60px;
    padding-left: 0rem;
    padding-right: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .mons-second-business-image {
    width: 100%;
    background-size: cover;

    @media (min-width: 765px) {
      margin-top: 20px;
    }
  }

  .mons-second-business {
    margin-top: 28px;
    display: flex;
    flex-direction: column;

    @media (min-width: 765px) {
      margin-top: 0px;
      width: 100%; // 조금 튀어나오는 느낌을 위해서.
      justify-content: center;
    }

    .only-business-component {
      font-size: 1.07rem;
      font-family: "Pretendard-Light";
      margin-top: 30px;
      line-height: 25px;
      color: #222222;

      @media (min-width: 765px) {
        font-size: 1.1rem;
        font-family: "Pretendard-Light";
        width: 400px;
        color: #666666;
      }
    }

    .mons-first-child {
      font-size: 1.5rem;
      font-family: "Pretendard-ExtraBold";
      color: #222222;

      @media (min-width: 765px) {
        font-size: 1.7rem;
        margin-top: 60px;
        color: #222222;
        width: 400px;
      }
    }
    .mons-second-child {
      margin-top: 16px;
      font-size: 1.2rem;
      margin-bottom: 28px;
      font-family: "Pretendard-Medium";

      @media (min-width: 765px) {
        font-size: 1.2rem;
        margin-bottom: 47px;
        color: #222222;
      }
    }
  }
`;

const BuildingAtMonsInfo = () => {
  return (
    <MonsWrapper>
      {/* 이미지 섹션 */}
      <img className="desktop-img mons-second-business-image" src={buildingAtBusiness} alt={"몬스소개-건물지원사업"} />
      <img className="mobile-img mons-second-business-image" src={buildingAtMbBusiness} />

      {/* 글자 섹션 */}
      <div className="mons-second-business">
        <span className="mons-first-child">건물지원사업(정부지원)</span>
        <span className="mons-second-child">200kW까지 지원받아 설치하는 정부지원사업</span>

        <ViewMoreButton pagePath={"business"} componentPath={"building"}></ViewMoreButton>
      </div>
    </MonsWrapper>
  );
};

export default BuildingAtMonsInfo;
