import React from "react";

import WebConsulting from "./WebConsulting";
import ScrollToTop from "./ScrollToTop";

import Fade from "react-reveal/Fade";

const FloatingActionButtons = () => {
  return (
    <div>
      <Fade bottom>
        <ScrollToTop />
        <WebConsulting />
      </Fade>
    </div>
  );
};

export default FloatingActionButtons;
