import React from "react";
import makeCarousel from "react-reveal/makeCarousel";
import Fade from "react-reveal/Fade";

import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

import logo1 from "../../assets/projectpage/main_banner.jpg";

const moveToLeft = keyframes`
   from {
        transform: translate3d(0px,0px,0px)
    }
    to {
        transform: translate3d(-50px,0px,0px)
    }
`;

const Container = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -2;
`;

const DivImage = styled.div`
  width: 110%;
  height: 100vh; // 모바일과 데스킅보에서 height를 조정할 땐 이 수치를 조정할 것
  overflow: hidden;

  background: url(${logo1}) no-repeat center;
  background-size: cover;

  animation-duration: 7.5s; // 캐러셀의 defaultWait 보다 길게 잡았음.
  animation-name: ${moveToLeft};
`;

const CarouselUI = ({ children }) => <Container>{children}</Container>;
const Carousel = makeCarousel(CarouselUI);

const MainBannerCarousel = () => {
  return (
    <Carousel maxTurns={100000000} swipe={false} defaultWait={6500}>
      <Fade>
        <DivImage />
      </Fade>
    </Carousel>
  );
};

export default MainBannerCarousel;
