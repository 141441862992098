import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import ConsultingForm from "../../components/ConsultingForm";

import CarouselsContainerAtHome from "../../components/Home/CarouselsContainerAtHome";
import MainBannerCarousel from "../../components/Home/MainBannerCarousel";

import ViewMoreButton from "../../components/ViewMoreButton/ViewMoreButton";
import SunAtHome from "../../components/Home/SunAtHome";
import HomeAtHome from "../../components/Home/HomeAtHome";
import BuildingAtHome from "../../components/Home/BuildingAtHome";
import * as Ani from "../../components/Home/animation";
import Arrow from "../../components/Generals/Arrow";
import ScrollTrigger from "react-scroll-trigger";
import { dbService } from "../../fbase";

import navyUnnamed from "../../assets/mainpage/sunAtHome.jpg";
import { useDispatch } from "react-redux";
import { headerHeightSlice } from "../../reducers/headerHeight";

import { isTablet } from "react-device-detect";
import Meta from "../../components/Meta";

import { metaHomeData } from "../../utils/metaData";

const Wrapper = styled.div`
  padding-bottom: 50px;
  overflow: hidden;
  position: relative;
`;

const DivImageSection = styled.div`
  margin-bottom: 15vh;
  display: flex;
  position: relative; // 이거는 스크롤 투 다운 화살표 때문에 있는건데, 이게 view more 애니메이션에 방해라면 빼야 한다

  width: 100%;
  height: 100vh;
  background-size: cover;
  z-index: 9999999;

  .text-container {
    height: 100%;
    padding-left: max(1rem, 10%);
    display: flex;
    flex-direction: column;
    justify-content: center;

    color: #ffffff;

    .first-text {
      font-size: 1.1rem;
      margin-bottom: 0.5em;
      font-family: "Pretendard-Medium";
    }
    .second-text {
      font-size: 2.1rem;
      font-family: "Pretendard-Bold";
      margin-bottom: 30px;
      padding-bottom: 20px;
    }
    .routing-button {
      font-family: "Pretendard-Light";
      border: 2px solid gray;
      width: 130px;
    }
  }

  @media (min-width: 765px) {
    height: 95vh;

    .text-container {
      height: 100vh;
      padding-left: 10%;

      .first-text {
        font-size: 1.4rem;

        font-family: "Pretendard-Light";
        margin-bottom: 11px;
      }
      .second-text {
        font-size: 3.3rem;

        font-family: "Pretendard-Bold";
        margin-bottom: 28px;
      }
    }
  }
`;

const DivBusinessSection = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  .desktop-wrapper {
    display: none;
  }

  @media (min-width: 865px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
    height: 1000px;

    .desktop-wrapper {
      display: flex;
      align-items: stretch;
    }

    .mobile-wrapper {
      display: none;
    }
  }
`;

const DivImageSliderSection = styled.section`
  margin-top: 4rem;

  height: 600px;

  .container {
    display: none;
  }

  @media (min-width: 300px) {
    height: 600px;
  }

  @media (min-width: 600px) {
    height: 100%;
  }

  @media (min-width: 1200px) {
    max-width: 1900px;

    margin-left: auto;
    margin-right: auto;

    .container {
      max-width: 1000px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;

      .first-child {
        font-size: 2.3rem;
        font-family: "Pretendard-ExtraBold";
        margin-bottom: 16px;
      }

      .second-child {
        font-size: 1.2rem;

        font-family: "Pretendard-Medium";
      }
    }
  }

  @media (min-width: 1000px) {
    max-width: 2600px;
  }
`;

const SectionConsultingForm = styled.section`
  margin-bottom: 100px;

  @media (min-width: 765px) {
    margin-top: 200px;

    padding-left: 2rem;
  }
`;

const DivImage = styled.div`
  display: none;

  @media (min-width: 565px) {
    position: relative;
    display: block;
  }

  @media (min-width: 865px) {
    /* overflow: hidden; */
    width: 100%;
    height: 100%;

    .image-container-ani {
      animation-duration: 3s;
      animation-name: ${Ani.minWidthFirstBuilding};
    }

    .image-container {
      background-color: green;
      position: absolute;
      width: 700px;
      height: 440px;
      object-fit: cover;
      object-position: center;
      right: -10px;

      z-index: -10;
    }
  }
`;

const SectionInfoText = styled.section`
  display: none;
  width: 100%;

  @media (min-width: 1200px) {
    display: block;
    margin-top: 200px;
    margin-bottom: 200px;

    .wrapper {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      max-width: 1150px;

      .title {
        padding-left: 5rem;
        font-size: 1.6rem;
        font-family: "Pretendard-ExtraBold";
        width: 40%;
        line-height: 35px;
        color: #222222;
      }

      .content {
        display: flex;
        flex-direction: column;
        width: 56%;
        color: #222222;

        .first-child {
          font-size: 1.1rem;
          font-family: "Pretendard-ExtraBold";
          margin-bottom: 19px;
        }

        .second-child {
          font-size: 1rem;
          font-family: "Pretendard-Medium";
          line-height: 25px;
        }
      }
    }
  }
`;

const Home = () => {
  const dispatch = useDispatch();

  const refSun = useRef();
  const refHeight = useRef();

  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [posts, setPosts] = useState([]);

  const onTriggerAnimate = useCallback(() => {
    if (!triggerAnimation) setTriggerAnimation(true);
  }, [triggerAnimation]);

  const getPostsByFirebase = useCallback(() => {
    dbService
      .collection("projects")
      .orderBy("createdAt", "desc")
      .limit(3)
      .onSnapshot((snapShot) => {
        const postArray = snapShot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // 배열을 복사하는 이유? -> 이미지 슬라이더가 보여주는 갯수와 렌더링할 이미지 배열의 인덱스가 동일하면 안된다. 무조건 배열의 더 커야 함.
        const copyPostArray = postArray;
        const resultArray = postArray.concat(copyPostArray);
        setPosts(resultArray);
      });
  }, []);

  useEffect(() => {
    getPostsByFirebase();
  }, [getPostsByFirebase]);

  const onArrowEnter = useCallback(() => {
    dispatch(headerHeightSlice.actions.saveHeaderHeight(0));
  }, [dispatch]);
  const onArrowExit = useCallback(() => {
    dispatch(headerHeightSlice.actions.saveHeaderHeight(1));
  }, [dispatch]);

  //  새로고침하더라도 헤더의 상태를 유지하도록 해 주는 로직
  useEffect(() => {
    if (window.pageYOffset > refHeight.current.clientHeight) {
      dispatch(headerHeightSlice.actions.saveHeaderHeight(1));
    }
  }, [dispatch]);

  return (
    <>
      <Meta data={metaHomeData} />

      <Wrapper>
        <DivImageSection ref={refHeight}>
          <div className="text-container">
            <span className="first-text">내일을 위한 현명한 선택</span>
            <span className="second-text">Monssolar</span>

            <ViewMoreButton borderAndFontColor={"white"} others={"0.75rem"} pagePath={"monsinfo"} />
          </div>

          <ScrollTrigger onEnter={onArrowEnter} onExit={onArrowExit}>
            <Arrow isTablet={isTablet} refObj={refSun} />
          </ScrollTrigger>
        </DivImageSection>

        <MainBannerCarousel />

        <DivBusinessSection>
          <ScrollTrigger onEnter={onTriggerAnimate}>
            <DivImage>
              {/* <img
                className={triggerAnimation ? "image-container image-container-ani" : "image-container"}
                src={navyUnnamed}
              /> */}
              <div
                className={triggerAnimation ? "image-container image-container-ani" : "image-container"}
                src={navyUnnamed}
                style={{ background: `url(${navyUnnamed}) no-repeat center`, backgroundSize: "cover" }}
              />
            </DivImage>
          </ScrollTrigger>

          <SunAtHome refSun={refSun} />

          <div className="mobile-wrapper">
            <HomeAtHome />
            <BuildingAtHome />
          </div>

          <div className="desktop-wrapper">
            <HomeAtHome />
            <BuildingAtHome />
          </div>
        </DivBusinessSection>

        <SectionInfoText>
          <div className="wrapper">
            <div className="title">
              {/*몬스솔라의 신재생에너지는<br></br> 에코 라이프 스타일 비즈니스입니다.*/}
              주식회사 몬스솔라는<br></br>고객과 친환경 신·재생에너지 연결하여<br></br>최적의 에코 라이프스타일을 제공합니다.
            </div>
            <div className="content">
              <span className="first-child">
                몬스솔라는 고객과 함께 가치를 만들어 나가는 에코 라이프 스타일 비즈니스입니다.
              </span>
              <span className="second-child">
                사업 그 이상의 가치를 위해 지속적인 투자를 하여 안정적인 시스템 설계 능력을 보유했습니다.<br></br> 생활
                방식에서부터 환경에 이르기까지, 지속 가능한 친환경 에너지를 통해 라이프스타일을 더<br></br> 이롭고
                유용하게 업그레이드할 수 있습니다.
              </span>
            </div>
          </div>
        </SectionInfoText>

        <DivImageSliderSection>
          <div className="container">
            <span className="first-child">Monssolar Project</span>
            <span className="second-child">몬스솔라에서 진행한 프로젝트입니다.</span>
          </div>
          <CarouselsContainerAtHome posts={posts} />
        </DivImageSliderSection>

        <SectionConsultingForm>
          <ConsultingForm />
        </SectionConsultingForm>
      </Wrapper>
    </>
  );
};

export default Home;
