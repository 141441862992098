import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from 'axios';
import CrawlsListPresenter from "./CrawlsListPresenter";
import { dbService } from "../../fbase";
import Pagination from "../../components/Blog/Pagination";
import styled from "@emotion/styled";
import MainBannerCarousel from "../../components/Blog/MainBannerCarousel";
import Arrow from "../../components/Generals/Arrow";
import { isTablet } from "react-device-detect";
import Meta from "../../components/Meta";
import {metaBlogData} from "../../utils/metaData";

const DivWrapper = styled.div`
  padding-bottom: 100px;
`;

// div banner text
const DivBannerText = styled.div`
  margin-bottom: 7.5vh;
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 99;

  .text-container {
    height: 100%;
    padding-left: 1.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;

    .first-text {
      font-size: 1.1rem;
      font-family: "Pretendard-Medium";
      margin-bottom: 0.5em;
    }
    .second-text {
      font-size: 2.1rem;
      font-family: "Pretendard-Bold";
    }
    .routing-button {
      border: 2px solid gray;
      width: 130px;
    }
  }

  @media (min-width: 765px) {
    margin-bottom: 10vh;
    height: 95vh;

    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .first-text {
        font-size: 1.33rem;
        font-family: "Pretendard-Medium";
        margin-bottom: 15px;
        color: #ffffff;
      }

      .second-text {
        font-size: 2.8rem;
        font-family: "Pretendard-Bold";
      }

      .routing-button {
        margin-left: auto;
        margin-right: auto;
        
      }
    }
  }
`;



const SectionBlogContainer = styled.section`
  margin-top: 18px;
  margin-bottom: 35px;
  padding-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  .hr-line {
    padding-left: 1rem;
    padding-right: 1rem;
    border-top: 2px solid black;
  }

  @media (min-width: 765px) {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
`;
const blogDataUrl = 'https://us-central1-practice-cloud-fucntions.cloudfunctions.net/userGetBlogsData';

const CrawlsContainer = () => {
  const refObj = useRef();
  const [currentPageNumber, setCurrentPageNumber] = useState(0); // 0으로 시작. 실제 페이지 넘버링은 + 1씩 한다고 생각할 것
  const [crawlResult, setCrawlResult] = useState([]);

  const getPostsByFirebase = async () => {
    const {data} = await axios(blogDataUrl)
    let tempA = data.sort((a,b) => b.birthId - a.birthId);
    let tempPaginationArr = [];
    let tempArr = [...tempA];

    let bindingPostAmount = 5; // 5개 포스팅씩 묶음
    let number = tempArr.length / bindingPostAmount;
    for (let i = 0; i < number; i++) {
      tempPaginationArr.push(tempArr.splice(0, bindingPostAmount));
    }
    setCrawlResult(tempPaginationArr);
  };

  useEffect(() => {
    getPostsByFirebase();
  }, []);

  // 유저가 ( <<, <, 1, 2, 3, >, >> ) 중에서 숫자를 누를 때,
  const onClickPageNumber = useCallback((e) => {
    setCurrentPageNumber(+(e.target.innerText - 1));
  }, []);

  // 유저가 ( <<, <, 1, 2, 3, >, >> ) 중에서 <, > 를 누를 때
  const onClickPageArrowPrevAndNext = useCallback(
    (e) => {
      if (e.target.innerText === "<") {
        if (currentPageNumber === 0) {
          return;
        }
        setCurrentPageNumber((prev) => prev - 1);
      }

      if (e.target.innerText === ">") {
        if (currentPageNumber === crawlResult.length - 1) {
          return;
        }
        setCurrentPageNumber((prev) => prev + 1);
      }
    },
    [crawlResult.length, currentPageNumber]
  );

  // 유저가 ( <<, <, 1, 2, 3, >, >> ) 중에서 <<, >> 를 누를 때
  const onClickPageLastAndFirst = useCallback(
    (e) => {
      if (e.target.innerText === "<<") {
        if (currentPageNumber === 0) {
          return;
        }
        setCurrentPageNumber(0);
      }

      if (e.target.innerText === ">>") {
        if (currentPageNumber === crawlResult.length - 1) {
          return;
        }
        setCurrentPageNumber(crawlResult.length - 1);
      }
    },
    [crawlResult.length, currentPageNumber]
  );

  return (
    <>
      <Meta data={metaBlogData} />
      <DivWrapper>
        {/* 이미지 대빵만 하게 큰 거 */}
        <DivBannerText>
          <div className="text-container">
            <span className="first-text">몬스솔라 소식입니다</span>
            <span className="second-text">MonsBlog</span>
          </div>

          <Arrow isTablet={isTablet} refObj={refObj} />
        </DivBannerText>

        <MainBannerCarousel />

        <div style={{ overflowX: "hidden", paddingLeft: "1rem", paddingRight: "1rem" }}>
          <section>
            <div ref={refObj}></div>
          </section>
        </div>

        {/* 블로그 글들의 리스트가 추루룩 */}
        <SectionBlogContainer>
          <div className="hr-line">
            {crawlResult &&
              crawlResult[currentPageNumber]?.map((el, index) => {
                return (
                  <CrawlsListPresenter
                    refScrollToProject={refObj}
                    key={index}
                    pageMaxNumber={crawlResult.length}
                    post={el}
                  />
                );
              })}
          </div>
        </SectionBlogContainer>

        {/* 하단부 페이지네이션 섹션 */}
        <Pagination
          crawlResult={crawlResult}
          currentPageNumber={currentPageNumber}
          onClickPageLastAndFirst={onClickPageLastAndFirst}
          onClickPageArrowPrevAndNext={onClickPageArrowPrevAndNext}
          onClickPageNumber={onClickPageNumber}
        />
      </DivWrapper>
    </>
  );
};

export default CrawlsContainer;
