import React from "react";
import styled from "@emotion/styled";

import companyMark from "../../assets/sun/f_medal.png";

const DivWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .main-left {
    display: none;

    .main-left-content {
      .main-left-content-medal {
        font-family: "Pretendard-Medium";
      }
    }
  }

  .main-right {
    width: 100%;

    .main-text {
      font-size: 1.4rem;
      font-family: "Pretendard-ExtraBold";
      margin-bottom: 30px;
      padding-left: 1rem;
      margin-top: 35px;
    }

    .list {
      width: 100%;
      height: 150px;
    }

    .main-right-box {
      display: flex;
      flex-direction: column;

      font-family: "Pretendard-Medium";
      padding-left: 1rem;
      padding-right: 1rem;

      .first-child {
        .first {
          font-size: 1.1rem;
          background-color: #222222;
          color: white;
        }
      }

      .list {
        margin: 0 auto;
        display: flex;
        width: 100%;

        & div {
          width: 100%;
          border: 1px solid #bfbfbf;
        }

        .center {
          display: flex;
          flex-direction: column;
          padding-top: 40px;
          align-items: center;

          .apply-margin-bottom {
            margin-bottom: 5px;
          }

          .round-number {
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            background-color: #222222;
            border-radius: 50%;
            color: white;
          }
        }

        .center-center {
          padding-top: 0px;
          justify-content: center;
        }
      }
    }
  }

  @media (min-width: 765px) {
    margin: 0 auto;
    margin-top: 130px;
    max-width: 1000px;
    padding-left: 1rem;
    padding-right: 1rem;

    .main-left {
      margin-right: 130px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 45%;

      .main-left-content {
        display: flex;
        flex-direction: column;

        .main-left-title {
          font-size: 1.7rem;
          font-family: "Pretendard-ExtraBold";
          margin-bottom: 21px;
        }

        .main-left-sub-text {
          font-size: 1.1rem;

          line-height: 28px;
          color: #747474;
          font-family: "Pretendard-Medium";
        }

        .medal-padding-top {
          padding-top: 28px;
        }

        .main-left-content-medal {
          display: flex;
          align-items: flex-end;
          font-family: "Pretendard-Medium";
          padding-top: 20px;

          .medal-img {
            width: 50px;
            padding-bottom: 5px;
            height: 50px;
            margin-right: 5px;
          }
          .medal-text {
            display: flex;
            flex-direction: column;
            font-family: "Pretendard-ExtraBold";
            line-height: 25px;
          }
        }

        .medal-margin-top {
          margin-top: 10px;
        }
      }
    }

    .main-right {
      .main-text {
        display: none;
      }
      .center {
        padding-top: 30px;
      }

      .main-right-box {
        padding-left: 0rem;
        padding-right: 0rem;

        .list {
          width: 90%;
        }

        .only-dt-div-container {
          display: flex;
          font-family: "Pretendard-Medium";
        }
      }
    }
  }
`;

const BusinessProcess = ({ query }) => {
  if (query === "sun") {
    return (
      <DivWrapper>
        <div className="main-left">
          <div className="main-left-content">
            <span className="main-left-title">사업절차 안내</span>

            <div className="main-left-sub-text">
              <span>사업에 필요한 모든 행정업무 및 시공을 직접 진행합니다.</span>
            </div>
          </div>
          <div className="main-left-content">
            <div className="main-left-content-medal first-medal">
              <img src={companyMark} alt="mons" />
              <div className="medal-text">
                <span>20,21년 보급사업</span>
                <span>참여기업 선정업체</span>
              </div>
            </div>
            <div className="main-left-content-medal medal-margin-top">
              <img src={companyMark} alt="mons" />
              <div className="medal-text">
                <span>한국에너지공단</span>
                <span>A/S 전담기업</span>
              </div>
            </div>
          </div>
        </div>

        <div className="main-right">
          <div className="main-text">사업절차 안내</div>
          <div className="main-right-box">
            <div className="only-dt-div-container">
              <div className="first-child list">
                <div className="first center center-center">과정안내</div>
                <div className="secondt center">
                  <span className="round-number">
                    <span>01</span>
                  </span>
                  <span className="apply-margin-bottom">사업타당성</span>
                  <span>검토</span>
                </div>
              </div>

              <div className="second-child list">
                <div className="first center">
                  <span className="round-number">
                    <span>02</span>
                  </span>
                  <span>제안 및 계약</span>
                </div>

                <div className="secondt center">
                  <span className="round-number">03</span>
                  <span>사업허가</span>
                </div>
              </div>
            </div>

            <div className="only-dt-div-container">
              <div className="third-child list">
                <div className="first center">
                  <span className="round-number">04</span>
                  <span>PPA신청</span>
                </div>

                <div className="secondt center">
                  <span className="round-number">05</span>
                  <span className="apply-margin-bottom">신고, 검사 및</span>
                  <span>수급계약</span>
                </div>
              </div>

              <div className="fourth-child list">
                <div className="first center">
                  <span className="round-number">06</span>
                  <span className="apply-margin-bottom">발전 및 사업</span>
                  <span>운영</span>
                </div>

                <div className="secondt center">
                  <span className="round-number">07</span>
                  <span className="apply-margin-bottom">운영 및 사후</span>
                  <span>관리</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DivWrapper>
    );
  }

  if (query === "home") {
    return (
      <DivWrapper>
        <div className="main-left">
          <div className="main-left-content">
            <span className="main-left-title">사업절차 안내</span>

            <div className="main-left-sub-text">
              <span>사업에 필요한 모든 행정업무 및 시공을 직접 진행합니다.</span>
            </div>
          </div>
          <div className="main-left-content">
            <div className="main-left-content-medal">
              <img src={companyMark} alt="mons" />
              <div className="medal-text">
                <span>20,21년 보급사업</span>
                <span>참여기업 선정업체</span>
              </div>
            </div>
            <div className="main-left-content-medal medal-margin-top">
              <img src={companyMark} alt="mons" />
              <div className="medal-text">
                <span>한국에너지공단</span>
                <span>A/S 전담기업</span>
              </div>
            </div>
          </div>
        </div>

        <div className="main-right">
          <div className="main-text">사업절차 안내</div>
          <div className="main-right-box">
            <div className="only-dt-div-container">
              <div className="first-child list">
                <div className="first center center-center">과정안내</div>
                <div className="secondt center">
                  <span className="round-number">
                    <span>01</span>
                  </span>
                  <span className="apply-margin-bottom">참여 기업</span>
                  <span>선택</span>
                </div>
              </div>

              <div className="second-child list">
                <div className="first center">
                  <span className="round-number">
                    <span>02</span>
                  </span>
                  <span className="apply-margin-bottom">설치장소</span>
                  <span>검토 및 계약</span>
                </div>

                <div className="secondt center">
                  <span className="round-number">03</span>
                  <span className="apply-margin-bottom">사업검토 및</span>
                  <span>선정</span>
                </div>
              </div>
            </div>

            <div className="only-dt-div-container">
              <div className="third-child list">
                <div className="first center">
                  <span className="round-number">04</span>
                  <span className="apply-margin-bottom">설치완료 및</span>
                  <span>전기점검</span>
                </div>

                <div className="secondt center">
                  <span className="round-number">05</span>
                  <span className="apply-margin-bottom">가상계좌</span>
                  <span>입금 및 승인</span>
                </div>
              </div>

              <div className="fourth-child list">
                <div className="first center">
                  <span className="round-number">06</span>
                  <span className="apply-margin-bottom">에너지공단</span>
                  <span>검수</span>
                </div>

                <div className="secondt center">
                  <span className="round-number">07</span>
                  <span className="apply-margin-bottom">지방비</span>
                  <span>신청/지급</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DivWrapper>
    );
  }

  if (query === "building") {
    return (
      <DivWrapper>
        <div className="main-left">
          <div className="main-left-content">
            <span className="main-left-title">사업절차 안내</span>

            <div className="main-left-sub-text">
              <span>사업에 필요한 모든 행정업무 및 시공을 직접 진행합니다.</span>
            </div>
          </div>
          <div className="main-left-content">
            <div className="main-left-content-medal">
              <div className="medal-img">
                <img src={companyMark} alt="mons" />
              </div>
              <div className="medal-text">
                <span>20,21년 보급사업</span>
                <span>참여기업 선정업체</span>
              </div>
            </div>
            <div className="main-left-content-medal medal-margin-top">
              <div className="medal-img">
                <img src={companyMark} alt="mons" />
              </div>
              <div className="medal-text">
                <span>한국에너지공단</span>
                <span>A/S 전담기업</span>
              </div>
            </div>
          </div>
        </div>

        <div className="main-right">
          <div className="main-text">사업절차 안내</div>
          <div className="main-right-box">
            <div className="only-dt-div-container">
              <div className="first-child list">
                <div className="first center center-center">과정안내</div>
                <div className="secondt center">
                  <span className="round-number">
                    <span>01</span>
                  </span>
                  <span className="apply-margin-bottom">사업신청 및</span>
                  <span>서류검토</span>
                </div>
              </div>

              <div className="second-child list">
                <div className="first center">
                  <span className="round-number">
                    <span>02</span>
                  </span>
                  <span className="apply-margin-bottom">사업평가 및</span>
                  <span>선정</span>
                </div>

                <div className="secondt center">
                  <span className="round-number">03</span>
                  <span className="apply-margin-bottom">사업계획서</span>
                  <span>제출</span>
                </div>
              </div>
            </div>

            <div className="only-dt-div-container">
              <div className="third-child list">
                <div className="first center">
                  <span className="round-number">04</span>
                  <span className="apply-margin-bottom">사업</span>
                  <span>최종승인</span>
                </div>

                <div className="secondt center">
                  <span className="round-number">05</span>
                  <span className="apply-margin-bottom">설비공사 및</span>
                  <span>정기점검</span>
                </div>
              </div>

              <div className="fourth-child list">
                <div className="first center">
                  <span className="round-number">06</span>
                  <span className="apply-margin-bottom">설치확인</span>
                  <span>신청</span>
                </div>

                <div className="secondt center">
                  <span className="round-number">07</span>
                  <span className="apply-margin-bottom">에너지공단</span>
                  <span>검수</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DivWrapper>
    );
  }
};
export default BusinessProcess;
