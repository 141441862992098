import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";

import ScrollTrigger from "react-scroll-trigger";

import CarouselsConnectsNav from "./CarouselsConnectsNav";

import { keyframes } from "@emotion/react";

const containerTranslate = keyframes`
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.02, 1.02, 1.02);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
`;


const DivWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;

  height: 650px;

  .main-content {
    display: flex;
    flex-direction: column;
    color: #ffffff;

    .first-child {
      margin-top: 4rem;
      margin-bottom: 8px;
      font-size: 1.2rem;
      font-family: "Pretendard-Medium";
    }
    .second-child {
      margin-bottom: 30px;
      font-size: 1.6rem;
      font-family: "Pretendard-Medium";
    }
    .third-child {
      margin-bottom: 25px;
      color: #505050;
      font-size: 1.1rem;
      font-family: "Pretendard-Medium";
    }
  }

  @media (min-width: 500px) {
    height: 730px;
  }

  @media (min-width: 500px) {
    height: 730px;
  }

  @media (min-width: 650px) {
    height: 750px;
  }

  @media (min-width: 1200px) {
    height: 500px;

    .ani {
      animation-name: ${containerTranslate};
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
    }
  }

  @media (min-width: 2000px) {
    overflow: visible;
  }
`;

const CarouselsContainerAtHome = ({ posts }) => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const onTriggerAnimate = useCallback(() => {
    if (!triggerAnimation) setTriggerAnimation(true);
  }, [triggerAnimation]);

  return (
    <ScrollTrigger onEnter={onTriggerAnimate}>
      <DivWrapper>
        <CarouselsConnectsNav posts={posts} triggerAnimation={triggerAnimation} />
      </DivWrapper>
    </ScrollTrigger>
  );
};

export default CarouselsContainerAtHome;
