import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import ChangeableBottomText from "../ChangeableBottomText";

import { useLocation } from "react-router";

import tablet_sun_banner from "../../assets/sun/real_f_sun_tb_main.png";
import mb_sun_banner from "../../assets/sun/real_f_sun_mb_main.png";
import sunZero from "../../assets/sun/real_f_sun_dt_main.png";

const SectionTopContainer = styled.section`
  margin-top: 100px;

  overflow: hidden;

  .main-title {
    font-size: 1.7rem;
    font-family: "Pretendard-ExtraBold";
    margin-left: 1rem;
    width: 100%;
    height: 100px;
  }

  .main-bottom-container {
    margin-top: 35px;
    display: flex;
    flex-direction: column;

    .main-bottom-content {
      margin-bottom: 45px;
      display: flex;

      flex-direction: column;

      .content-left {
        font-size: 1.45rem;
        font-family: "Pretendard-ExtraBold";
        margin-right: 50px;
        margin-bottom: 30px;
        padding-left: 1rem;
        display: flex;
        flex-direction: column;
        color: #222222;
        width: 100%;

        span {
          line-height: 30px;
        }
      }

      .content-right {
        font-size: 0.9rem;
        font-family: "Pretendard-Medium";
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
        padding-right: 1rem;

        .mobile-content-right {
          font-size: 1.1rem;
          line-height: 25px;
          span {
            padding-left: 0rem;
          }
        }

        .desktop-content-right {
          display: none;
        }

        span {
          padding-left: 1rem;
          line-height: 25px;
          color: #222222;
        }
      }

      .content-bottom-right {
        display: flex;
        flex-direction: column;
        width: calc(100% - 2rem);
        margin-left: auto;
        margin-right: auto;

        .left-part {
          width: 100%;
          border-bottom: 1px solid #c5c5c5;
          padding-bottom: 30px;
        }

        .right-part {
          margin-top: 35px;
          width: 100%;
        }

        .content-bottom-right-left {
          display: flex;
          flex-direction: column;

          .desktop {
            display: none;
          }

          .small-title {
            font-size: 1.22rem;
            font-family: "Pretendard-ExtraBold";
            margin-bottom: 15px;
            color: #000000;
          }

          .small-content {
            font-size: 1.1rem;
            font-family: "Pretendard-Medium";
            line-height: 25px;
            color: #222222;
          }
        }
      }
    }
  }

  @media (min-width: 1100px) {
    margin-top: 207px;
    margin-bottom: 80px;
    max-width: 1900px;
    overflow: visible;

    .dt-wrapper-main-title {
      max-width: 1050px;
      margin-left: auto;
      margin-right: auto;
    }

    .only-dt-image-container {
      overflow: hidden;
    }

    .main-title {
      font-size: 2.1rem;
      font-family: "Pretendard-ExtraBold";
      margin-left: 0rem;
      width: 100%;
    }

    .main-bottom-container {
      padding-top: 0px;
      margin-left: auto;
      margin-right: auto;
      max-width: 1000px;

      .first-mbc {
        margin-top: 20px;
        margin-bottom: 25px;
      }

      .main-bottom-content {
        flex-direction: row;

        .content-left {
          font-size: 1.6rem;
          font-family: "Pretendard-ExtraBold";
          padding-left: 0rem;
          padding-bottom: 7px;
          margin-right: 0px;
          width: 550px;
        }

        .content-right {
          font-size: 1.1rem;
          font-family: "Pretendard-Medium";
          width: 100%;
          padding-left: 1rem;
          padding-right: 0rem;

          .desktop-content-right {
            display: block;
          }

          .mobile-content-right {
            display: none;
          }

          span {
            line-height: 28px;
          }
        }

        .content-bottom-right {
          flex-direction: row;
          font-size: 1.1rem;
          font-family: "Pretendard-Medium";
          width: 100%;
          padding-left: 1rem;
          padding-right: 0rem;

          .left-part {
            border-bottom: none;
            border-right: 1px solid #c5c5c5;
            padding-bottom: 0px;

            .small-title,
            .small-content {
              padding-left: 1rem;
            }
          }

          .right-part {
            width: 100%;
            margin-top: 0px;

            .small-title,
            .small-content {
              padding-left: 1.5rem;
            }
          }

          .content-bottom-right-left {
            padding-left: 0rem;

            .desktop {
              display: block;
            }
            .mobile {
              display: none;
            }

            .small-title {
              font-size: 1.3rem;
              font-family: "Pretendard-ExtraBold";
            }

            .small-content {
              font-size: 1rem;
              font-family: "Pretendard-Medium";
            }
          }
        }
      }
    }
  }
`;
const MobileDivImageContainer = styled.img`
  margin-top: 20px;
  margin-left: 1.3rem;
  width: 100%;

  @media (min-width: 500px) {
    display: none;
  }
`;

const TabletDivImageContainer = styled.img`
  display: none;

  @media (min-width: 500px) {
    display: block;
    margin-top: 20px;
    margin-left: 1.3rem;
    width: 100%;
  }

  @media (min-width: 800px) {
    display: none;
  }
`;

const DivImageContainer = styled.div`
  display: none;

  @media (min-width: 800px) {
    display: block;
    margin-top: 20px;
    margin-left: 2%; // 얘가 이미지를 오른쪽으로 치우치게 하는 역할이다.
    margin-bottom: 76px;
    height: 340px;
    background: url(${sunZero}) no-repeat center;
    background-size: cover;
  }

  @media (min-width: 1100px) {
    margin-left: 7%;
    margin-bottom: 76px;
  }
`;

const UlContainer = styled.div`
  width: 100%;

  @media (min-width: 1100px) {
    width: 95%;
    margin: 0 auto;
  }
`;

const Ul = styled.ul`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
  max-width: 100%;
  border-top: 1px solid rgba(178, 171, 170, 0.3);
  border-bottom: 1px solid rgba(178, 171, 170, 0.3);

  .li-list {
    border: none;
    padding: 1rem 0;
    cursor: pointer;
  }

  .list-process {
    font-family: ${(props) => (props.innerText === "사업절차" ? "Pretendard-ExtraBold" : "Pretendard-Light")};

    border-bottom: ${(props) => (props.innerText === "사업절차" ? "2px solid black" : "")};
  }

  .list-example {
    font-family: ${(props) => (props.innerText === "시공사례" ? "Pretendard-ExtraBold" : "Pretendard-Light")};

    border-bottom: ${(props) => (props.innerText === "시공사례" ? "2px solid black" : "")};
  }

  @media (min-width: 1100px) {
    max-width: 1000px;
    justify-content: flex-start;
    border-top: 1px solid rgba(178, 171, 170, 0.9);
    border-bottom: none;

    .li-list {
      padding: 1.5rem 0;
      margin-right: 20px;
    }

    .list-process {
      font-family: ${(props) => (props.innerText === "사업절차" ? "Pretendard-ExtraBold" : "Pretendard-Light")};
      color: ${(props) => (props.innerText === "사업절차" ? "" : "#B2ABAA")};

      border-bottom: none;
    }

    .list-example {
      font-family: ${(props) => (props.innerText === "시공사례" ? "Pretendard-ExtraBold" : "Pretendard-Light")};
      color: ${(props) => (props.innerText === "시공사례" ? "" : "#B2ABAA")};
      border-bottom: none;
    }
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Sun = () => {
  let query = useQuery();

  const [innerText, setInnerText] = useState("사업절차");

  const onCheckLiInnerText = useCallback((e) => {
    setInnerText(e.target.innerText);
  }, []);

  return (
    <div style={{ maxWidth: "1600px", margin: "0 auto", paddingBottom: 50 }}>
      <SectionTopContainer>
        <div className="dt-wrapper-main-title">
          <span className="main-title">태양광발전사업</span>
        </div>
        {/* 이미지 컨테이너 */}
        <div className="only-dt-image-container">
          <MobileDivImageContainer src={mb_sun_banner} />
          <TabletDivImageContainer src={tablet_sun_banner} />
          <DivImageContainer />
        </div>
        <div className="main-bottom-container">
          <div className="main-bottom-content first-mbc">
            <div className="content-left">
              <span>태양광발전사업</span>
              <span>(RPS/FIT)</span>
            </div>
            <div className="content-right">
              <div className="mobile-content-right">
                <span>
                  토지나 건물 옥상, 출사 등 태양광 시스템을 설치한 후 태양광발전소에서 생산한 전력을 공급 의무자에게
                  판매함으로써 얻는 SMP 및 REC 판매수익을 목적으로 합니다
                </span>
              </div>

              <span className="desktop-content-right">
                토지나 건물 옥상, 축사 등 태양광 시스템을 설치한 후 태양광발전소에서 생산한 전력을 공급 의무자에게
                판매함으로써 얻는 SMP 및 REC 판매수익을 목적으로 합니다
              </span>
            </div>
          </div>

          <div className="main-bottom-content">
            <div className="content-left">
              <span>발전 차액지원제도</span>
              <span>(FIT)</span>
            </div>
            <div className="content-right">
              <div className="mobile-content-right">
                <span>
                  발전차액 지원제도는 정부가 일정기간동안 정해진 가격으로 전력을 매입하여 수입을 보장하기 때문에 투자의
                  안정성을 높이고 중.소규모의 발전이 가능하게 하는 역할을 합니다
                </span>
              </div>
              <span className="desktop-content-right">
                발전차액 지원제도는 정부가 일정기간동안 정해진 가격으로 전력을 매입하여 수입을 보장하기 때문에 투자의
                안정성을 높이고 중.소규모의 발전이 가능하게 하는 역할을 합니다
              </span>
            </div>
          </div>

          <div className="main-bottom-content">
            <div className="content-left" />

            <div className="content-bottom-right">
              <div className="content-bottom-right-left left-part">
                <span className="small-title">참여대상</span>

                <span className="small-content desktop">- 설비용량 30kW미만의 태양광발전사업자,</span>
                <span className="small-content desktop">&nbsp;&nbsp;&nbsp;자가발전</span>
                <span className="small-content desktop">- 설비용량 100kW미만의 태양광발전사업</span>
                <span className="small-content desktop">&nbsp;&nbsp;&nbsp;자로 농·축산, 어민 및 협동조합</span>

                <span className="small-content mobile">- 설비용량 30kW미만의 태양광발전사업자, 자가발전</span>
                <span className="small-content mobile">
                  - 설비용량 100kW미만의 태양광발전사업자로 농·축산, 어민 및 협동조합
                </span>
              </div>
              <div className="content-bottom-right-left right-part">
                <span className="small-title">계약기간</span>
                <span className="small-content">태양광 20년 / 태양광과 연계된 ESS 15년</span>
              </div>
            </div>
          </div>
        </div>
      </SectionTopContainer>

      {/* 여기부터 사업절차, 시공사례 시작됨 */}
      <section>
        <UlContainer>
          <Ul innerText={innerText}>
            <li className="li-list list-process" onClick={onCheckLiInnerText}>
              사업절차
            </li>
            <li className="li-list list-example" onClick={onCheckLiInnerText}>
              시공사례
            </li>
          </Ul>
        </UlContainer>
        <ChangeableBottomText query={query.get("name")} innerText={innerText} />
      </section>
    </div>
  );
};
export default Sun;
