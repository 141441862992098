import React, { useState, useCallback } from "react";

import styled from "@emotion/styled";
import ScrollTrigger from "react-scroll-trigger";
import ViewMoreButton from "../ViewMoreButton/ViewMoreButton";
import * as Ani from "./animation";
import buildingAtBusinessImage from "../../assets/businesspage/modified_home.png";
import buildingAtMobileBusinessImage from "../../assets/businesspage/f_mobile_building.jpg";

const MonsWrapper = styled.div`
  margin-top: 61px;
  padding-left: 1rem;
  padding-right: 1rem;

  .mons-second-business-image {
    /* width: 100%;
    height: 300px; */
  }

  .mons-second-business {
    margin-top: 28px;
    display: flex;
    flex-direction: column;

    .only-business-component {
      font-size: 1.07rem;
      font-family: "Pretendard-Light";
      margin-top: 30px;
      margin-bottom: 30px;
      line-height: 25px;
      color: #222222;

      .desktop-br {
        display: none;
      }
    }

    .mons-first-child {
      font-size: 1.5rem;
      font-family: "Pretendard-ExtraBold";
      color: #222222;
    }
    .mons-second-child {
      margin-top: 16px;
      font-size: 1.2rem;
      font-family: "Pretendard-Medium";
    }
  }

  .ani-one {
    animation-duration: 1.5s;
    animation-name: ${Ani.firstOpacityAndTranslateY};
  }
  .ani-two {
    animation-duration: 1.7s;
    animation-name: ${Ani.secondOpacityAndTranslateY};
  }
  .ani-three {
    animation-duration: 1.7s;
    animation-name: ${Ani.thirdOpacityAndTranslateY};
  }
  .ani-four {
    animation-duration: 1.9s;
    animation-name: ${Ani.thirdOpacityAndTranslateY};
  }
  .ani-five {
    animation-duration: 1.5s;
    animation-name: ${Ani.thirdOpacityAndTranslateY};
  }

  //=======================

  @media (min-width: 840px) {
    margin-top: 60px;
    padding-left: 0rem;
    padding-left: 1rem;
    padding-right: 0rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .mons-second-business {
      margin-top: 0px;
      justify-content: center;

      .only-business-component {
        font-size: 1.1rem;
        margin-bottom: 20px;
        width: 400px;
        color: #666666;
        font-weight: 200;

        .desktop-br {
          display: block;
        }
      }

      .mons-first-child {
        font-size: 2.1rem;
        color: #222222;
        width: 50%;
        line-height: 40px;
      }
      .mons-second-child {
        font-size: 1.3rem;
        color: #222222;
      }
    }
  }
`;

const DivImageContainer = styled.div`
  width: 100%;
  overflow: hidden;

  .desktop {
    display: none;
  }

  .mons-second-business-image {
    width: 100%;
  }

  @media (min-width: 765px) {
    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }
  }

  @media (min-width: 840px) {
    margin-left: 4rem;
    min-width: 300px;
  }
`;

const BuildingAtBusiness = () => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const onTriggerAnimate = useCallback(() => {
    if (!triggerAnimation) setTriggerAnimation((prev) => !prev);
  }, [triggerAnimation]);

  return (
    <ScrollTrigger onEnter={onTriggerAnimate}>
      <MonsWrapper>
        {/* 이미지 섹션 */}
        <DivImageContainer>
          <img
            className={
              triggerAnimation ? "desktop mons-second-business-image ani-one" : "desktop mons-second-business-image"
            }
            src={buildingAtBusinessImage}
            alt={"사업영역-건물지원사업-데스크톱"}
          />
          <img
            className={
              triggerAnimation ? "mobile mons-second-business-image ani-one" : "mobile mons-second-business-image"
            }
            src={buildingAtMobileBusinessImage}
            alt={"사업영역-건물지원사업-모바일"}
          />
        </DivImageContainer>

        {/* 글자 섹션 */}
        <div className="mons-second-business">
          <span
            className={triggerAnimation ? "mons-first-child ani-two" : "mons-first-child"}
            style={{ display: "flex", flexDirection: "column" }}
          >
            주택지원사업(정부지원)
          </span>
          <span className={triggerAnimation ? "mons-second-child ani-three" : "mons-second-child"}>
            3kW 주택용 태양광 정부지원사업
          </span>
          <div className={triggerAnimation ? "only-business-component ani-four" : "only-business-component"}>
            정부지원금을 받아 주택에 설치하여 전기요금 절감효과를 볼 수 있는 사업입니다.
          </div>

          <div className={triggerAnimation ? "ani-five" : ""}>
            <ViewMoreButton others={"0.7rem"} pagePath={"business"} componentPath={"home"} />
          </div>
        </div>
      </MonsWrapper>
    </ScrollTrigger>
  );
};

export default BuildingAtBusiness;
