import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import ChangeableBottomText from "../../components/ChangeableBottomText";

import { useLocation } from "react-router";

import mainBanner from "../../assets/monsinfopage/main_banner.png";
import mbMainBanner from "../../assets/monsinfopage/mdf_mb_main_banner.jpg";
import tabletMainBanner from "../../assets/monsinfopage/tablet_info_banner.png";

import Meta from "../../components/Meta";
import { metaMonsInfoData } from "../../utils/metaData";

const DivImage = styled.div`
  display: none;

  @media (min-width: 800px) {
    display: block;
    margin-top: 20px;
    margin-left: 2%; // 얘가 이미지를 오른쪽으로 치우치게 하는 역할이다.
    margin-bottom: 76px;
    height: 340px;
    background: url(${mainBanner}) no-repeat center;
    background-size: cover;
  }

  @media (min-width: 1100px) {
    margin-left: 7%; // 얘가 이미지를 오른쪽으로 치우치게 하는 역할이다.
    margin-bottom: 76px;
  }
`;

const TabletImage = styled.img`
  display: none;

  @media (min-width: 500px) {
    display: block;
    margin-top: 20px;
    margin-left: 1.3rem;
    width: 100%;
  }

  @media (min-width: 800px) {
    display: none;
  }
`;

const MobileDivImage = styled.img`
  margin-top: 20px;
  margin-left: 1.3rem;
  width: 100%;

  @media (min-width: 500px) {
    display: none;
  }
`;

const SectionTopContainer = styled.section`
  margin-top: 100px;

  overflow: hidden;

  .main-title {
    font-size: 1.7rem;
    font-family: "Pretendard-ExtraBold";
    margin-left: 1rem;
    width: 100%;
    height: 100px;
  }

  .main-bottom-container {
    margin-top: 35px;
    font-size: 1rem;
    font-family: "Pretendard-Medium";
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    display: block;
    justify-content: space-between;
    max-width: 1000px;
    line-height: 20px;

    .main-bottom-left {
      font-size: 1.4rem;
      font-family: "Pretendard-ExtraBold";
      display: flex;
      flex-direction: column;
      color: #222222;
      line-height: 31px;

      .desktop {
        display: none;
      }
    }

    .main-bottom-right {
      margin-top: 31px;
      display: flex;
      flex-direction: column;
      color: #222222;
      font-weight: 200;
      line-height: 25px;

      .right-text {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media (min-width: 880px) {
    margin-top: 160px;
    margin-bottom: 80px;
    max-width: 1900px;
    overflow: visible;

    .dt-wrapper-main-title {
      max-width: 1050px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1rem;
    }

    .only-dt-image-container {
      overflow: hidden;
    }

    .main-title {
      font-size: 2.1rem;
      font-family: "Pretendard-ExtraBold";
      margin-left: 0rem;
      width: 100%;
    }

    .main-bottom-container {
      display: flex;

      .main-bottom-left {
        font-size: 1.35rem;
        line-height: 33px;
        width: 400px;

        .desktop {
          display: block;
        }

        .mobile {
          display: none;
        }
      }

      .main-bottom-right {
        font-size: 1rem;
        margin-top: 0px;
        width: 600px;
        line-height: 27px;
      }
    }
  }
`;

const UlContainer = styled.div`
  width: 100%;

  @media (min-width: 880px) {
    margin: 0 auto;
    width: 95%;
  }
`;

const Ul = styled.ul`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
  max-width: 100%;
  border-top: 1px solid rgba(178, 171, 170, 0.3);
  border-bottom: 1px solid rgba(178, 171, 170, 0.3);

  .li-list {
    cursor: pointer;
    padding: 1rem 0;
    border: none;
  }

  .list-area {
    font-family: ${(props) => (props.innerText === "사업분야" ? "Pretendard-ExtraBold" : "Pretendard-Light")};
    border-bottom: ${(props) => (props.innerText === "사업분야" ? "2px solid black" : "")};
  }

  .list-history {
    font-family: ${(props) => (props.innerText === "연혁" ? "Pretendard-ExtraBold" : "Pretendard-Light")};
    border-bottom: ${(props) => (props.innerText === "연혁" ? "2px solid black" : "")};
  }

  .list-certification {
    font-family: ${(props) => (props.innerText === "인증서" ? "Pretendard-ExtraBold" : "Pretendard-Light")};
    border-bottom: ${(props) => (props.innerText === "인증서" ? "2px solid black" : "")};
  }

  @media (min-width: 880px) {
    max-width: 1000px;
    justify-content: flex-start;
    border-top: 1px solid rgba(178, 171, 170, 0.9);
    border-bottom: none;

    .li-list {
      padding: 1.5rem 0;
      margin-right: 20px;

      &:nth-child(1) {
        margin-right: 20px;
      }
      &:nth-child(2) {
        margin-right: 20px;
      }
    }

    .list-area {
      font-family: ${(props) => (props.innerText === "사업분야" ? "Pretendard-ExtraBold" : "Pretendard-Light")};
      border-bottom: none;
    }

    .list-history {
      font-family: ${(props) => (props.innerText === "연혁" ? "Pretendard-ExtraBold" : "Pretendard-Light")};
      border-bottom: none;
    }

    .list-certification {
      font-family: ${(props) => (props.innerText === "인증서" ? "Pretendard-ExtraBold" : "Pretendard-Light")};
      border-bottom: none;
    }
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const MonsInfo = () => {
  let query = useQuery();

  const [innerText, setInnerText] = useState("사업분야");

  const onCheckLiInnerText = useCallback((e) => {
    setInnerText(e.target.innerText);
  }, []);

  return (
    <>
      <Meta data={metaMonsInfoData} />

      <div
        style={{
          maxWidth: "1600px",
          margin: "0 auto",
          paddingBottom: 50,
        }}
      >
        <SectionTopContainer>
          <div className="dt-wrapper-main-title">
            <span className="main-title">Mons Introduce</span>
          </div>
          <div className="only-dt-image-container">
            <DivImage />
            {/* <DivImage src={mainBanner} /> */}

            <TabletImage src={tabletMainBanner} alt={"몬스 소개"}/>

            <MobileDivImage src={mbMainBanner} alt={"몬스 소개"}/>
          </div>
          <div className="main-bottom-container">
            <div className="main-bottom-left">
              <span className="desktop">
                주식회사 몬스솔라는<br></br>고객과 친환경 신·재생에너지를 연결하여<br></br> 최적의 에코 라이프스타일을
                제공합니다.
              </span>

              <span className="mobile">
                주식회사 몬스솔라는 고객과 친환경 신·재생에너지를 연결하여 최적의 에코 라이프스타일을 제공합니다.
              </span>
            </div>
            <div className="main-bottom-right">
              <div className="right-text">
                <span>
                  다수의 프로젝트를 통해 고도의 노하우와 데이터를 축적하였으며, 이를 바탕으로 가장 최적의 발전 시스템을
                  구축하는 기술력을 갖추었습니다.
                </span>
              </div>
              <div className="right-text">
                <span>
                  빠르게 변화하는 환경 속에서 늘 새로운 목표에 도전하며, 고객과 함께 성장해왔습니다. 앞으로도 끊임없이
                  고민하고 성장하여 더 편리하고 행복한 세상을 만드는데 도움이 되겠습니다.
                </span>
              </div>
            </div>
          </div>
        </SectionTopContainer>

        {/* 여기부터 사업분야, 연혁, 인증서 시작됨 */}
        <UlContainer>
          <Ul innerText={innerText}>
            <li className="li-list list-area" onClick={onCheckLiInnerText}>
              사업분야
            </li>
            <li className="li-list list-history" onClick={onCheckLiInnerText}>
              연혁
            </li>
            <li className="li-list list-certification" onClick={onCheckLiInnerText}>
              인증서
            </li>
          </Ul>
        </UlContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ChangeableBottomText query={query.get("name")} innerText={innerText} />
        </div>
      </div>
    </>
  );
};

export default MonsInfo;
