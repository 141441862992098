import React from "react";
import styled from "@emotion/styled";
import logo from "../../assets/businesspage/solution_banner.jpg";

const Wrapper = styled.div`
  display: none;

  @media (min-width: 765px) {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -90px;
    display: block;
    max-width: 1450px;
    height: 325px;
    z-index: 9898;

    background: url(${logo}) no-repeat center;
    background-size: cover;

    .only-container {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;

      .left-part {
        font-size: 1.66rem;
        font-family: "Pretendard-Medium";
        margin-top: 80px;
        padding-right: 3rem;
        display: flex;
        flex-direction: column;
        line-height: 35px;
        color: #ffffff;
      }

      .right-part {
        margin-top: 80px;
        display: flex;
        width: 75%;
        color: #ffffff;

        .arrow {
          margin: 0 10px;
        }

        .first-container {
          display: flex;
          flex-direction: column;
          margin-right: 1.5rem;
          width: 40%;

          .only-margin-top {
            margin-top: 13px;
          }

          .summary-first-child {
            font-size: 0.9rem;
            font-family: "Pretendard-ExtraBold";
            margin-bottom: 13px;
          }

          .summary-second-child {
            font-size: 1.3rem;
            font-family: "Pretendard-ExtraBold";
            height: 50px;
            line-height: 25px;
            margin-bottom: 15px;
          }

          .summary-third-child {
            font-size: 1rem;
            font-family: "Pretendard-Light";
            line-height: 30px;
          }
        }
      }
    }
  }
`;

const DesktopMainBannerText = () => {
  return (
    <Wrapper>
      <div className="only-container">
        {/* 왼쪽 부분 */}
        <div className="left-part">
          <span className="first-text">몬스솔라</span>
          <span className="second-text">솔루션</span>
        </div>

        {/* 오른쪽 부분 */}
        <div className="right-part">
          <div className="first-container">
            <span className="summary-first-child">Consulting</span>
            <span className="summary-second-child">최적의 발전시스템 컨설팅</span>
            <span className="summary-third-child">최적의 비용을 산출함과 동시에 최상의 수익률을 제공합니다.</span>
          </div>

          <div className="first-container">
            <span className="summary-first-child">Reasonable Price</span>
            <span className="summary-second-child ">
              고품질 시스템과 합리적인<br></br>가격
            </span>
            <span className="summary-third-child ">
              국내외 최고의 품질을 갖춘 태양광 설비 시스템과 전기차 충전기를 비롯한 상품을 합리적인 가격으로 제시합니다.
            </span>
          </div>

          <div className="first-container">
            <span className="summary-first-child">Construction</span>
            <span className="summary-second-child">태양광 시스템 설계 및 시공</span>
            <span className="summary-third-child">
              다년간 축적된 노하우를 바탕으로 고객의 니즈에 적합한 최적의 태양광 솔루션을 제공합니다.
            </span>
          </div>

          <div className="first-container">
            <span className="summary-first-child">A/S Center</span>
            <span className="summary-second-child">사후관리 서비스</span>
            <span className="summary-third-child">한국에너지공단 A/S 전담기업 2020/2021 연속 선정</span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default DesktopMainBannerText;
