import React from "react";
import { Helmet } from "react-helmet";

const locales = {
  en: "en_US",
  ko: "ko_KR",
};

const Meta = ({ data }) => {
  const lang = locales[data.locale] || locales["en"];
  const title = data.title;
  const description = data.description === undefined ? "태양광전문업체 몬스솔라｜MONSSOLAR" : data.description;
  const image = data.image !== undefined && `${data.image}`;
  const canonical = "https://www.mons-solar.com";
  const type = data.type === undefined ? "website" : data.type;
  const width = data.image && (data.width || 1200);
  const height = data.image && (data.height || 630);

  return (
    <Helmet titleTemplate="%s">
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
       {/*<link rel="canonical" href={canonical} />*/}
      {image ? <link rel="image_src" href={image} /> : null}
      {image ? <meta itemprop="image" content={image} /> : null}

      <meta property="og:site_name" content="태양광전문업체 몬스솔라｜MONSSOLAR" />
      <meta property="og:title" content={title} />
      {description ? <meta property="og:description" content={description} /> : null}
      <meta property="og:url" content={canonical} />
      <meta property="og:locale" content={locales[lang]} />
      <meta property="og:type" content={type} />
      {image ? <meta property="og:image" content={image} /> : null}
      {width ? <meta property="og:image:width" content={width} /> : null}
      {height ? <meta property="og:image:height" content={height} /> : null}
      <meta property="fb:pages" content="태양광전문업체 몬스솔라｜MONSSOLAR" />

      <meta name="twitter:card" content={image} />
      <meta name="twitter:title" content={title} />
      {description ? <meta name="twitter:description" content={description} /> : null}
      {image ? <meta name="twitter:image" content={image} /> : null}
      <meta name="twitter:site" content="태양광전문업체 몬스솔라｜MONSSOLAR" />
      {canonical ? <link rel="alternate" href={canonical} hreflang={"ko"} /> : null}
    </Helmet>
  );
};

export default Meta;
