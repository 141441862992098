import { keyframes } from "@emotion/react";

const firstOpacityAndTranslateY = keyframes`
   0% {
      opacity:0;
      transform: translate3d(0px, 30px,0px);
    }
    60% {
      opacity:0.7;
      transform: translate3d(0px, -6px,0px);
    }
    90%{
      opacity:0.9;
      transform: translate3d(0px, -1px,0px);
    }
    100% {
      opacity:1;
      transform: translate3d(0px, 0px,0px);
    }
`;

const secondOpacityAndTranslateY = keyframes`
   0% {
      opacity:0;
      transform: translate3d(0px, 20px,0px);
    }
    60% {
      opacity:0.7;
      transform: translate3d(0px, -2px,0px);
    }
    90%{
      opacity:0.9;
      transform: translate3d(0px, -0.5px,0px);
    }
    100% {
      opacity:1;
      transform: translate3d(0px, 0px,0px);
    }
`;

const thirdOpacityAndTranslateY = keyframes`
   0% {
      opacity:0;
      transform: translate3d(0px, 20px,0px);
    }
    60% {
      opacity:0.7;
      transform: translate3d(0px, -2px,0px);
    }
    90%{
      opacity:0.9;
      transform: translate3d(0px, -0.5px,0px);
    }
    100% {
      opacity:1;
      transform: translate3d(0px, 0px,0px);
    }
`;

const fourthOpacityAndTranslateY = keyframes`
   0% {
      opacity:0;
      transform: translate3d(0px, 20px,0px);
    }
    60% {
      opacity:0.7;
      transform: translate3d(0px, -2px,0px);
    }
    90%{
      opacity:0.9;
      transform: translate3d(0px, -0.5px,0px);
    }
    100% {
      opacity:1;
      transform: translate3d(0px, 0px,0px);
    }
`;

// view more 버튼에는 이 변수를 적용할 것
const fifthOpacityAndTranslateY = keyframes`
   0% {
      opacity:0;
      transform: translate3d(0px, 20px,0px);
    }
    60% {
      opacity:0.7;
      transform: translate3d(0px, -2px,0px);
    }
    90%{
      opacity:0.9;
      transform: translate3d(0px, -0.5px,0px);
    }
    100% {
      opacity:1;
      transform: translate3d(0px, 0px,0px);
    }
`;

// min-width:1024------------
const minWidthFirstBusiness = keyframes`
   0% {
      opacity:0;
      transform: translate3d(-100px, 0px,0px);
    }
    100% {
      opacity:1;
      transform: translate3d(0px, 0px,0px);
    }
`;
const minWidthFirstBuilding = keyframes`
   0% {
      opacity:0;
      transform: translate3d(100px, 0px,0px);
    }
    100% {
      opacity:1;
      transform: translate3d(0px, 0px,0px);
    }
`;
const minWidthFirstHome = keyframes`
   0% {
      opacity:0;
      transform: translate3d(0px, 100px,0px);
    }
    100% {
      opacity:1;
      transform: translate3d(0px, 20px,0px);
    }
`;

const minWidthFirstOpacityAndTranslateY = keyframes`
   0% {
      opacity:0;
      /* transform: translate3d(100px, 0px,0px); */
    }
    60% {
      opacity:0.7;
      /* transform: translate3d(30px, 0px,0px); */
    }
    90%{
      opacity:0.9;
      /* transform: translate3d(10px, 0px,0px); */
    }
    100% {
      opacity:1;
      /* transform: translate3d(0px, 0px,0px); */
    }
`;

const zIndexFirstOpacityAndTranslateY = keyframes`
   0% {
      opacity:0;
      z-index:-12;
      transform: translate3d(0px, 30px,0px);
    }
    60% {
      opacity:0.7;
      z-index:-12;
      transform: translate3d(0px, -6px,0px);
    }
    90%{
      opacity:0.9;
      z-index:-12;
      transform: translate3d(0px, -1px,0px);
    }
    100% {
      opacity:1;
      z-index:-12;
      transform: translate3d(0px, 0px,0px);
    }
`;

export {
  firstOpacityAndTranslateY,
  secondOpacityAndTranslateY,
  thirdOpacityAndTranslateY,
  fourthOpacityAndTranslateY,
  fifthOpacityAndTranslateY,
  minWidthFirstBusiness,
  minWidthFirstBuilding,
  minWidthFirstHome,
  minWidthFirstOpacityAndTranslateY,
  zIndexFirstOpacityAndTranslateY,
};
