import React from "react";
import styled from "@emotion/styled";

import LinkContainer from "../LinkContainer/LinkContainer";

import modifiedMainInverter from "../../assets/productpage/modified_main_inverter.jpg";
import arrow from "../../assets/productpage/arrow.png";

const ImageAndTextContainer = styled.section`
  position: relative;
  z-index: 10;

  .two-text-container {
    top: 0px;
    z-index: 9898;
    position: absolute;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;

    .two-text-content {
      height: 50%;

      .two-top-part {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        div {
          display: flex;
          flex-direction: column;

          .two-first-text {
            font-size: 0.95rem;
            font-family: "Pretendard-Medium";
            color: #ffffff;
          }

          .two-second-text {
            font-size: 2rem;
            font-family: "Pretendard-Bold";
            margin-top: 7px;
            color: #ffffff;
          }
        }
      }

      .two-third-text {
        font-size: 0.8rem;
        font-family: "Pretendard-Light";
        margin-top: 30px;
        line-height: 20px;
        color: #ffffff;
      }

      .desktop-button {
        display: none;
      }
    }
  }

  @media (min-width: 765px) {
    background-position: center;
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;

    // animation 파트 시작
    .two-left-image-ani-0 {
      filter: brightness(55%);
      transform: scale(1);

      transition: all 0.7s;
    }
    // 1번 애니메이션 케이스
    .two-left-image-ani-1 {
      filter: brightness(80%);
      z-index: 1;

      transform: scale(1.1);
      transition: all 0.7s;
    }

    .two-left-image-ani-2 {
      filter: brightness(70%);
      z-index: 1;

      transform: translate3d(20px, 0px, 0px);
      transition: all 0.7s;
    }

    .two-text-left-ani-0 {
      transform: scale(1);
      transition: all 0.7s;
    }

    .two-text-left-ani-1 {
      transform: scale(1.1);
      transition: all 0.7s;
      z-index: 1;
    }

    .two-text-left-ani-2 {
      transform: translate3d(20px, 0px, 0px);
      transition: all 0.7s;
      z-index: 1;
    }

    .two-text-container {
      padding-left: 0rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      height: 100%;

      .two-text-content {
        padding-left: 12%;

        .desktop-button {
          margin-top: 26px;

          .btn {
            font-size: 0.9vw;
            font-family: "Pretendard-Medium";
          
            width: 11vw;
            height: 3.5vw;
            background-color: var(--background-color);
            color: #ffffff;
            padding: 0.5em 1em;
            border: none;
            outline: none;
            position: relative;
            cursor: pointer;
            border: 1px solid #ffffff;
            --border-size: 2px;
            --accent-color: darkgray;
          }

          .btn.btn-background-slide::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: -1;
            background-color: var(--accent-color);

            transition: transform 300ms ease-in-out;
            transform: scaleX(0);
            transform-origin: left;
          }

          .btn.btn-background-slide:hover::before {
            transform: scaleX(1);
          }

          .btn.btn-background-slide:focus::before {
            transform: scaleX(1);
          }

          .btn.btn-background-slide {
            transition: color 300ms ease-in-out;
            z-index: 1;
          }
        }

        .two-third-text {
          font-size: 1.1vw;
          font-family: "Pretendard-Medium";
          margin-top: 3vw;
          line-height: 2vw;
        }

        .two-top-part {
          div {
            .two-first-text {
              font-size: 1.3vw;
              font-family: "Pretendard-Medium";
            }

            .two-second-text {
              font-size: 2.7vw;
              font-family: "Pretendard-Bold";
              margin-bottom: 23px;
            }
          }
        }

        .desktop-button {
          display: block;
        }
      }
    }
  }
`;

const DivImageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const DivImage = styled.img`
  filter: brightness(70%);
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
`;

const DivTextConatiner = styled.div``;

const BottomAndRightContainer = ({ ani }) => {
  return (
    <ImageAndTextContainer>
      <DivImageContainer>
        <DivImage className={`two-left-image-ani-${ani}`} src={modifiedMainInverter} 어/>
      </DivImageContainer>
      <DivTextConatiner className={`two-text-left-ani-${ani} two-text-container`}>
        <div className="two-empty"></div>
        <div className="two-text-content">
          <div className="two-top-part">
            <div>
              <span className="two-first-text">인버터</span>
              <span className="two-second-text">Inverter</span>
            </div>
          </div>

          <div className="two-third-text">
            <img style={{ height: 10, width: 60 }} src={arrow} alt={"몬스솔라 제품"}/> 가정용 3kW부터 대용량 발전소까지<br></br>시스템 구성에
            맞는 인버터를 제공합니다.
          </div>

          <div className="desktop-button">
            <LinkContainer pagePath={"product"} componentPath={"kra-krb"}>
              <button className="btn btn-background-slide">VIEW MORE</button>
            </LinkContainer>
          </div>
        </div>
      </DivTextConatiner>
    </ImageAndTextContainer>
  );
};

export default BottomAndRightContainer;
