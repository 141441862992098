import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";

import ImageCarousel from "./ImageCarousel";

import bannerImg from "../../assets/productpage/eclair/banner.jpg";

import specImage from "../../assets/productpage/eclair/spec.jpg";
import mbSpecImage from "../../assets/productpage/eclair/mb_spec.jpg";
import featureIcon01 from "../../assets/productpage/eclair/01_smallIcon.png";
import featureIcon02 from "../../assets/productpage/eclair/02_smallIcon.png";
import featureIcon03 from "../../assets/productpage/eclair/03_smallIcon.png";

const DivWrapper = styled.div`
  @media (min-width: 765px) {
    .dt-carousel {
      height: 90vh;
    }
  }
`;

const SectionMain = styled.section`
  position: relative;
`;

// div banner text
const DivBannerText = styled.div`
  position: relative;
  margin-bottom: 10vh;
  width: 100%;
  height: 95vh;
  z-index: 99;

  .text-container {
    .only-dt-text {
      display: none;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .mb-wrapper {
      display: flex;
      align-items: flex-end;
    }

    .first-text {
      font-size: 2.1rem;
      font-family: "Pretendard-ExtraBold";
      color: #ffffff;
    }
    .second-text {
      font-size: 1.3rem;
      font-family: "Pretendard-Light";
      color: #ffffff;
    }
  }

  .bottom-container {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;

    @media (min-width: 600px) {
      width: 85%;
    }

    @media (min-width: 700px) {
      width: 75%;
    }

    @media (min-width: 800px) {
      width: 70%;
    }

    @media (min-width: 900px) {
      width: 65%;
    }

    .title {
      font-size: 1.1rem;
      font-family: "Pretendard-Medium";
      margin-bottom: 10px;
      color: #ffffff;
    }

    .content {
      font-size: 1.1rem;
      font-family: "Pretendard-ExtraBold";
      color: #ffffff;
    }

    .vertical-line {
      border-right: thin solid #e5e5e5;

      .only-padding-bottom {
        padding-bottom: 1rem;
      }
    }

    .only-padding-left {
      padding-left: 1rem;
    }

    .top-content {
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex;

      div {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: thin solid white;
      }

      .item {
        font-size: 0.2rem;
      }
    }
    .bottom-content {
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex;

      div {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .only-padding-top {
        padding-top: 1rem;
      }
    }
  }

  @media (min-width: 1265px) {
    margin: 0 auto;
    height: 90vh;

    .text-container {
      .only-dt-text {
        display: block;
      }

      .first-text {
        font-size: 2.9rem;
        font-family: "Pretendard-ExtraBold";
        margin-right: 10px;
      }
      .second-text {
        font-size: 1.6rem;
        font-family: "Pretendard-Medium";
        vertical-align: text-bottom;
      }

      .mobile-text {
        display: none;
      }
    }

    .bottom-container {
      width: 100%;
      justify-content: center;
      flex-direction: row;

      .dt-left {
        margin-left: 10px;
      }

      .vertical-line {
        .only-padding-bottom {
          padding-bottom: 0rem;
        }
      }

      .title {
        padding-right: 1rem;

        width: 100%;
      }

      .top-content {
        margin-left: 123px;
        padding-left: 0rem;
        padding-right: 0rem;

        div {
          border-bottom: none;
        }
      }

      .dt-border-right {
        border-right: thin solid #e5e5e5;
      }

      .only-padding-left {
        padding-left: 0rem;
      }

      .bottom-content {
        margin-top: 0px;
        padding-left: 0rem;
        padding-right: 0rem;

        .only-padding-bottom {
          padding-bottom: 0rem;
        }

        .only-padding-top {
          padding-top: 0rem;
        }
      }

      .dt-item {
        width: 100%;
        height: 80px;
        padding-right: 5rem;
        padding-left: 1rem;

        display: flex;
        justify-content: center;
      }
    }
  }
`;

const DivBannerImageContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 72px;
  height: 91vh;

  @media (min-width: 765px) {
    height: calc(100vh - 72px);
  }
`;

// div banner image
const DivBannerImage = styled.img`
  filter: brightness(70%);
  margin-bottom: 10vh;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const SectionFeatureContainer = styled.section`
  margin-top: 130px;

  div {
    margin-bottom: 41px;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;

    .feature-summary {
      font-size: 1.15rem;
      font-family: "Pretendard-ExtraBold";
      margin-bottom: 19px;
      color: #262626;
    }
    .feature-explain {
      font-size: 1.1rem;
      font-family: "Pretendard-Light";
      line-height: 25px;
      color: #505050;
      line-height: 25px;
    }
  }

  @media (min-width: 765px) {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;

    height: 370px;

    div {
      align-items: center;
      justify-content: center;
      width: 25%;

      .feature-explain {
        text-align: center;
      }
    }
  }
`;

const OpenIcon = styled.img`
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
  color: black;
`;

const SectionSpec = styled.section`
  position: relative;
  display: flex;
  justify-content: center;

  .vanish-open-height-button {
    display: none;
  }

  .open-height-button {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(242, 242, 242, 0.98);
    box-shadow: 0px -15px 21px 21px rgba(242, 242, 242, 0.98);
    z-index: 9999;
    cursor: pointer;
  }

  .clickable-button {
    display: flex;
    height: 80%;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 300px;
    font-family: "Pretendard-Medium";
    cursor: pointer;
    opacity: 0.5;
  }

  .hide-height {
    height: 500px;
    overflow: hidden;
  }

  .bottom-text-wrapper {
    position: absolute;

    padding-left: 1rem;
    padding-right: 1rem;
  }

  .dt-image {
    display: none;
  }

  @media (min-width: 1400px) {
    position: relative;
    display: flex;
    justify-content: center;

    .vanish-open-height-button {
      display: none;
    }

    .open-height-button {
      display: none;
    }

    .dt-image {
      display: block;
      filter: brightness(91%);
    }
    .mb-image {
      display: none;
    }

    .bottom-text-wrapper {
      padding-left: 0rem;
      padding-right: 0rem;

      width: 70vw;
      height: 100%;

      position: absolute;

      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const DivImageText = styled.div`
  opacity: 0.9;
  min-width: 350px;

  .content-container {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    z-index: 999;

    .title {
      font-size: 1.7rem;
      font-family: "Pretendard-ExtraBold";
      margin-top: 70px;
      margin-bottom: 33px;

      color: #ffffff;
    }

    .spec {
      display: flex;
      flex-direction: column;
      background-color: white;

      .spec-position {
        padding-left: 1rem;
        margin-top: 40px;
      }

      .spec-title {
        font-size: 1.1rem;
        font-family: "Pretendard-ExtraBold";
        margin-bottom: 20px;
        color: rgba(34, 34, 34, 1);
      }
      .spec-sub-title {
        font-size: 1.05rem;
        font-family: "Pretendard-Medium";
        margin-bottom: 50px;
        color: rgba(34, 34, 34, 1);
      }

      .bottom-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .position-wrapper {
          padding-left: 1rem;
          padding-right: 1rem;
          display: flex;
          width: 100%;
        }

        .horizontal-line {
          border-bottom: 1px solid #b4b4b4;
        }

        .top-content,
        .bottom-content {
          width: 100%;
          border-top: 2px solid #222222;
          border-bottom: 2px solid #222222;
          margin-bottom: 50px;
        }

        .bottom-content {
          border-left: 2px solid #b4b4b4;
        }

        .box-content {
          padding: 1rem;
          display: flex;
          flex-direction: column;
        }

        .box {
          display: flex;
          justify-content: space-between;
        }

        .box-title {
          font-size: 1.02rem;
          font-family: "Pretendard-Medium";
          margin-bottom: 28px;
          color: #222222;
        }

        .box-spec {
          font-size: 1.1rem;
          font-family: "Pretendard-ExtraBold";

          color: #222222;
        }

        .item-wrapper {
          display: flex;
          flex-direction: column;

          padding-left: 1rem;

          .item-container {
            display: flex;
            padding-right: 1rem;

            .item {
              font-size: 0.9rem;
              font-family: "Pretendard-ExtraBold";

              display: flex;
              flex-direction: column;
              width: 50%;
              margin-bottom: 20px;
              color: #646464;
              line-height: 15px;

              .item-padding-left {
                font-size: 0.9rem;
                font-family: "Pretendard-Medium";
                margin-top: 5px;
                padding-left: 0.7rem;
                color: #646464;
              }
            }
          }
        }
      }
    }

    .qualification {
      padding-left: 1rem;

      .first-qualification,
      .second-qualification {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
      }

      .first {
        font-size: 1.28rem;
        font-family: "Pretendard-ExtraBold";
        margin-bottom: 15px;
        color: #000000;
      }

      .second {
        font-size: 1.05rem;
        font-family: "Pretendard-Medium";
        line-height: 25px;
        color: #222222;
      }
    }
  }

  @media (min-width: 1400px) {
    .content-container {
      height: 50%;
      width: 100%;

      padding-top: 4rem;
      padding-bottom: 4rem;

      .title {
        color: #ffffff;
        font-size: 2.1rem;
        font-family: "Pretendard-ExtraBold";
        padding-left: 2rem;
      }

      .spec {
        /* background-color: skyblue; */

        padding-left: 1rem;

        padding-right: 1rem;
        padding-bottom: 1rem;

        .spec-title {
          font-size: 1.5rem;
          font-family: "Pretendard-ExtraBold";
          margin-top: 8px;
          margin-bottom: 0px;
        }

        .bottom-container {
          margin-top: 20px;
          flex-direction: row;
          height: 100%;

          .item-wrapper {
            width: 100%;

            .item-container {
              padding-right: 0rem;
              .item {
                font-size: 1rem;
                font-family: "Pretendard-ExtraBold";
                color: #444444;
                width: 100%;
                height: 60px;

                line-height: 15px;
                .item-padding-left {
                  font-size: 0.95rem;
                  font-family: "Pretendard-Medium";
                  width: 93%;
                  line-height: 20px;
                  margin-top: 10px;
                }
              }
              .only-padding {
                padding-right: 5rem;

                .item-padding-left {
                  width: 120%;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const DivImage = styled.div`
  width: 100%;
  height: 57rem;
  opacity: 0.95;
  background: url(${specImage}) no-repeat center;
  background-size: cover;

  @media (min-width: 1400px) {
    height: 50rem;
    width: 100%;
  }
`;

const Eclair = () => {
  const [openHeight, setOpenHeight] = useState(false);

  const onOpenHeight = useCallback(() => {
    setOpenHeight((prev) => true);
  }, []);

  return (
    <DivWrapper>
      <SectionMain>
        <DivBannerText>
          <div className="text-container">
            <div className="mb-wrapper">
              <span className="first-text mobile-text">Eclair</span>
              <span className="second-text mobile-text">이클레어</span>
            </div>

            <div className="only-dt-text">
              <span className="first-text">Eclair</span>
              <span className="second-text">이클레어</span>
            </div>
          </div>

          <div className="bottom-container">
            <div className="top-content">
              <div className="vertical-line item dt-item">
                <span className="title">완속충전기</span>
                <span className="only-padding-bottom content">7kW</span>
              </div>

              <div className="only-padding-left item dt-border-right dt-item dt-left">
                <span className="title">LED 탑재</span>
                <span className="content">LED&Buttonless</span>
              </div>
            </div>

            <div className="bottom-content">
              <div className="vertical-line  item dt-item dt-left">
                <span className="only-padding-top title">eclair 전용카드로 충전</span>
                <span className="content">Security&Safety</span>
              </div>

              <div className="only-padding-left item dt-item dt-left">
                <span className="only-padding-top title">편의성을 반영한 설계</span>
                <span className="content">Convenience</span>
              </div>
            </div>
          </div>
        </DivBannerText>

        <DivBannerImageContainer>
          <DivBannerImage className="dt-banner" src={bannerImg} alt={"이클레어"} />
        </DivBannerImageContainer>
      </SectionMain>

      <SectionFeatureContainer>
        <div>
          <span>
            <OpenIcon src={featureIcon01} />
          </span>
          <span className="feature-summary">3시간 기준 22kW급 충전 가능</span>
          <span className="feature-explain">
            국내외 모든 EV차 충전이 가능한 AC 7kW 완속 충전기로 3시간 기준 22kW급 충전이 가능합니다.
          </span>
        </div>

        <div>
          <span>
            <OpenIcon src={featureIcon02} />
          </span>
          <span className="feature-summary">LED&Buttonless</span>
          <span className="feature-explain">
            상태에 따라 변하는 LED 색상으로 전기차 사용자가 쉽게 육안으로 충전상태 및 모든 동작의 상태표시가 가능
          </span>
        </div>

        <div>
          <span>
            <OpenIcon src={featureIcon03} />
          </span>
          <span className="feature-summary">Security&Safety</span>
          <span className="feature-explain">
            국제규격인 IEC와 국내 KC인증이 통과한 향상된 내부 시스템 설계로 별도 누설차단기 시공없이 누설을 감지
          </span>
        </div>
      </SectionFeatureContainer>

      {/* 이미지 캐러셀 부분 */}
      <div className="dt-carousel">
        <ImageCarousel />
      </div>

      {/* 하단에 나오는 주요 스펙 */}
      <SectionSpec>
        <div className={openHeight ? "vanish-open-height-button" : "open-height-button"} onClick={onOpenHeight}>
          <div className="clickable-button">VIEW MORE</div>
        </div>

        <DivImage className="dt-image" src={specImage}></DivImage>
        <DivImage className={openHeight ? "mb-image" : "mb-image hide-height"} src={mbSpecImage} />

        <DivImageText className={openHeight ? "bottom-text-wrapper " : "bottom-text-wrapper hide-height"}>
          <div className="content-container">
            <span className="title">제품 상세 스펙</span>
            <div className="spec">
              <div className="spec spec-position">
                <span className="spec-title">7K Specification</span>
              </div>

              {/* 표 나오는 부분 */}
              <div className="bottom-container">
                {/* 브레이크포인트를 위해 div를 놔둠 */}
                <div className="item-wrapper">
                  {/* 개별 아이템 */}
                  <div className="item-container">
                    <div className="item">
                      <span>· 충전표준</span>
                      <span className="item-padding-left">Type 1,220Vac</span>
                    </div>
                    <div className="item">
                      <span>· 입력 전류 (A)</span>
                      <span className="item-padding-left">32A</span>
                    </div>
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· 최대 출력 용량 (kW)</span>
                      <span className="item-padding-left">7kW</span>
                    </div>
                    <div className="item">
                      <span>· 최대 출력 전류 (A)</span>
                      <span className="item-padding-left">32A</span>
                    </div>
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· 입력 전압</span>
                      <span className="item-padding-left">220Vac +/- 10%</span>
                    </div>
                    <div className="item">
                      <span>· 크기 (W/D/H)</span>
                      <span className="item-padding-left">730/257/400</span>
                    </div>
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· 정격 주파수</span>
                      <span className="item-padding-left">60Hz</span>
                    </div>
                    <div className="item">
                      <span>· 효율</span>
                      <span className="item-padding-left">93%</span>
                    </div>
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· 출력전압 (V)</span>
                      <span className="item-padding-left">220Vac</span>
                    </div>
                    <div className="item">
                      <span>· 표시형식</span>
                      <span className="item-padding-left">LED 점등</span>
                    </div>
                  </div>
                </div>
                {/* 브레이크포인트를 위해 div를 놔둠 */}
                <div className="item-wrapper">
                  {/* 개별 아이템 */}
                  <div className="item-container">
                    <div className="item">
                      <span>· 규격</span>
                      <span className="item-padding-left">KC 61851-1,22</span>
                    </div>
                    <div className="item only-padding">
                      <span>· 설치방식</span>
                      <span className="item-padding-left">스탠드 / 벽부</span>
                    </div>
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· 인증</span>
                      <span className="item-padding-left">KC</span>
                    </div>
                    <div className="item only-padding">
                      <span>· 무게 (kg)</span>
                      <span className="item-padding-left">9.5</span>
                    </div>
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· 보호등급</span>
                      <span className="item-padding-left">IP 44</span>
                    </div>
                    <div className="item only-padding">
                      <span>· 사용자 인증</span>
                      <span className="item-padding-left">
                        RFID 사용자 인식<br></br>RFID 선/후불 카드 인식
                      </span>
                    </div>
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· 충전 시간 (시간)</span>
                      <span className="item-padding-left">3~4시간 (22kW급) // 8~9시간 (6.3kW급)</span>
                    </div>
                    {/* <div className="item only-padding">
                      <span>· 59.3Hz~60.5Hz</span>
                      <span className="item-padding-left">옥외형</span>
                    </div> */}
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· 보호기능</span>
                      <span className="item-padding-left">과전압 보호 / 과전류 보호/ 낙뢰 보호</span>
                    </div>
                    {/* <div className="item only-padding">
                      <span>· 동작온도/보존온도</span>
                      <span className="item-padding-left">-25℃~50℃ / -30℃~70℃</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DivImageText>
      </SectionSpec>
    </DivWrapper>
  );
};

export default Eclair;
