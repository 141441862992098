import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import { authService } from "./fbase";

import Home from "./pages/Home";
import Business from "./pages/Business";
import Project from "./pages/Project";
import Product from "./pages/Product";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import MonsInfo from "./pages/MonsInfo";
import ProjectPost from "./pages/Project/ProjectPost";

import ChannelService from "./components/ChannelService";
import Header from "./components/Header";

import FloatingActionButtons from "./components/FloatingActionButtons";

import { userSlice } from "./reducers/user";
import Footer from "./components/Footer";
import ScrollEveryToTop from "./components/ScrollEveryToTop";
import { mobileMediaQuerySlice } from "./reducers/mobileMediaQuery";

import SignUp from "./components/Auth/SignUp";

const DivFloatingAction = styled.div`
  position: fixed;
  bottom: 160px;
  right: 16px;
  z-index: 99999999;

  @media (min-width: 400px) {
    position: fixed;
    bottom: 185px;
    right: 22px;
  }

  @media (max-width: 565px) {
    display: none;
  }
`;

function App() {
  
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 250) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const handleResize = useCallback(() => {
    if (window.innerWidth < 765) {
      dispatch(mobileMediaQuerySlice.actions.saveMobileMediaQueryBoolean(true));
    } else {
      dispatch(mobileMediaQuerySlice.actions.saveMobileMediaQueryBoolean(false));
    }
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", () => {
      handleResize();
      window.addEventListener("resize", handleResize);
    });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch, handleResize]);

  useEffect(() => {
    authService.onAuthStateChanged((user) => {
      if (user) {
        dispatch(userSlice.actions.saveUserInfo(user));
      } else {
        dispatch(userSlice.actions.unSaveUserInfo(null));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    // 채널톡 연결
    ChannelService.boot({
      pluginKey: process.env.REACT_APP_CHANNEL_KEY,
    });
  }, []);

  return (
    <>
      <Router>
        {/* 특정 페이지에서 다른 페이지로 넘어갈 떄 항상 최상단 높이에서 페이지 렌더링을 하게 해 주는 함수 */}
        <ScrollEveryToTop />

        <Header />

        <Switch>
          {/* 사업영역 */}
          <Route path="/business" component={Business} />
          {/* 프로젝트 */}
          <Route path="/project/:post">
            <ProjectPost />
          </Route>
          <Route path="/project">
            <Project />
          </Route>
          {/* 제품안내 */}
          <Route path="/product" exact component={Product} />
          {/* 블로그 */}
          <Route path="/blog" component={Blog} />
          {/* 고객문의 */}
          <Route path="/contact" component={Contact} />
          {/* 몬스소개 */}
          <Route path="/monsinfo" component={MonsInfo} />
          {/* 로그인 */}
          <Route path="/login" component={SignUp} />

          {/* 홈 */}
          <Route path="/" exact component={Home} />
        </Switch>

        <DivFloatingAction>{isVisible && <FloatingActionButtons />}</DivFloatingAction>
        <Footer />
      </Router>
    </>
  );
}

export default App;
