import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Eclair from "../../components/Product/Eclair";
import KraKrb from "../../components/Product/KraKrb";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import TopAndLeftContainer from "../../components/Product/TopAndLeftContainer";
import BottomAndRightContainer from "../../components/Product/BottomAndRightContainer";
import Kra from "../../components/Product/Kra";
import K50 from "../../components/Product/K50";
import K500 from "../../components/Product/K500";
import Krd from "../../components/Product/Krd";

import Meta from "../../components/Meta";
import { metaProductData } from "../../utils/metaData";

const DivWrapper = styled.div`
  /* 헤더와 간격을 띄우기 위해  */
  margin-top: 72px;
  height: 90vh;
  overflow: hidden;

  .desktop {
    display: none;
  }

  @media (min-width: 765px) {
    display: flex;
    width: 100%;
    .desktop {
      display: flex;
    }

    .mobile {
      display: none;
    }
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Product = () => {
  const checkURL = useLocation();
  const history = useHistory();

  const [xState, setXState] = useState(0);
  const [yState, setYState] = useState(0);

  // 브라우저 너비가 765 이하면 무조건 이클레어로 리다이렉팅하기
  useEffect(() => {
    function replaceEclair() {
      if (checkURL.pathname === "/product" && checkURL.search === "" && window.innerWidth < 1100) {
        history.replace("/product?name=eclair");
      }
    }

    window.addEventListener("resize", replaceEclair);

    return () => window.removeEventListener("resize", replaceEclair);
  }, [checkURL.pathname, checkURL.search, history]);

  const onAllLeave = useCallback(() => {
    setXState((prev) => 0);
    setYState((prev) => 0);
  }, []);

  const onXLeave = useCallback(() => {
    setXState((prev) => 0);
  }, []);

  const onXEnter = useCallback(() => {
    setXState((prev) => 1);
    setYState((prev) => 2);
  }, []);

  const onYLeave = useCallback(() => {
    setYState((prev) => 0);
  }, []);

  const onYEnter = useCallback(() => {
    setYState((prev) => 1);
    setXState((prev) => 2);
  }, []);

  let query = useQuery();

  if (query.get("name") === "eclair") {
    // eclair
    return <Eclair query={query.get("name")} />;
  } else if (query.get("name") === "kra-krb") {
    // inverter
    return <KraKrb query={query.get("name")} />;
  } else if (query.get("name") === "krd") {
    // inverter
    return <Krd query={query.get("name")} />;
  } else if (query.get("name") === "kra") {
    // inverter
    return <Kra query={query.get("name")} />;
  } else if (query.get("name") === "50k") {
    // inverter
    return <K50 query={query.get("name")} />;
  } else if (query.get("name") === "500k") {
    // inverter
    return <K500 query={query.get("name")} />;
  } else {
    return (
      <>
        <Meta data={metaProductData} />
        <DivWrapper onMouseLeave={onAllLeave}>
          <div className="mobile" style={{ width: "100%" }}>
            <Link to="/product?name=eclair">
              <TopAndLeftContainer />
            </Link>
          </div>
          <div onMouseEnter={onXEnter} onMouseLeave={onXLeave} className="desktop" style={{ width: "100%" }}>
            <TopAndLeftContainer ani={xState} />
          </div>

          <div className="mobile" style={{ width: "100%" }}>
            <Link to="/product?name=kra-krb">
              <BottomAndRightContainer />
            </Link>
          </div>
          <div onMouseEnter={onYEnter} onMouseLeave={onYLeave} className="desktop" style={{ width: "100%" }}>
            <BottomAndRightContainer ani={yState} />
          </div>
        </DivWrapper>
      </>
    );
  }
};

export default Product;
