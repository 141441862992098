import React from "react";
import styled from "@emotion/styled";

import certification01Image from "../../assets/monsinfopage/mdf_ctf01.jpg";
import certification02Image from "../../assets/monsinfopage/mdf_ctf02.jpg";
import certification03Image from "../../assets/monsinfopage/mdf_ctf03.jpg";
import certification04Image from "../../assets/monsinfopage/mdf_ctf04.jpg";
import certification05Image from "../../assets/monsinfopage/mdf_ctf05.jpg";
import certification06Image from "../../assets/monsinfopage/mdf_ctf06.jpg";
import certification07Image from "../../assets/monsinfopage/mdf_ctf07.jpg";
import certification08Image from "../../assets/monsinfopage/mdf_ctf08.jpg";
import certification09Image from "../../assets/monsinfopage/host.jpg";
import certification10Image from "../../assets/monsinfopage/ks.jpg";
import certification11Image from "../../assets/monsinfopage/mdf_ctf11.jpg";

const DivWrapper = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 765px) {
    margin-top: 30px;
    padding-left: 0rem;
    padding-right: 0rem;
    max-width: 1000px;
    flex-direction: row;
  }
`;

const SectionMainText = styled.section`
  width: 100%;
  .main-text-container {
    padding-right: 1rem;
    display: flex;
    flex-direction: column;

    .main-text {
      font-size: 1.4rem;
      font-family: "Pretendard-ExtraBold";
      margin-top: 35px;
      margin-bottom: 15px;
    }
    .sub-text {
      font-size: 1rem;
      font-family: "Pretendard-Light";
      color: #222222;

      line-height: 25px;
    }
  }
  @media (min-width: 765px) {
    width: 48%;

    padding-left: 1rem;
    margin-right: 30px;
    .main-text-container {
      .main-text {
        font-size: 1.7rem;
        font-family: "Pretendard-ExtraBold";
        margin-bottom: 20px;
      }

      .sub-text {
        line-height: 25px;
      }
    }
  }
`;

const SectionContent = styled.section`
  margin-top: 61px;
  display: flex;
  justify-content: center;

  .post-wrapper {
    padding-right: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;

    .certification {
      max-width: 200px;
    }

    .certificate-title {
      font-size: 1.2rem;
      font-family: "Pretendard-Medium";
      margin-top: 13px;
      display: flex;
      justify-content: center;
      text-align: center;
      line-height: 22px;
    }
  }

  @media (min-width: 765px) {
    margin-top: 35px;
    width: 100%;

    .post-wrapper {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 14px;

      .certificate-title {
        font-size: 1.1rem;

        margin-top: 10px;
      }
    }
  }
`;

const DivImage = styled.img`
  width: 100%;
  border: 1px solid #e2e2e2;
`;

const MonsInfoCertification = () => {
  return (
    <DivWrapper>
      {/* 왼쪽 */}
      <SectionMainText>
        <div className="main-text-container">
          <span className="main-text">인증서</span>
          <div className="sub-text">
            <span>고객과 함께 성장을 한다는 기업 이념으로 최고의 </span>
            <span>서비스를 전달하고자 최선을 다하고 있습니다.</span>
          </div>
        </div>
      </SectionMainText>

      {/* 오른쪽 */}
      <SectionContent>
        <div className="post-wrapper">
          <div className="certification">
            <DivImage src={certification01Image} alt={"몬스인증서-에너지공단표창장"} />
            <div className="certificate-title">한국에너지공단 표창장</div>
          </div>

          <div className="certification">
            <DivImage src={certification02Image} alt={"몬스인증서-itqa9001"} />
            <div className="certificate-title">ITQA 9001 인증서</div>
          </div>
          <div className="certification">
            <DivImage src={certification03Image} alt={"사업자등록증"} />
            <div className="certificate-title">사업자등록증</div>
          </div>
          <div className="certification">
            <DivImage src={certification04Image} alt={"전기공사업등록증"} />
            <div className="certificate-title">전기공사업등록증</div>
          </div>
          <div className="certification">
            <DivImage src={certification05Image} alt={"as전담업체"} />
            <div className="certificate-title">
              신재생에너지설비<br></br>AS전담업체
            </div>
          </div>
          <div className="certification">
            <DivImage src={certification06Image} alt={"중소기업확인서"} />
            <div className="certificate-title">중소기업확인서</div>
          </div>

          <div className="certification">
            <DivImage src={certification07Image} alt={"우수기업확인서"} />
            <div className="certificate-title">
              나이스평가<br></br>우수기업확인서
            </div>
          </div>
          <div className="certification">
            <DivImage src={certification08Image} alt={"벤처기업확인서"} />
            <div className="certificate-title">벤처기업확인서</div>
          </div>
          <div className="certification">
            <DivImage src={certification09Image} alt={"대리점확인서"} />
            <div className="certificate-title">대리점확인서</div>
          </div>
          <div className="certification">
            <DivImage src={certification10Image} alt={"ks인증"} />
            <div className="certificate-title">KS 인증(태양광 발전용 접속함)</div>
          </div>
          <div className="certification">
            <DivImage src={certification11Image} alt={"직접생산확인서"} />
            <div className="certificate-title">직접생산확인증명서</div>
          </div>
        </div>
      </SectionContent>
    </DivWrapper>
  );
};

export default MonsInfoCertification;
