import React, { useCallback, useState } from "react";

import styled from "@emotion/styled";
import * as Ani from "./animation";

import ScrollTrigger from "react-scroll-trigger";
import ViewMoreButton from "../../components/ViewMoreButton/ViewMoreButton";
import HomeAtHomeImage from "../../assets/mainpage/navyUnnamed.jpg";

const Wrapper = styled.div`
  overflow: hidden;
  margin-top: 18px;
  margin-bottom: 18px;

  .desktop {
    display: none;
  }

  .mobile-ani {
    animation-duration: 1.5s;
    animation-name: ${Ani.firstOpacityAndTranslateY};
  }

  .test-wrapper {
    position: relative;

    .image-container {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 100%;
      height: 43vh; // 브레이크포인트
      z-index: -1;
      object-position: center;
      object-fit: cover;
    }
  }

  .third-business {
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 43vh; // 브레이크포인트

    // 애니메이션 시작
    .first-ani {
      animation-duration: 1.5s;
      animation-name: ${Ani.firstOpacityAndTranslateY};
    }
    .second-ani {
      animation-duration: 1.7s;
      animation-name: ${Ani.secondOpacityAndTranslateY};
    }
    .third-ani {
      animation-duration: 1.7s;
      animation-name: ${Ani.thirdOpacityAndTranslateY};
    }

    .fifth-ani {
      animation-duration: 1.8s;
      animation-name: ${Ani.fifthOpacityAndTranslateY};
    }

    .first-child {
      font-size: 1.7rem;
      font-family: "Pretendard-ExtraBold";
      margin-bottom: 8px;
      color: #ffffff;
    }
    .second-child {
      font-size: 1.4rem;
      font-family: "Pretendard-ExtraBold";
      margin-bottom: 14px;
      color: #ffffff;
    }
    .third-child {
      font-size: 1.1rem;
      font-family: "Pretendard-Light";
      color: #ffffff;
      margin-top: 27px;
      margin-bottom: 52px;
    }
  }
  //
  @media (min-width: 600px) {
    height: 50vh; // 브레이크포인트

    .test-wrapper {
      .image-container {
        height: 50vh; // 브레이크포인트
        object-position: center;
        object-fit: cover;
      }
    }

    .third-business {
      height: 50vh; // 브레이크포인트
    }
  }
  //
  @media (min-width: 865px) {
    .desktop {
      margin-top: 0px;
      margin-bottom: 0px;
      padding-bottom: 4rem;
      display: block;
      width: 550px;
      height: 550px;

      .dt-third-business {
        padding-left: 5rem;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: auto;
        height: 250px;

        animation-duration: 1.5s;
        animation-name: ${Ani.zIndexFirstOpacityAndTranslateY};

        // 애니메이션 시작
        .first-ani {
          animation-duration: 1.5s;
          animation-name: ${Ani.firstOpacityAndTranslateY};
        }
        .second-ani {
          animation-duration: 1.7s;
          animation-name: ${Ani.secondOpacityAndTranslateY};
        }
        .third-ani {
          animation-duration: 1.7s;
          animation-name: ${Ani.thirdOpacityAndTranslateY};
        }
        .fifth-ani {
          animation-duration: 1.8s;
          animation-name: ${Ani.fifthOpacityAndTranslateY};
        }

        .first-child {
          font-size: 1.7rem;
          margin-bottom: 8px;
          color: #ffffff;
          font-family: "Pretendard-ExtraBold";
        }
        .second-child {
          font-size: 1.4rem;
          margin-bottom: 14px;
          color: #ffffff;
          font-family: "Pretendard-ExtraBold";
        }
        .third-child {
          font-size: 1.1rem;
          color: #505050;
          margin-top: 27px;
          margin-bottom: 52px;
          color: #ffffff;
          font-family: "Pretendard-Light";
        }
      }
      .dt-i-ani {
        animation-duration: 1.5s;
        animation-name: ${Ani.zIndexFirstOpacityAndTranslateY};
      }
      .dt-i {
        position: absolute;

        .dt-image {
          position: absolute;
          width: 550px;
          height: 620px;
          bottom: -53px;
          left: 40px;
          z-index: -13;
          opacity: 0.99;
        }
      }
    }

    .mobile {
      display: none;
    }
  }
`;

const HomeAtHome = () => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const onTriggerAnimate = useCallback(() => {
    if (!triggerAnimation) setTriggerAnimation(true);
  }, [triggerAnimation]);

  return (
    <ScrollTrigger onEnter={onTriggerAnimate}>
      <Wrapper>
        <div className="mobile">
          <div className={triggerAnimation ? "test-wrapper mobile-ani" : "test-wrapper"}>
            <img className="image-container" src={HomeAtHomeImage} alt={"주택지원사업"} />
          </div>

          <div className={triggerAnimation ? "third-business mobile-ani" : "third-business"}>
            <span className={triggerAnimation ? "first-child first-ani" : "first-child"}>주택지원사업</span>
            <span className={triggerAnimation ? "second-child second-ani" : "second-child"}>(정부지원)</span>
            <span className={triggerAnimation ? "third-child third-ani" : "third-child"}>
              3kW 주택용 태양광 정부지원사업입니다.
            </span>

            <ViewMoreButton
              borderAndFontColor={"white"}
              triggerAnimation={triggerAnimation}
              pagePath={"business"}
              componentPath={"home"}
            />
          </div>
        </div>

        <div className="desktop">
          <div className="dt-third-business">
            <span className={triggerAnimation ? "first-child first-ani" : "first-child"}>주택지원사업</span>
            <span className={triggerAnimation ? "second-child second-ani" : "second-child"}>(정부지원)</span>
            <span className={triggerAnimation ? "third-child third-ani" : "third-child"}>
              3kW 주택용 태양광 정부지원사업입니다.
            </span>

            <ViewMoreButton
              borderAndFontColor={"white"}
              triggerAnimation={triggerAnimation}
              pagePath={"business"}
              componentPath={"home"}
            />
          </div>

          <div className={triggerAnimation ? "dt-i-ani dt-i" : "dt-i"}>
            <img src={HomeAtHomeImage} className="dt-image" />
          </div>
        </div>
      </Wrapper>
    </ScrollTrigger>
  );
};

export default HomeAtHome;
