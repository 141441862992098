import React from "react";
import { useLocation } from "react-router-dom";
import LinkContainer from "../LinkContainer/LinkContainer";

const NavAtNotHome = ({ pathname }) => {
  const { state } = useLocation();

  return (
    <>
      <div className="li-list nav-not-home">
        <LinkContainer pagePath={"business"}>
          <span className={pathname === "/business" ? "clicked-text" : ""}>사업영역</span>
        </LinkContainer>
      </div>
      <div className="li-list  nav-not-home ">
        <LinkContainer pagePath={"project"}>
          <span className={pathname === "/project" ? "clicked-text" : state ? "clicked-text" : ""}>프로젝트</span>
        </LinkContainer>
      </div>
      <div className="li-list nav-not-home ">
        <LinkContainer pagePath={"product"}>
          <span className={pathname === "/product" ? "clicked-text" : ""}>제품안내</span>
        </LinkContainer>
      </div>
      <div className="li-list  nav-not-home only-width">
        <LinkContainer pagePath={"blog"}>
          <span className={pathname === "/blog" ? "clicked-text" : ""}>블로그</span>
        </LinkContainer>
      </div>
      <div className="li-list nav-not-home ">
        <LinkContainer pagePath={"contact"}>
          <span className={pathname === "/contact" ? "clicked-text" : ""}>고객문의</span>
        </LinkContainer>
      </div>
      <div className="li-list nav-not-home ">
        <LinkContainer pagePath={"monsinfo"}>
          <span className={pathname === "/monsinfo" ? "clicked-text" : ""}>몬스소개</span>
        </LinkContainer>
      </div>
    </>
  );
};

export default NavAtNotHome;
