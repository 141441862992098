import React, { useCallback, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { dbService } from "../../fbase";
import ToastEditor from "../../components/ToastEditor";

import Modal from "react-modal";

import MainBannerCarousel from "../../components/Project/MainBannerCarousel";
import Arrow from "../../components/Generals/Arrow";
import { isTablet } from "react-device-detect";

const DivWrapper = styled.div`
  overflow-x: hidden;
  padding-bottom: 80px;
  margin-top: 72px;

  .only-dt-banner-text,
  .only-dt-carousel {
    display: none;
  }

  @media (min-width: 765px) {
    overflow-x: none;
    margin-top: 0px;
    padding-bottom: 100px;

    .only-dt-banner-text,
    .only-dt-carousel {
      display: block;
    }
  }

  .post-container {
    display: block;
    justify-content: none;
    align-items: none;
    max-width: none;

    @media (min-width: 765px) {
      margin: 0 auto;
      padding-top: 50px;
      padding-left: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1000px;
    }
  }
`;

const TestDiv = styled.div`
  width: 100%;
  height: 50vh;
  overflow: hidden;

  @media (min-width: 765px) {
    width: 48%;
    height: 450px;
  }
`;

const DivImage = styled.div`
  width: 100%;
  height: 100%;
`;

// div banner text
const DivBannerText = styled.div`
  /* background-color:red; */
  margin-bottom: 7.5vh;
  position: relative;
  width: 100%;
  height: 100vh;
  /* z-index: 99; */
  /* background-size: cover; */

  .dt-container {
    display: flex;
    justify-content: center;
  }

  .dt-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dt-first-text {
    font-size: 1.33rem;
    font-family: "Pretendard-Medium";
    margin-bottom: 15px;
    color: #ffffff;
  }

  .dt-second-text {
    font-size: 2.8rem;
    font-family: "Pretendard-ExtraBold";
    color: #ffffff;
  }

  .text-container {
    height: 100%;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;

    .first-text {
      font-size: 1.1rem;
      font-family: "Pretendard-Medium";
      margin-bottom: 0.5em;
    }
    .second-text {
      font-size: 2.1rem;
      font-family: "Pretendard-Medium";
    }
    .routing-button {
      border: 2px solid gray;
      width: 130px;
    }

    @media (min-width: 765px) {
      display: flex;
      justify-content: center;
      height: 100vh;
    }
  }

  @media (min-width: 765px) {
    height: 95vh;
  }
`;

const SectionTextContainer = styled.section`
  margin-top: 70px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;

  .content-desktop,
  .content-mobile {
    display: flex;
    flex-direction: column;

    .first-child {
      font-size: 1.1rem;
      font-family: "Pretendard-Medium";
      margin-bottom: 10px;
      color: #222222;
    }
    .second-child {
      font-size: 1.9rem;
      font-family: "Pretendard-ExtraBold";
      margin-bottom: 20px;
      color: #222222;
    }
    .third-child {
      font-size: 1.3rem;
      font-family: "Pretendard-Medium";
      padding-top: 2rem;
      padding-bottom: 2rem;
      /* line-height: 80px; */
      line-height: 30px;
      /* margin-bottom:20px; */
      color: #222222;
      /* border: 1px solid red; */
    }

    .fourth-child {
      font-size: 1.1rem;
      font-family: "Pretendard-Light";
      margin-bottom: 41px;

      color: #666666;
      line-height: 30px;

      @media (min-width: 765px) {
        margin-bottom: 0px;
        //height:35px;
      }
    }
  }

  .content-desktop {
    display: none;
  }

  @media (min-width: 765px) {
    width: 45%;
    margin-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    height: 350px;
    justify-content: space-between;

    .content-mobile {
      display: none;
    }

    .content-desktop {
      display: flex;

      .first-child {
        margin-bottom: 14px;
      }

      .second-child {
        font-family: "Pretendard-ExtraBold";
      }

      .third-child {
        padding-top: 1rem;
        padding-bottom: 0rem;
        /* border: 2px solid blue; */
        line-height: 40px;
        margin-bottom: 20px;
      }
    }
  }

  .table {
    display: flex;
    border-top: 1px solid #222222;
    border-bottom: 1px solid #222222;
    width: 100%;

    height: 100%;

    @media (min-width: 765px) {
      height: auto;
    }

    .first-child,
    .second-child {
      font-size: 1.1rem;
      font-family: "Pretendard-Medium";
      display: flex;
      flex-direction: column;
    }

    .label,
    .label-non {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 0.3rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #707070;
    }
    .label {
      border-bottom: 1px solid #dcdcdc;
    }

    .first-child {
      width: 30%;
      border-right: 1px solid #dcdcdc;

      div {
        font-weight: bold;
      }
    }

    .second-child {
      width: 100%;
      div {
        padding-left: 15px;
        color: #707070;
      }
    }
  }
`;

const SectionGoBack = styled.section`
  position: relative;
  margin-top: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: "Pretendard-Medium";

  .admin-see-box {
    margin-top: 20px;
    border: 1px solid black;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .only-admin-see-edit,
  .only-admin-see-delete {
    width: 40px;
    height: 40px;
  }
  .only-admin-see-edit {
    top: 0px;
  }
  .only-admin-see-delete {
    top: 30px;
  }

  .post-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 161px;
    height: 53px;
    border: 1px solid #707070;
    border-radius: 5px;
    cursor: pointer;

    span {
      color: #222222;
    }
  }

  .post-edit {
    background-color: whitesmoke;
  }

  .post-delete {
    background-color: #7d7d7d;
    color: white;
  }

  @media (min-width: 765px) {
    flex-direction: row;

    .admin-see-box {
      margin-top: 0px;
      margin-left: 30px;
    }
  }
`;

const ProjectPost = () => {
  const history = useHistory();

  const refScrollToProjectPost = useRef();

  const { state } = useLocation();
  const userInfo = useSelector((state) => state.user.userInfo);
  const [editorModalOpen, setEditorModalOpen] = useState(false);

  // 글 수정하기 위해 에디터를 오픈함 -> 다만 수정하는 로직은 해당 컴포넌트에 없고, ToastEditor 컴포넌트에 있음
  const onEditClick = useCallback(() => {
    setEditorModalOpen((prev) => !prev);
  }, []);

  const onDeleteClick = useCallback(async () => {
    const ok = window.confirm("삭제하시겠습니까?");

    if (ok) {
      await dbService.doc(`projects/${state.id}`).delete();
      history.goBack();
    }
  }, [history, state?.id]);

  return (
    <DivWrapper>
      {/* 데스크톱 사이즈일 떄만 나옴*/}
      {/* 이미지 섹션 */}
      <div className="only-dt-banner-text">
        <DivBannerText>
          <div className="text-container">
            <div className="dt-container">
              <div className="dt-text-container">
                <div className="dt-first-text">몬스솔라에서 진행한 프로젝트입니다.</div>
                <div>
                  <span className="dt-second-text">Monssolar&nbsp;</span>
                  <span className="dt-second-text">Project</span>
                </div>
              </div>

              <Arrow isTablet={isTablet} refObj={refScrollToProjectPost} />
            </div>
          </div>
        </DivBannerText>

        <MainBannerCarousel />
      </div>

      {/* 개별 포스트의 내용이 나오는 부분 */}
      <div className="post-container">
        <TestDiv>
          {/* <img className="post-img" src={state.attachmentURL} alt="mons" /> */}
          <DivImage style={{ background: `url(${state?.attachmentURL}) no-repeat center`, backgroundSize: "cover" }} />
        </TestDiv>

        <SectionTextContainer ref={refScrollToProjectPost}>
          <div className="content-mobile">
            <span className="first-child">프로젝트</span>
            <span className="second-child">{state?.title}</span>
            <span className="third-child">{state?.main_content}</span>
            {/*<span className="fourth-child">{state.sub_content}</span>*/}
          </div>

          {/* 데스크톱 사이즈일 때만 content desktiop 가 나옴 */}
          <div className="content-desktop">
            <span className="first-child">프로젝트</span>
            <span className="second-child">{state?.title}</span>
          </div>

          <div className="content-desktop">
            <span className="third-child">{state?.main_content}</span>
            {/*<span className="fourth-child">{state.sub_content}</span>*/}
          </div>

          <div className="table">
            <div className="first-child">
              <div className="label">형태/용량</div>
              <div className="label">기간</div>
              <div className="label-non">시공지역</div>
            </div>
            <div className="second-child">
              <div className="label">{state?.table_title}</div>
              <div className="label">{state?.table_dueDate}</div>
              <div className="label-non">{state?.table_place}</div>
            </div>
          </div>
        </SectionTextContainer>
      </div>

      <SectionGoBack>
        <Link to="/project">
          <div className="post-button">
            <span>목록으로</span>
          </div>
        </Link>
        {userInfo && (
          <>
            <span className="only-admin-see-edit admin-see-box post-button post-edit" onClick={onEditClick}>
              수&nbsp;정
            </span>
            {editorModalOpen && (
              <div>
                <Modal
                  shouldCloseOnEsc={false}
                  preventScroll={true}
                  isOpen={editorModalOpen}
                  onRequestClose={onEditClick}
                  style={customStyles}
                >
                  <ToastEditor
                    editArticle={true}
                    initValue={state}
                    postId={state?.id}
                    toggleModalEditorOpen={onEditClick}
                  />
                </Modal>
              </div>
            )}
            <span className="only-admin-see-delete admin-see-box post-button post-delete" onClick={onDeleteClick}>
              삭&nbsp;제
            </span>
          </>
        )}
      </SectionGoBack>
    </DivWrapper>
  );
};

export default ProjectPost;

// 모달 스타일
const customStyles = {
  content: {
    zIndex: "998888888899",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
