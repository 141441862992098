import React from "react";
import styled from "@emotion/styled";

import LinkContainer from "../LinkContainer/LinkContainer";

import modifiedMainEclair from "../../assets/productpage/modified_main_eclair.png";
import arrow from "../../assets/productpage/arrow.png";

const ImageAndTextContainer = styled.section`
  min-height: 50vh;
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .text-content {
      margin-top: -5px;
      padding-left: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 40%;
      background-color: #212121;
    }

    .top-part {
      display: flex;
      justify-content: space-between;

      .desktop-button {
        display: none;
      }
    }

    div {
      .first-text {
        // 애니메이션이 없는 초기 텍스트 상태
        font-size: 1.5rem;
        font-family: "Pretendard-Bold";
        color: #ffffff;
      }

      .second-text {
        font-size: 1rem;
        font-family: "Pretendard-Medium";
        margin-left: 5px;
        color: #ffffff;
      }
    }

    .third-text {
      line-height: 20px;
      color: #ffffff;
      font-size: 0.8rem;
      font-family: "Pretendard-Light";
    }
  }

  @media (min-width: 765px) {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;

    // animation 파트 시작
    .left-image-ani-0 {
      filter: brightness(80%);

      transform: scale(1);
      transition: all 0.7s;
    }
    // 1번 애니메이션 케이스
    .left-image-ani-1 {
      filter: brightness(100%);

      z-index: 1;
      transform: scale(1.1);
      transition: all 0.7s;
    }

    .left-image-ani-2 {
      filter: brightness(80%);

      z-index: 1;
      transform: translate3d(-20px, 0px, 0px);
      transition: all 0.7s;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 35%;

      .text-content {
        margin-top: 0px;
        padding-left: 11%;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .third-text {
          font-size: 1.12vw;
          font-family: "Pretendard-Medium";
        }
      }

      .top-part {
        display: flex;
        align-items: flex-end;
        width: 80%;
        margin-bottom: 4%;
        margin-top: 2%;

        .first-text {
          font-size: 2.7vw;
          font-family: "Pretendard-Bold";
        }

        .second-text {
          color: #ffffff;
          font-size: 1.3vw;
          font-family: "Pretendard-Medium";
          margin-left: 10px;
        }

        div {
          .second-text {
            color: #ffffff;
          }
        }

        .desktop-button {
          height: 100%;
        }

        .btn {
          font-size: 0.9vw;
          font-family: "Pretendard-Medium";
        }
      }

      .third-text {
        font-size: 1.1rem;
        font-family: "Pretendard-Light";
        line-height: 2vw;
      }

      .top-part {
        .desktop-button {
          display: block;
          color: white;
        }

        .btn {
          background-color: var(--background-color);
          width: 11vw;
          height: 3.5vw;

          color: #ffffff;
          padding: 0.5em 1em;
          border: none;
          outline: none;
          position: relative;
          cursor: pointer;
          border: 1px solid gray;
          --border-size: 2px;

          --accent-color: darkgray;
        }

        .btn.btn-background-slide::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: -1;
          background-color: var(--accent-color);

          transition: transform 300ms ease-in-out;
          transform: scaleX(0);
          transform-origin: left;
        }

        /* 이 부분이 파란색으로 밀어주는 효과 -> js 로 바꾸자 */
        .btn.btn-background-slide:hover::before {
          transform: scaleX(1);
        }

        .btn.btn-background-slide:focus::before {
          transform: scaleX(1);
        }

        .btn.btn-background-slide {
          transition: color 300ms ease-in-out;
          z-index: 1;
        }
      }
    }
  }
`;

const DivImageContainer = styled.div`
  width: 100%;
  height: 65%;
`;

const DivImage = styled.img`
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
`;

const DivTextConatiner = styled.div``;

const TopAndLeftContainer = ({ ani }) => {
  return (
    <ImageAndTextContainer>
      <DivImageContainer>
        <DivImage src={modifiedMainEclair} className={`left-image-ani-${ani}`} alt={"몬스솔라 이클레어 제품"}/>
      </DivImageContainer>
      <DivTextConatiner className={`left-image-ani-${ani} text-container`}>
        <div className="empty"></div>

        <div className="text-content">
          <div className="top-part">
            <div>
              <span className="first-text">Eclair</span>
              <span className="second-text">이클레어</span>
            </div>

            <div className="desktop-button">
              <LinkContainer pagePath={"product"} componentPath={"eclair"}>
                <button className="btn btn-background-slide">VIEW MORE</button>
              </LinkContainer>
            </div>
          </div>
          <span className="third-text">
            <img style={{ height: 10, width: 60 }} src={arrow} alt={"몬스솔라 이클레어 제품"}/> 국내외 모든 EV차 충전이 가능한<br></br>주택/아파트
            설치용 전기차 충전기입니다.
          </span>
        </div>
      </DivTextConatiner>
    </ImageAndTextContainer>
  );
};

export default TopAndLeftContainer;
