import React from "react";
import styled from "@emotion/styled";


const DivWrapper = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 765px) {
    margin-top: 30px;
    padding-left: 0rem;
    padding-right: 0rem;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1000px;
  }
`;

const SectionMainText = styled.section`
  .main-text-container {
    padding-right: 1rem;
    display: flex;
    flex-direction: column;

    .main-text {
      font-size: 1.4rem;
      font-family: "Pretendard-ExtraBold";
      margin-top: 35px;
      margin-bottom: 15px;
    }
    .sub-text {
      font-size: 1rem;
      font-family: "Pretendard-Light";
      color: #222222;

      line-height: 25px;
    }
  }
  @media (min-width: 765px) {
    width: 31%;

    padding-left: 1rem;
    .main-text-container {
      .main-text {
        font-size: 1.7rem;
        font-family: "Pretendard-ExtraBold";
      }

      .sub-text {
        line-height: 25px;
      }
    }
  }
`;

const SectionContent = styled.section`
  overflow: hidden;

  .container {
    margin-top: 50px;
    padding-bottom: 50px;

    border-bottom: 1px solid #cbcbcb;
  }

  .main-content {
    max-width: 100%;
  }

  .last-container {
    margin-top: 50px;
    padding-bottom: 50px;
  }
  .main-title {
    font-size: 1.25rem;
    font-family: "Pretendard-ExtraBold";
    margin-bottom: 28px;
    color: #262626;
  }
  .first-content {
    margin-top: 28px;
    display: flex;
    line-height: 30px;
  }
  .year-column {
    font-size: 1.2rem;
    margin-right: 15px;
    font-family: "Pretendard-Medium";
  }
  .specific-column {
    font-size: 1.1rem;
    font-family: "Pretendard-Medium";
    display: flex;
    flex-direction: column;
    color: #707070;
  }
  .second-content {
    display: flex;
    line-height: 30px;
  }

  @media (min-width: 765px) {
    .container {
      width: 100%;
      margin-top: 35px;
      padding-bottom: 35px;
      padding-right: 160px;

      .main-title {
        font-size: 1.45rem;
        margin-bottom: 28px;
      }

      .main-content {
        .space-item {
          margin-top: 30px;
        }

        .specific-column {
          font-size: 1.05rem;
          line-height: 30px;

          color: rgb(112, 112, 112);
        }

        .year-column {
          font-size: 1.3rem;
          width: 5rem;
        }
      }
    }

    .last-container {
      width: 100%;
      margin-top: 35px;
      padding-bottom: 35px;
      padding-right: 160px;

      .main-title {
        font-size: 1.45rem;
        margin-bottom: 28px;
      }

      .main-content {
        .space-item {
          margin-top: 30px;
        }

        .specific-column {
          font-size: 1.05rem;
          line-height: 30px;

          color: rgb(112, 112, 112);
        }

        .year-column {
          font-size: 1.3rem;
          width: 5rem;
        }
      }
    }
  }
`;

const MonsInfoHistory = () => {
  return (
    <DivWrapper>
      {/* 왼쪽 */}
      <SectionMainText>
        <div className="main-text-container">
          <span className="main-text">몬스솔라 연혁</span>
          <div className="sub-text">
            <span>고객과 함께 성장을 한다는 기업 이념으로 최고의 </span>
            <span>서비스를 전달하고자 최선을 다하고 있습니다.</span>
          </div>
        </div>
      </SectionMainText>

      {/* 오른쪽 */}
      <SectionContent>
        {/* 첫번째 역사 */}
        <div className="container">
          <div className="main-title">2013-2014</div>
          <div className="main-content">
            <div className="first-content">
              <span className="year-column">2013</span>
              <div className="specific-column">
                <span>(주)대성솔라일렉트로닉스 회사설립</span>
                <span>전기면허 등록</span>
              </div>
            </div>

            <div className="second-content space-item">
              <span className="year-column">2014</span>
              <div className="specific-column">
                <span>신재생에너지 등록업체</span>
                <span>공장등록</span>
                <span>(주)대성솔라일렉트로닉스 DSS250-60P 모듈인증</span>
              </div>
            </div>
          </div>
        </div>

        {/* 두번쨰 역사 */}
        <div className="container">
          <span className="main-title">2015-2016</span>
          <div className="main-content">
            <div className="first-content">
              <span className="year-column">2015</span>
              <div className="specific-column">(주)대성솔라일렉트로닉스 DSS265-96M 모듈인증</div>
            </div>

            <div className="second-content space-item">
              <span className="year-column">2016</span>
              <div className="specific-column">
                <span>ISO인증</span>
              </div>
            </div>
          </div>
        </div>

        {/* 2 2 2 두번쨰 역사 */}
        <div className="container">
          <span className="main-title">2017-2019</span>
          <div className="main-content">
            <div className="first-content">
              <span className="year-column">2017</span>
              <div className="specific-column">
                <span>주식회사 몬스솔라 설립</span>
                <span>전기면허업등록</span>
              </div>
            </div>

            <div className="second-content space-item">
              <span className="year-column">2018</span>
              <div className="specific-column">
                <span>벤처기업인증</span>
                <span>ISO 인증</span>
                <span>나이스평가 우수기업 인증</span>
              </div>
            </div>

            <div className="second-content space-item">
              <span className="year-column">2019</span>
              <div className="specific-column">
                <span>공장등록</span>
              </div>
            </div>
          </div>
        </div>

        {/* 세번쨰 역사 */}
        <div className="last-container">
          <span className="main-title">2020-2021</span>
          <div className="main-content">
            <div className="first-content">
              <span className="year-column">2020</span>
              <div className="specific-column">
                <span>주택/건물 보급사업 참여기업 선정 (20년)</span>
                <span>한국에너지공단 A/S 전담업체 (경북 북부)</span>
                <span>한국에너지공단 표창장 수상</span>
              </div>
            </div>

            <div className="second-content space-item">
              <span className="year-column">2021</span>
              <div className="specific-column">
                <span>주택/건물 보급사업 참여기업 선정 (21년)</span>
                <span>한국에너지공단 A/S 전담업체 (경북 남부)</span>
                <span>KS 인증 취득 (태양광 발전장치)</span>
                <span>포항본사 이전</span>
                <span>조달청 마스등록</span>
              </div>
            </div>
          </div>
        </div>
      </SectionContent>
    </DivWrapper>
  );
};

export default MonsInfoHistory;
