import makeCarousel from "react-reveal/makeCarousel";
import Slide from "react-reveal/Slide";
import styled from "@emotion/styled";

import feature from "../../assets/productpage/inverter/feature.jpg";

import icon01 from "../../assets/productpage/inverter/01_inverterIcon.png";
import icon02 from "../../assets/productpage/inverter/02_inverterIcon.png";
import icon03 from "../../assets/productpage/inverter/03_inverterIcon.png";
import leftArrow from "../../assets/productpage/inverter/leftArrow.png";
import rightArrow from "../../assets/productpage/inverter/rightArrow.png";

import { useLocation } from "react-router-dom";

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 20rem;

  .arrow-left {
    padding-left: 1rem;
    position: absolute;
    bottom: 10%;
    right: 5rem;
    text-align: center;
    cursor: pointer;
    z-index: 99;
  }
  .arrow-right {
    padding-right: 1rem;
    position: absolute;
    bottom: 10%;
    right: 1rem;
    text-align: center;
    cursor: pointer;
    z-index: 99;
  }
`;

const DivImage = styled.div`
  width: 100%;
  height: 20rem;
  position: relative;

  .desktop-img {
    display: none;
  }

  .img-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 765px) {
    .desktop-img {
      display: block;
    }

    .mobile-img {
      display: none;
    }
  }
`;

const SpanImageText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  left: 5%;
  width: 100%;
  height: 100%;

  div {
    margin-bottom: 41px;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;

    .content {
      padding-left: 0rem;
    }
  }

  .feature-summary {
    font-size: 1.14rem;
    margin-bottom: 19px;
    color: #ffffff;
    line-height: 40px;
    display: flex;
    flex-direction: column;

    .summary-first-child {
      font-size: 1.7rem;
      font-family: "Pretendard-ExtraBold";
      margin-right: 5px;
      color: #ffffff;
    }
    .summary-second-child {
      font-size: 1rem;
      font-family: "Pretendard-Medium";
      color: #ffffff;
    }
  }

  .feature-explain {
    font-size: 1.1rem;
    font-family: "Pretendard-ExtraBold";
    color: #ffffff;
    line-height: 30px;
  }
`;

const OpenIcon = styled.img`
  margin-right: 20px;
  width: 55px;
  height: 60px;
`;

// 공통적으로 적용 될 요소들을 여기서 뿌린다
const CarouselUI = ({ position, handleClick, children }) => (
  <Container>
    <div>{children}</div>
    <div className="arrow-left" onClick={handleClick} data-position={position - 1}>
      <span>
        <img style={{ width: 50, height: 50 }} src={leftArrow}></img>
      </span>
    </div>
    <div className="arrow-right" right onClick={handleClick} data-position={position + 1}>
      <span>
        <img style={{ width: 50, height: 50 }} src={rightArrow}></img>
      </span>
    </div>
    <div
      src={feature}
      style={{
        background: `url(${feature}) no-repeat center`,
        backgroundSize: "cover",
        width: "100%",
        height: "100%",
      }}
    />
  </Container>
);
const Carousel = makeCarousel(CarouselUI);

const ImageCarouselTwo = () => {
  const { search } = useLocation();

  return (
    <Carousel>
      <Slide right>
        <div>
          <DivImage>
            <SpanImageText>
              <div>
                <span className="feature-summary">
                  <span className="summary-first-child">Inverter</span>
                  <span className="summary-second-child">인버터 제품특징</span>
                </span>
                <div className="content" style={{ display: "flex", flexDirection: "row" }}>
                  <span>
                    <OpenIcon src={icon01} />
                  </span>
                  <span style={{ display: "flex", alignItems: "center" }} className="feature-explain">
                    {search === "?name=kra-krb" && (
                      <>
                        에너지공단 보급사업<br></br>시공기준 만족
                      </>
                    )}
                    {search === "?name=krd" && (
                      <>
                        에너지공단 보급사업<br></br>시공기준 만족
                      </>
                    )}
                    {search === "?name=kra" && (
                      <>
                        인버터 및 접속함 <br></br>KS인증 취득
                      </>
                    )}
                    {search === "?name=50k" && (
                      <>
                        인버터 및 접속함<br></br>KS인증 취득
                      </>
                    )}
                    {search === "?name=500k" && (
                      <>
                        멀티레벨 PWM 소자를 사용해,<br></br>최고의 변환효율 제공
                      </>
                    )}
                  </span>
                </div>
              </div>
            </SpanImageText>
          </DivImage>
        </div>
      </Slide>
      <Slide right>
        <div>
          <DivImage>
            <SpanImageText>
              <div>
                <span className="feature-summary">
                  <span className="summary-first-child">Inverter</span>
                  <span className="summary-second-child">인버터 제품특징</span>
                </span>
                <div className="content" style={{ display: "flex", flexDirection: "row" }}>
                  <span>
                    <OpenIcon src={icon02} />
                  </span>
                  <span style={{ display: "flex", alignItems: "center" }} className="feature-explain">
                    {search === "?name=kra-krb" && (
                      <>
                        인버터 및 접속함<br></br>KS인증 취득
                      </>
                    )}
                    {search === "?name=krd" && (
                      <>
                        인버터 및 접속함<br></br>KS인증 취득
                      </>
                    )}
                    {search === "?name=kra" && (
                      <>
                        한글 LCD 적용으로 누구나<br></br>인버터 상태 확인 가능
                      </>
                    )}
                    {search === "?name=50k" && (
                      <>
                        한글 LCD 적용으로 누구나<br></br>인버터 상태 확인 가능
                      </>
                    )}
                    {search === "?name=500k" && (
                      <>
                        PULL DIGITAL 제어시스템에<br></br>의한 편리성 및 정확성
                      </>
                    )}
                  </span>
                </div>
              </div>
            </SpanImageText>
          </DivImage>
        </div>
      </Slide>
      <Slide right>
        <div>
          <DivImage>
            <SpanImageText>
              <div>
                <span className="feature-summary">
                  <span className="summary-first-child">Inverter</span>
                  <span className="summary-second-child">인버터 제품특징</span>
                </span>
                <div className="content" style={{ display: "flex", flexDirection: "row" }}>
                  <span>
                    <OpenIcon src={icon03} />
                  </span>
                  <span style={{ display: "flex", alignItems: "center" }} className="feature-explain">
                    {search === "?name=kra-krb" && (
                      <>
                        접속함 내장 3.5kW<br></br>태양광 인버터
                      </>
                    )}
                    {search === "?name=krd" && (
                      <>
                        지락 차단장치 내장<br></br>인버터
                      </>
                    )}
                    {search === "?name=kra" && (
                      <>
                        지락 차단장치 내장<br></br>인버터
                      </>
                    )}
                    {search === "?name=50k" && (
                      <>
                        에너지공단 보급사업<br></br>시공기준 만족
                      </>
                    )}
                    {search === "?name=500k" && (
                      <>
                        3Level 방식의 입력구성으로<br></br>발전효율 극대화
                      </>
                    )}
                  </span>
                </div>
              </div>
            </SpanImageText>
          </DivImage>
        </div>
      </Slide>
    </Carousel>
  );
};

export default ImageCarouselTwo;
