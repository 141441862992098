import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import ScrollTrigger from "react-scroll-trigger";
import * as Ani from "./animation";

const DivWrapper = styled.div`
  max-height: 350px;
  display: flex;
  justify-content: center;

  .image-container {
    width: 43vw;
    height: 11rem;
    overflow: hidden;
  }

  .ani-container {
    overflow: hidden;

    animation-duration: 0.8s;
    animation-name: ${Ani.containerTranslate};
    -moz-animation-duration: 0.8s;
    -moz-animation-name: ${Ani.containerTranslate};
    -webkit-animation-duration: 0.8s;
    -webkit-animation-name: ${Ani.containerTranslate};
    -o-animation-duration: 0.8s;
    -o-animation-name: ${Ani.containerTranslate};

    .real-image {
      width: 100%;
      height: 100%;
      animation-duration: 1s;
      animation-name: ${Ani.imageTranslate};
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      -moz-animation-duration: 1s;
      -moz-animation-name: ${Ani.imageTranslate};
      -moz-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      -webkit-animation-duration: 1s;
      -webkit-animation-name: ${Ani.imageTranslate};
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      -o-animation-duration: 1s;
      -o-animation-name: ${Ani.imageTranslate};
      -o-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    line-height: 20px;

    .text-first-child {
      font-size: 1rem;
      font-family: "Pretendard-Medium";
      margin-top: 18px;
      margin-bottom: 7.7px;
      color: #666666;
    }
    .text-second-child {
      font-size: 1.2rem;
      font-family: "Pretendard-Bold";
      color: #222222;
    }
  }

  @media (min-width: 420px) {
    .ani-container {
      height: 14rem;
    }
  }

  @media (min-width: 530px) {
    .ani-container {
      height: 17rem;
    }
  }

  @media (min-width: 965px) {
    transform: ${(props) => (props.triangleItem ? "translate3d(0px, -30px, 0)" : "")};
    -moz-transform: ${(props) => (props.triangleItem ? "translate3d(0px, -30px, 0)" : "")};
    -o-transform: ${(props) => (props.triangleItem ? "translate3d(0px, -30px, 0)" : "")};
    -webkit-transform: ${(props) => (props.triangleItem ? "translate3d(0px, -30px, 0)" : "")};

    .image-container {
      max-width: 275px;
    }

    .ani-container {
      .real-image {
        width: 100%;
        height: 16rem;
      }
    }
  }
`;

const PostsContainer = ({ post, index }) => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const onTriggerAnimate = useCallback(() => {
    if (!triggerAnimation) setTriggerAnimation(true);
  }, [triggerAnimation]);

  return (
    <ScrollTrigger onEnter={onTriggerAnimate}>
      <DivWrapper triangleItem={index % 3 === 1}>
        <Link
          to={{
            pathname: `/project/${post.id}`,
            state: {
              attachmentURL: post.attachmentURL,
              category: post.category,
              createdAt: post.createdAt,
              id: post.id,
              main_content: post.main_content,
              // sub_content: post.sub_content,
              table_dueDate: post.table_dueDate,
              table_place: post.table_place,
              table_title: post.table_title,
              title: post.title,
            },
          }}
        >
          <div className={triggerAnimation ? "image-container ani-container" : "image-container"}>
            <div
              style={{
                background: `url(${post.attachmentURL}) no-repeat center`,
                backgroundSize: "cover",
              }}
              className="real-image"
            />
          </div>
          <div className={triggerAnimation ? "text-container ani-container" : "text-container"}>
            <span className="text-first-child">[{post.category}]</span>
            <span className="text-second-child">{post.title}</span>
          </div>
        </Link>
      </DivWrapper>
    </ScrollTrigger>
  );
};

export default PostsContainer;
