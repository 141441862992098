import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subOfSubHeaderBoolean: false,
};

export const subOfSubHeaderSlice = createSlice({
  name: "subOfSubHeader",
  initialState,
  reducers: {
    saveSubOfSubHeaderBoolean(state, action) {
      state.subOfSubHeaderBoolean = action.payload;
    },
  },
});
