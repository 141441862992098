import React from "react";
import styled from "@emotion/styled";

import specialImage from "../../assets/home_component/gyeongbuk_andong.jpg";
import basicImage from "../../assets/home_component/basic.jpg";
import frontImage from "../../assets/home_component/gyeongbuk_kimcheon.jpg";
import stiffImage from "../../assets/home_component/gyeongbuk_yeongju.jpg";

const DivWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .main-container {
    width: 100%;

    .main-title {
      font-size: 1.4rem;
      font-family: "Pretendard-ExtraBold";
      margin-top: 35px;
      margin-bottom: 30px;
      padding-left: 1rem;
    }

    .main-contents {
      display: flex;
      flex-direction: column;

      .content {
        margin-bottom: 40px;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(178, 171, 170, 0.9);

        .content-text {
          margin-top: 25px;
          display: flex;
          flex-direction: column;
          line-height: 25px;

          .content-main-title {
            font-size: 1.3rem;
            font-family: "Pretendard-ExtraBold";
            margin-bottom: 6px;
          }

          .content-sub-first-text {
            font-size: 1rem;
            font-family: "Pretendard-Medium";
            margin-top: 13px;
            margin-bottom: 8px;
          }

          .content-feature-first {
            font-size: 0.9rem;
            font-family: "Pretendard-Medium";
            margin-right: 20px;
          }
          .content-feature-second {
            font-size: 0.9rem;
            font-family: "Pretendard-ExtraBold";
            margin-right: 20px;
          }
        }
      }
    }
  }

  .main-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .main-left-content {
      display: flex;
      flex-direction: column;

      .main-left-content-medal {
        display: flex;

        .medal-img {
          margin-right: 5px;
        }
        .medal-text {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  @media (min-width: 840px) {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    .main-container {
      width: 100%;

      .main-title {
        font-size: 1.4rem;
        font-family: "Pretendard-ExtraBold";
        padding-left: 1rem;
      }

      .main-contents {
        width: 100%;
        .content {
          padding-bottom: 40px;
          display: flex;
          width: 98%;
          height: 100%;

          .content-text {
            margin-top: 0px;
            justify-content: center;
            width: 70%;

            div {
              line-height: 20px;
            }

            .content-main-title {
              font-size: 1.3rem;
            }

            .content-sub-first-text {
              margin-bottom: 35px;
              margin-top: 12px;
              color: #747474;
              font-size: 1rem;

              line-height: 23px;
            }
          }
        }
      }
    }
  }
`;

const DivImage = styled.img`
  width: 100%;

  @media (min-width: 500px) {
    /* height: 400px; */
  }

  @media (min-width: 840px) {
    margin-right: 90px;
    width: 25%;
    height: 100%;
  }
`;

const BusinessFeature = () => {
  return (
    <DivWrapper>
      <div className="main-container">
        <div className="main-title">주택용 태양광 설치 형태</div>
        <div className="main-contents">
          {/* 컨텐트 1개씩 duplicate 될거임 */}
          <div className="content">
            <DivImage src={basicImage} alt={"사업특징-주택지원사업-기본"} />
            <div className="content-text">
              <span className="content-main-title">기본형(표준형)</span>
              <span className="content-sub-first-text">
                기본형은 슬리브 옥상에 설치하는 방법으로 가장 기본적인 형태입니다. 다리높이 조절이 가능합니다.
              </span>

              <div>
                <span className="content-feature-first">지역</span>
                <span className="content-feature-second">경북 경산시</span>
              </div>
              <div>
                <span className="content-feature-first">용량</span>
                <span className="content-feature-second">3kW</span>
              </div>
            </div>
          </div>

          <div className="content">
            <DivImage src={stiffImage} alt={"사업특징-주택지원사업-경사형"} />
            <div className="content-text">
              <span className="content-main-title">경사형(부착형)</span>
              <span className="content-sub-first-text">
                경사 지붕(박공 지붕)에 설치하는 형태로 방위 각도가 범위 내에 들어오면 설치 할 수 있는 형태입니다.
              </span>

              <div>
                <span className="content-feature-first">지역</span>
                <span className="content-feature-second">경북 영주시</span>
              </div>
              <div>
                <span className="content-feature-first">용량</span>
                <span className="content-feature-second">3kW</span>
              </div>
            </div>
          </div>

          <div className="content">
            <DivImage src={frontImage} alt={"사업특징-주택지원사업"} />
            <div className="content-text">
              <span className="content-main-title">지상형</span>
              <span className="content-sub-first-text">
                시멘트 바닥이 아닌 마당에 설치할 경우 기초 작업(시멘트)이 필요하며, 다른 형태보다 기간이 소요됩니다.
              </span>

              <div>
                <span className="content-feature-first">지역</span>
                <span className="content-feature-second">경북 김천시</span>
              </div>
              <div>
                <span className="content-feature-first">용량</span>
                <span className="content-feature-second">3kW</span>
              </div>
            </div>
          </div>

          <div className="content">
            <DivImage src={specialImage} alt={"사업특징-주택지원사업-특수형"} />
            <div className="content-text">
              <span className="content-main-title">특수형</span>
              <span className="content-sub-first-text">
                지붕 역경사, 자리 협소 등의 이유로 구조물을 별도로 특수 제작해 설치하는 방법으로 구조물 설계 및 제작을
                위해 추가 준비 시간이 소요됩니다.
              </span>
              <div>
                <span className="content-feature-first">지역</span>
                <span className="content-feature-second">경남 안동시</span>
              </div>
              <div>
                <span className="content-feature-first">용량</span>
                <span className="content-feature-second">3kW</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DivWrapper>
  );
};

export default BusinessFeature;
