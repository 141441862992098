import React from "react";
import makeCarousel from "react-reveal/makeCarousel";
import Fade from "react-reveal/Fade";

import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

import mainBanner01 from "../../assets/mainpage/modified_main_banner01.jpg";
import mainBanner02 from "../../assets/mainpage/modified_main_banner02.jpg";
import mainBanner03 from "../../assets/mainpage/modified_main_banner03.jpg";
import mainBanner04 from "../../assets/mainpage/modified_main_banner04.jpg";

import emptyRectangle from "../../assets/mainpage/emptyRectangle.png";
import fullRectangle from "../../assets/mainpage/fullRectangle.png";

const moveToLeft = keyframes`
   from {
        transform: translate3d(0px,0px,0px)
    }
    to {
        transform: translate3d(-30px,0px,0px)
    }
`;

const Container = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;

  .dots-wrapper {
    margin-left: 97%;
    display: flex;
    align-items: center;
    height: 100%;
  }

  @media (max-width: 500px) {
    .dots-wrapper {
      display: none;
    }
  }
`;

const Dots = styled.span`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 9896785856767858989;
`;

const DivImage = styled.div`
  overflow: hidden;
  filter: brightness(80%);

  width: 113%;
  height: 100vh; // 모바일과 데스킅보에서 height를 조정할 땐 이 수치를 조정할 것

  animation-duration: 7.5s; // 캐러셀의 defaultWait 보다 길게 잡았음.
  animation-name: ${moveToLeft};
`;

const DivTabletImage = styled.img`
  display: none;

  @media (min-width: 500px) {
    display: block;
    filter: brightness(80%);
    width: 105%;
    height: 100vh;
    animation-duration: 7.5s; // 캐러셀의 defaultWait 보다 길게 잡았음.
    animation-name: ${moveToLeft};
  }

  @media (min-width: 1065px) {
    display: none;
  }
`;

const DivMobilImage = styled.img`
  width: 115%;
  height: 100vh; // 모바일과 데스킅보에서 height를 조정할 땐 이 수치를 조정할 것
  animation-duration: 7.5s; // 캐러셀의 defaultWait 보다 길게 잡았음.
  animation-name: ${moveToLeft};

  @media (min-width: 500px) {
    display: none;
  }
`;

const Dot = styled.span`
  font-size: 1.6em;
  cursor: pointer;
  text-shadow: 1px 1px 1px #fff;
  user-select: none;
  color: #ffffff;
`;

const RectangleWrapper = styled.img`
  width: 5px;
  height: 20px;
`;

// react reveal 컴포넌트를 만드는 로직
const CarouselUI = ({ position, total, handleClick, children }) => (
  <Container>
    {children}

    <div className="dots-wrapper">
      <Dots>
        {Array(...Array(total)).map((val, index) => (
          <Dot key={index} onClick={handleClick} data-position={index}>
            {index === position ? <RectangleWrapper src={fullRectangle} alt={'몬스솔라 도트'} /> : <RectangleWrapper src={emptyRectangle} alt={'몬스솔라 도트'}/>}
          </Dot>
        ))}
      </Dots>
    </div>
  </Container>
);
const Carousel = makeCarousel(CarouselUI);

const MainBannerCarousel = () => {
  return (
    <Carousel maxTurns={100000000} swipe={false} defaultWait={6500}>
      <Fade>
        <DivImage style={{ background: `url(${mainBanner04}) no-repeat center`, backgroundSize: "cover" }} />
      </Fade>
      <Fade>
        <DivImage style={{ background: `url(${mainBanner03}) no-repeat center`, backgroundSize: "cover" }} />
      </Fade>
      <Fade>
        <DivImage style={{ background: `url(${mainBanner02}) no-repeat center`, backgroundSize: "cover" }} />
      </Fade>
      <Fade>
        <DivImage style={{ background: `url(${mainBanner01}) no-repeat center`, backgroundSize: "cover" }} />
      </Fade>
    </Carousel>
  );
};

export default MainBannerCarousel;
