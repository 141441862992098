import { combineReducers } from "redux";
import { userSlice } from "./user";
import { mobileMediaQuerySlice } from "./mobileMediaQuery";
import { subOfSubHeaderSlice } from "./subOfSubHeader";
import { headerHeightSlice } from "./headerHeight";
import { offChannelTalkSlice } from "./offChannelTalk";

export default combineReducers({
  user: userSlice.reducer,
  mobileMediaQuery: mobileMediaQuerySlice.reducer,
  subOfSubHeader: subOfSubHeaderSlice.reducer,
  headerHeight: headerHeightSlice.reducer,
  offChannelTalk: offChannelTalkSlice.reducer,
});
