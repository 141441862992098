import React, { useState } from "react";
import styled from "@emotion/styled";
import arrow from "../../assets/blogpage/arrow.png";
import { useEffect } from "react";

const DivContainerStyled = styled.div`
  margin-top: 18px;
  display: flex;
  cursor: pointer;

  @media (min-width: 765px) {
    margin-top: 30px;
  }
`;
const SectionImageContainerStyled = styled.section`
  margin-right: 13.5px;

  .img {
    width: 88px;
    height: 88px;
  }

  @media (min-width: 765px) {
    .img {
      margin-right: 30px;
      width: 250px;
      height: 250px;
    }
  }
`;
const SectionTextContainerStyled = styled.section`
  width: 100%; // 모바일엔 없었는데, 데스크톱에서 넣었다. 근데 이걸 넣어도 모바일에선 크게 변화가 없어서, 공통으로 뽑아둠
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 15px;

  .only-dt-show-detail-content {
    display: none;
  }

  .desktop {
    display: none;
  }

  .first-child {
    font-size: 1.1rem;
    font-family: "Pretendard-ExtraBold";
    line-height: 22px;
    color: #000000;
  }

  .second-child {
    font-size: 0.85rem;
    font-family: "Pretendard-Light";

    color: #707070;
  }

  @media (min-width: 765px) {
    flex-direction: row;
    padding-bottom: 0px;

    .desktop {
      display: block;
    }

    .dt-text {
      margin-right: auto;
      width: 82%;
      height: 80%;
      display: flex;
      flex-direction: column;
      margin-top: auto;
      margin-bottom: auto;
      justify-content: space-around;
    }

    .dt-icon {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .only-dt-show-detail-content {
      font-size: 1.05rem;
      font-family: "Pretendard-Medium";
      display: block;
      line-height: 30px;
      color: #747474;
    }

    .first-child {
      font-size: 1.6rem;
      line-height: 30px;
      font-family: "Pretendard-ExtraBold";
    }

    .second-child {
      font-size: 0.8rem;
      font-family: "Pretendard-Light";
    }

    .mobile {
      display: none;
    }
  }
`;

const CrawlsListPresenter = ({ post }) => {
  const { title, image_url, detailContent, idNumber, publishDate, createdAt } = post;

  let dateObj = { renderBoolean: false };
  if (publishDate.indexOf("시간") !== -1) {
    let dt = new Date(createdAt);
    dateObj.date = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
    dateObj.renderBoolean = true;
  }
console.log('BLOG;', post);
  return (
    <DivContainerStyled onClick={() => window.open(`https://blog.naver.com/monssolar/${idNumber}`, "_blank")}>
      {/* 이미지 공간 */}
      <SectionImageContainerStyled className="image-container">
        <img className="img" src={image_url} alt={`${post.createdAt}몬스솔라 블로그 대표 사진`} />
      </SectionImageContainerStyled>

      {/* 텍스트 공간 */}
      <SectionTextContainerStyled className="text-container">
        {/* 모바일 사이즈일 때만 */}
        <span className="first-child mobile">{title}</span>
        <span className="second-child mobile">{dateObj.renderBoolean ? dateObj.date : publishDate}</span>

        {/* 데스크톱 사이즈일 때만 */}
        <div className="desktop dt-text">
          <span className="first-child">{title}</span>
          <span className="only-dt-show-detail-content">{detailContent.slice(0, 120)}...</span>
          <span className="second-child">{dateObj.renderBoolean ? dateObj.date : publishDate}</span>
        </div>
        <div className="desktop dt-icon">
          <img style={{ height: 43 }} src={arrow} alt={`${post.createdAt} 몬스솔라 블로그 데스크톱 사진`}/>
        </div>
      </SectionTextContainerStyled>
    </DivContainerStyled>
  );
};

export default CrawlsListPresenter;
