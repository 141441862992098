import React, { useState, useCallback } from "react";

import styled from "@emotion/styled";
import * as Ani from "./animation";

import ScrollTrigger from "react-scroll-trigger";
import ViewMoreButton from "../../components/ViewMoreButton/ViewMoreButton";

import BuildingAtHomeImage from "../../assets/mainpage/buildingAtHome.jpg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
    flex-direction: column-reverse;
  }

  .mobile-ani {
    animation-duration: 1.5s;
    animation-name: ${Ani.firstOpacityAndTranslateY};
  }

  .image-container {
    width: 100%;
    /* height: 40vh; */
    object-position: center;
    object-fit: cover;
  }

  .second-business {
    margin-top: 20px;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;

    .first-child {
      font-size: 1.7rem;
      margin-bottom: 20px;
      font-family: "Pretendard-ExtraBold";
    }
    .second-child {
      margin-bottom: 14px;
      font-size: 1.4rem;
    }
    .third-child {
      font-size: 1.1rem;
      color: #505050;
      line-height: 25px;
      margin-bottom: 20px;
      font-family: "Pretendard-Light";
    }
    .fourth-child {
      color: #505050;
      margin-bottom: 14px;
      font-size: 1.1rem;
    }

    // 애니메이션 시작
    .first-ani {
      animation-duration: 1.5s;
      animation-name: ${Ani.firstOpacityAndTranslateY};
    }
    .third-ani {
      animation-duration: 1.7s;
      animation-name: ${Ani.thirdOpacityAndTranslateY};
    }
    .fourth-ani {
      animation-duration: 1.7s;
      animation-name: ${Ani.fourthOpacityAndTranslateY};
    }
    .fifth-ani {
      animation-duration: 1.8s;
      animation-name: ${Ani.fifthOpacityAndTranslateY};
    }
  }

  @media (min-width: 865px) {
    height: 350px;

    animation-duration: 3s;
    animation-name: ${Ani.minWidthFirstBuilding};

    .desktop {
      display: block;

      .dt-second-business {
        padding-left: 5.5rem;
        display: flex;
        flex-direction: column;

        .first-child {
          font-size: 1.7rem;
          margin-bottom: 14px;
          font-family: "Pretendard-ExtraBold";
        }
        .second-child {
          margin-bottom: 14px;
          font-size: 1.4rem;
        }
        .third-child {
          font-size: 1.1rem;
          color: #505050;
          margin-bottom: 30px;
          font-family: "Pretendard-Light";
        }
        .fourth-child {
          color: #505050;
          margin-bottom: 14px;
          font-size: 1.1rem;
        }

        // 애니메이션 시작
        .first-ani {
          animation-duration: 1.5s;
          animation-name: ${Ani.firstOpacityAndTranslateY};
        }
        .third-ani {
          animation-duration: 1.7s;
          animation-name: ${Ani.thirdOpacityAndTranslateY};
        }
        .fourth-ani {
          animation-duration: 1.7s;
          animation-name: ${Ani.fourthOpacityAndTranslateY};
        }
        .fifth-ani {
          animation-duration: 1.8s;
          animation-name: ${Ani.fifthOpacityAndTranslateY};
        }
      }

      .dt-i-ani {
        animation-duration: 1.5s;
        animation-name: ${Ani.minWidthFirstBuilding};
      }

      .dt-i {
        position: absolute;

        .dt-image {
          position: absolute;
          bottom: 200px;
          height: 400px;
          z-index: 1;
          object-position: center;
          object-fit: cover;
        }
      }
    }

    .mobile {
      display: none;
    }
  }
`;

const BuildingAtHome = () => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const onTriggerAnimate = useCallback(() => {
    if (!triggerAnimation) setTriggerAnimation(true);
  }, [triggerAnimation]);

  return (
    <ScrollTrigger onEnter={onTriggerAnimate}>
      <Wrapper>
        <div className={triggerAnimation ? "mobile mobile-ani" : "mobile"}>
          <div className="second-business">
            <span className={triggerAnimation ? "first-child first-ani" : "first-child"}>건물지원사업(정부지원)</span>
            <span className={triggerAnimation ? "third-child third-ani" : "third-child"}>
              200kW까지 지원받아 설치하는<br></br>정부지원사업입니다.
            </span>

            <ViewMoreButton pagePath={"business"} componentPath={"building"} />
          </div>

          <div>
            <img
              src={BuildingAtHomeImage}
              className={triggerAnimation ? "image-container image-container-ani" : "image-container"}
              alt={"주택지원사업"}
            />
          </div>
        </div>

        <div className="desktop">
          <div className="dt-second-business">
            <span className={triggerAnimation ? "first-child first-ani" : "first-child"}>건물지원사업(정부지원)</span>
            <span className={triggerAnimation ? "third-child third-ani" : "third-child"}>
              200kW까지 지원받아 설치하는 정부지원사업입니다.
            </span>

            <ViewMoreButton triggerAnimation={triggerAnimation} pagePath={"business"} componentPath={"building"} />
          </div>

          <div className={triggerAnimation ? "dt-i-ani dt-i" : "dt-i"}>
            <img src={BuildingAtHomeImage} className="dt-image" />
          </div>
        </div>
      </Wrapper>
    </ScrollTrigger>
  );
};

export default BuildingAtHome;
