import React, {useCallback, useRef} from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import LinkContainer from "../LinkContainer/LinkContainer";
import { useHistory, useLocation } from "react-router-dom";
import { authService } from "../../fbase";

import whiteLogo from "../../assets/footer/whiteLogo.png";
import naverLogo from "../../assets/footer/naverLogo.png";

const applyMarginTopPixel = ["/business", "/contact", "/monsinfo"];

const FooterWrapper = styled.footer`
  margin-top: ${(props) => (applyMarginTopPixel.indexOf(props.pathname) === -1 ? "" : "100px")};
  padding-bottom: 30px;
  background-color: #242424;

  .wrapper {
    /* border: 1px solid red; */
    max-width: 1100px;
    margin: 0 auto;
  }

  .mons-company-info {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    line-height: 15px;

    .first-child {
      margin-top: 25px;
      margin-bottom: 10px;
    }
    .second-child {
      font-size: 0.9rem;
      font-family: "Pretendard-Bold";
      margin-bottom: 8px;
      color: #b4b4b4;
    }
    .third-child {
      font-size: 0.9rem;
      font-family: "Pretendard-Light";
      margin-bottom: 8px;
      color: #b4b4b4;
      
      .copy-email {
        cursor: pointer;
      }
    }
    .fourth-child {
      font-size: 0.9rem;
      font-family: "Pretendard-Light";
      margin-bottom: 8px;
      color: #b4b4b4;

      .bold-text {
        font-family: "Pretendard-ExtraBold";
      }
    }
    .fifth-child {
      font-size: 0.9rem;
      font-family: "Pretendard-Light";
      color: #b4b4b4;
      .bold-text {
        font-family: "Pretendard-ExtraBold";
      }
    }
  }
  .mons-company-blog-copyright-info {
    margin-top: auto;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;

    .blog-part {
      display: flex;
      margin-top: 15px;
      color: #ffffff;

      a {
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        font-family: "Pretendard-Light";
        margin-left: 9px;
        color: #ffffff;
      }

      .blog-part-text {
        margin-left: 10px;
      }
    }
    .copyright-part {
      margin-top: 15px;
      display: flex;
      font-size: 0.9rem;
      font-family: "Pretendard-Medium";
      color: #858585;

      .log-in-out {
        width: auto;
        @media (min-width: 765px) {
          margin-left: 15px;
        }
      }
    }
  }
  .desktop {
    display: none;
  }

  @media (min-width: 765px) {
    .wrapper {
      display: flex;
      max-width: 1100px;
      justify-content: space-between;
      padding: 0rem;
      /* margin: 0rem; */

      .mons-company-info {
        font-size: 1.1rem;
        font-family: "Pretendard-Medium";

        .desktop {
          display: block;
        }

        .second-child,
        .third-child {
          font-size: 0.9rem;
          margin-bottom: 12px;
        }
        
        .second-child {
          font-size:1.1rem;
        }

        .fourth-child {
          display:flex;
          flex-direction: column;
          margin-bottom: 0px;
          
          .only-dt-margin{
            margin-bottom:10px;
          }
          .bold-text {
            font-weight: bold;
          }
        }

        .mobile {
          display: none;
        }
      }

      .mons-company-blog-copyright-info {
        height: 100%;

        .blog-part {
          margin-top: 0px;
          margin-left: auto;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 5px;
            line-height: 20px;
          }

          .blog-part-text {
            margin-left: 0px;
          }
        }

        .copyright-part {
          margin-top: 62px;
          font-size: 0.9rem;
          font-family: "Pretendard-Medium";

          span {
            .copyright-text-first {
              color: #858585;
            }

            .copyright-text-second {
              text-decoration: blink;
            }
          }
        }
      }
    }
  }
`;

const Footer = () => {
  const refCopyEmail = useRef();
  const history = useHistory();

  const { pathname } = useLocation();

  const { userInfo } = useSelector((state) => state.user);

  const onLogout = useCallback(() => {
    authService.signOut();
    history.push("/");
  }, [history]);

  const onClickRefCopyEmail = useCallback(() => {
    try {
      const valueEmail = refCopyEmail.current.textContent;
      const textArea = document.createElement('textArea');
      document.body.appendChild(textArea); textArea.value = valueEmail;
      textArea.select(); document.execCommand('copy');
      document.body.removeChild(textArea);
    } catch(error){
      console.error(error);
    } finally {
      alert('이메일 주소가 복사되었습니다')
    }
  },[])

  return (
    <FooterWrapper pathname={pathname} productBottom={pathname === "/product" ? true : false}>
      {/* sdfsdfsdf */}
      <div className="wrapper">
        {/* 왼쪽 */}

        <section className="mons-company-info">
          <span className="first-child">
            <img src={whiteLogo} style={{ width: 180, height: 80 }} alt={'몬스솔라 소a'}/>
          </span>
          {/*<span className="second-child">본사 ㅣ 경북 포항시 남구 청림동길59번길 9</span>*/}
          <span className="second-child">대표전화 1800-5403</span>
          {/*<span className="third-child">공장 ㅣ 경남 창원시 마산합포구 진북면 추곡2길 61</span>*/}
          <span className="third-child">이메일: <span className='copy-email' onClick={onClickRefCopyEmail} ref={refCopyEmail}>monssolar@naver.com</span></span>

          {/* 모바일용 */}
          <span className="fourth-child mobile">
            {/*<span className="bold-text">Tel</span> 1800-5403 | <span className="bold-text">Fax</span> 055-715-7713 |*/}
            <span className='only-dt-margin'>본사 : 경북 포항시 남구 동해면 블루밸리동로248번길 8-4 / Tel 054-274-7712 / Fax 055-715-7713</span>
          </span>
          <span className="fifth-child mobile">
            {/*<span className="bold-text">E-mail</span> monssolar@naver.com*/}
            <span>공장 : 경남 창원시 마산합포구 진북면 추곡2길 61 / Tel 055-271-7712 / Fax 055-715-7713</span>
          </span>

          {/* 데스크톱용 */}
          <span className="fourth-child desktop">
            {/*<span className="bold-text">본사 : 경북 포항시 남구 동해면 블루밸리동로248번길 8-4 / Tel</span> 1800-5403 | <span className="bold-text">Fax</span> 055-715-7713 |{" "}*/}
            <span className='only-dt-margin'>본사 : 경북 포항시 남구 동해면 블루밸리동로248번길 8-4 / Tel 054-274-7712 / Fax 055-715-7713</span>
            <span>공장 : 경남 창원시 마산합포구 진북면 추곡2길 61 / Tel 055-271-7712 / Fax 055-715-7713</span>
          </span>
        </section>

        {/* 오른쪽 */}
        <section className="mons-company-blog-copyright-info">
          <div className="blog-part">
            <a target="_blank" href="https://blog.naver.com/monssolar" rel="noreferrer">
              <span>
                <img src={naverLogo} style={{ width: 40, height: 40 }} alt={'몬스솔라 소개'}/>
              </span>
              <span className="blog-part-text">블로그 바로가기</span>
            </a>
          </div>

          <div className="copyright-part">
            <span>
              <span className="copyright-text-first">COPYRIGHT &copy;</span>
              <span className="copyright-text-second">2019 MONSSOLAR ALL Rights Reserved.</span>
            </span>

            {!userInfo ? (
              <LinkContainer pagePath="login">
                <span className="log-in-out">로그인</span>
              </LinkContainer>
            ) : (
              <div className="log-in-out" onClick={onLogout} style={{ cursor: "pointer" }}>
                로그아웃
              </div>
            )}
          </div>
        </section>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
