import makeCarousel from "react-reveal/makeCarousel";
import Slide from "react-reveal/Slide";
import styled from "@emotion/styled";

import leftArrow from "../../assets/businesspage/leftArrow.png";
import rightArrow from "../../assets/businesspage/rightArrow.png";

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 20rem;
  z-index: 989889899;
  .arrow-left {
    padding-left: 1rem;
    position: absolute;
    bottom: 10%;
    right: 4.4rem;
    text-align: center;
    cursor: pointer;
    z-index: 99;

    img {
      width: 40px;
      height: 40px;
    }
  }
  .arrow-right {
    padding-right: 1rem;
    position: absolute;
    bottom: 10%;
    right: 1rem;
    text-align: center;
    cursor: pointer;
    z-index: 99;

    img {
      width: 40px;
      height: 40px;
    }
  }
`;

const DivImage = styled.div`
  z-index: 9898989898989898989898989898989898989898989898989898989;
  width: 100%;
  height: 20rem;
  background-size: cover;
  position: relative;

  .image-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const SpanImageText = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  top: 40%;
  width: 100%;
  height: 3rem;

  div {
    margin-bottom: 30px;
  }

  .feature-summary {
    font-size: 1.14rem;
    font-family: "Pretendard-ExtraBold";
    margin-bottom: 19px;
    color: #ffffff;
    display: flex;
    flex-direction: column;

    .summary-first-child {
      font-size: 1.05rem;
      font-family: "Pretendard-ExtraBold";
      margin-bottom: 13px;
    }
    .summary-second-child {
      font-size: 1.45rem;
      font-family: "Pretendard-ExtraBold";
      margin-bottom: 15px;
      color: #ffffff;
    }
  }

  .feature-explain {
    font-size: 1.1rem;
    font-family: "Pretendard-Light";
    color: #ffffff;
    font-weight: 900;
    line-height: 20px;
  }
`;

const CarouselUI = ({ position, handleClick, children }) => (
  <Container>
    <div>{children}</div>
    <div className="arrow-left" onClick={handleClick} data-position={position - 1}>
      <img src={leftArrow} />
    </div>
    <div className="arrow-right" right onClick={handleClick} data-position={position + 1}>
      <img src={rightArrow} />
    </div>
  </Container>
);
const Carousel = makeCarousel(CarouselUI);

const MobileImageCarousel = () => {
  return (
    <Carousel>
      {/* 1 */}
      <Slide right>
        <div>
          <DivImage>
            <SpanImageText>
              <div>
                <span className="feature-summary">
                  <span className="summary-first-child">Consulting</span>
                  <span className="summary-second-child">최적의 발전시스템 컨설팅</span>
                </span>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span style={{ display: "flex", alignItems: "center" }} className="feature-explain">
                    최적의 지용을 산출함과 동시에 최상의<br></br>수익률을 제공합니다.
                  </span>
                </div>
              </div>
            </SpanImageText>
          </DivImage>
        </div>
      </Slide>

      {/* 2 */}
      <Slide right>
        <DivImage>
          <SpanImageText>
            <div>
              <span className="feature-summary">
                <span className="summary-first-child">Reasonable Price</span>
                <span className="summary-second-child">고품질 시스템과 합리적인 가격</span>
              </span>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span style={{ display: "flex", alignItems: "center" }} className="feature-explain">
                  국내외 최고의 품질을 갖춘 태양광 설비<br></br>시스템과 전기차 충전기를 비롯한 상품<br></br>을 합리적인
                  가격으로 제시합니다.
                </span>
              </div>
            </div>
          </SpanImageText>
        </DivImage>
      </Slide>

      {/* 3 */}
      <Slide right>
        <DivImage>
          <SpanImageText>
            <div>
              <span className="feature-summary">
                <span className="summary-first-child">Construction</span>
                <span className="summary-second-child">태양광 시스템 설계 및 시공</span>
              </span>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span style={{ display: "flex", alignItems: "center" }} className="feature-explain">
                  다년간 축적된 노하우를 바탕으로<br></br>고객의 니즈에 적합한 최적의 태양광<br></br>솔루션을
                  제공합니다.
                </span>
              </div>
            </div>
          </SpanImageText>
        </DivImage>
      </Slide>

      {/* 4 */}
      <Slide right>
        <DivImage>
          <SpanImageText>
            <div>
              <span className="feature-summary">
                <span className="summary-first-child">A/S Center</span>
                <span className="summary-second-child">사후관리 서비스</span>
              </span>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span style={{ display: "flex", alignItems: "center" }} className="feature-explain">
                  한국에너지공단 A/S 전담기업<br></br>2020/2021 연속 선정
                </span>
              </div>
            </div>
          </SpanImageText>
        </DivImage>
      </Slide>
    </Carousel>
  );
};

export default MobileImageCarousel;
