import React from "react";

import styled from "@emotion/styled";

import ViewMoreButton from "../../components/ViewMoreButton/ViewMoreButton";

import modifiedHomeImage from "../../assets/monsinfopage/final_mdf_home.png";
import modifiedMbHomeImage from "../../assets/monsinfopage/f_info_mb_home.jpg";

const MonsWrapper = styled.div`
  overflow-x: hidden;
  margin-top: 61px;

  .desktop-img {
    display: none;
  }

  @media (min-width: 1100px) {
    .desktop-img {
      display: block;
    }
    .mobile-img {
      display: none;
    }
  }

  @media (min-width: 900px) {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .mons-second-business-image {
    width: 100%;
    background-size: cover;

    @media (min-width: 765px) {
      margin-top: 90px;
    }
  }

  .mons-second-business {
    margin-top: 28px;
    display: flex;
    flex-direction: column;

    @media (min-width: 765px) {
      margin-top: 0px;
      width: 100%; // 조금 튀어나오는 느낌을 위해서.
      justify-content: center;
    }

    .only-business-component {
      font-size: 1.07rem;
      font-family: "Pretendard-Light";
      line-height: 25px;
      color: #222222;

      @media (min-width: 765px) {
        font-size: 1.1rem;
        font-family: "Pretendard-Light";
        margin-top: 30px;
        padding-right: 2rem;
        color: #666666;
      }
    }

    .mons-first-child {
      font-size: 1.5rem;
      font-family: "Pretendard-ExtraBold";
      color: #222222;

      @media (min-width: 765px) {
        font-size: 1.7rem;
        margin-top: 60px;
        color: #222222;
        width: 400px;
      }
    }
    .mons-second-child {
      margin-top: 16px;
      font-size: 1.2rem;
      margin-bottom: 28px;
      font-family: "Pretendard-Medium";

      @media (min-width: 765px) {
        font-size: 1.2rem;
        margin-bottom: 47px;

        color: #222222;
      }
    }
  }
`;

const HomeAtMonsInfo = () => {
  return (
    <MonsWrapper>
      {/* 이미지 섹션 */}
      <img className="desktop-img mons-second-business-image" src={modifiedHomeImage} alt={"몬스소개-주택지원사업"} />
      <img className="mobile-img mons-second-business-image" src={modifiedMbHomeImage} />

      {/* 글자 섹션 */}
      <div className="mons-second-business">
        <span className="mons-first-child">주택지원사업(정부지원)</span>
        <span className="mons-second-child">3kW 주택용 태양광 정부지원사업</span>

        <ViewMoreButton pagePath={"business"} componentPath={"home"}></ViewMoreButton>
      </div>
    </MonsWrapper>
  );
};

export default HomeAtMonsInfo;
