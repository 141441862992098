import { keyframes } from "@emotion/react";

const containerTranslate = keyframes`
   0% { 
      transform: translate3d(0px, 40px,0px);
    }
    100% {
      transform: translate3d(0px, 0px,0px);
    }
`;

const imageTranslate = keyframes`
   0% { 
      transform: translate3d(0px, 100%,0px);
    }
    100% {
      transform: translate3d(0px, 0px,0px);
    }
`;

export { imageTranslate, containerTranslate };
