import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    unSaveUserInfo(state, action) {
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addDefaultCase((state, action) => {
      return;
    }),
});
