import React, { Component } from "react";
import Slider from "react-slick";
import styled from "@emotion/styled";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import leftArrow from "../../assets/productpage/inverter/leftArrow.png";
import rightArrow from "../../assets/productpage/inverter/rightArrow.png";
import { Link } from "react-router-dom";

import { keyframes } from "@emotion/react";

const containerTranslate = keyframes`
  from {
    opacity:0;
    transform: translate3d(-230px, 0px, 0px);
  }

  50% {
    opacity:0.7;
    transform: translate3d(10px, 0px, 0px);
  }

  to {
    opacity:1;
    transform: translate3d(0px, 0px, 0px);
  }
`;

const imageTranslate = keyframes`
  from {
    opacity:0;
    transform: translate3d(230px, 0px, 0px);
  }

  50% {
    opacity:0.7;
    transform: translate3d(-10px, 0px, 0px);
  }

  to {
    opacity:1;
    transform: translate3d(0px, 0px, 0px);
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  .back-scene {
    padding: 0rem;
    position: absolute;
    background-color: #0e2944;
    width: 100%;
    height: 400px;
  }

  @media (min-width: 300px) {
    .back-scene {
      height: 450px;
    }
  }

  @media (min-width: 1200px) {
    .ani-left {
      animation-name: ${containerTranslate};
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
    }

    .ani-image {
      animation-name: ${imageTranslate};
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
    }

    display: flex;
    justify-content: center;

    .back-scene {
      display: flex;
      position: absolute;
      top: 0px;
      left: -25%;
      width: 85%;
      height: 90%;
      z-index: -1;
    }
  }
`;

const SliderTextContainer = styled(Slider)`
  position: relative;
  width: 100%;
  height: 200px;
  padding-left: 2rem;

  .main-content {
    .third-child {
      color: #ffffff;
      font-family: "Pretendard-Light";
    }
  }

  @media (min-width: 1200px) {
    position: static;
    width: 80%;
    max-width: 1100px;

    .main-content {
      padding-top: 4rem;

      .first-child {
        font-size: 1.1rem;
        font-family: "Pretendard-Medium";
        margin-bottom: 12px;
        color: #ffffff;
      }

      .second-child {
        font-size: 2rem;
        font-family: "Pretendard-Medium";
        margin-bottom: 34px;
        color: #ffffff;
      }
      .third-child {
        width: 45%;
        line-height: 25px;
      }
    }
  }
`;

// 이미지 간의 너비를 정하려면 여기서 조정할 것
const SliderContainer = styled(Slider)`
  margin-top: 30px;
  margin-left: 2rem;
  position: absolute;
  bottom: -90px;
  width: 250%;
  height: 70%;
  overflow: hidden;

  @media (min-width: 500px) {
    bottom: -65px;
  }

  @media (min-width: 650px) {
    bottom: -60px;
  }

  @media (min-width: 900px) {
    bottom: -60px;
    width: 170%;
  }

  @media (min-width: 1000px) {
    bottom: -60px;
    width: 180%;
  }

  @media (min-width: 1200px) {
    margin-top: 60px;
    bottom: -40px;
    height: 100%;
    width: 140%;
    right: -90%;
  }

  @media (min-width: 1300px) {
    width: 135%;
    right: -85%;
  }

  @media (min-width: 1500px) {
    width: 100%;
    right: -50%;
  }

  @media (min-width: 1700px) {
    width: 90%;
    right: -40%;
  }

  /* 여기서부턴 건들지 말 것. */
  @media (min-width: 2000px) {
    width: 80%;
    right: -30%;
  }

  @media (min-width: 3200px) {
    width: 80%;
    right: -30%;
  }
`;

// 개별 이미지의 크기를 정하려면 여기서 조정할 것
const Divimage = styled.img`
  width: 90%;
  object-fit: cover;
  object-position: center;
  min-height: 250px;

  @media (min-width: 300px) {
    width: 90%;
    height: 300px;
  }

  @media (min-width: 400px) {
    width: 90%;
    height: 350px;
  }

  @media (min-width: 500px) {
    width: 90%;
  }

  @media (min-width: 600px) {
    height: 400px;
  }

  @media (min-width: 650px) {
    height: 450px;
  }

  @media (min-width: 600px) {
    width: 90%;
  }

  @media (min-width: 800px) {
    width: 80%;
  }

  @media (min-width: 900px) {
    width: 90%;
  }

  @media (min-width: 1100px) {
  }

  @media (min-width: 1200px) {
    width: 90%;
  }

  @media (min-width: 1000px) {
    width: 80%;
  }

  @media (min-width: 1200px) {
  }

  @media (min-width: 1300px) {
  }

  @media (min-width: 1400px) {
  }

  @media (min-width: 1500px) {
  }

  @media (min-width: 1600px) {
  }

  @media (min-width: 1700px) {
  }
`;

const OpenIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: 1.6rem;
  font-family: "Pretendard-Medium";
  padding: 0.5rem;
  border: 1px solid #ffffff;
`;

const PrevArrow = styled.div`
  width: 40px;
  z-index: 98;
`;

const NextArrow = styled.div`
  padding-left: 2.4rem;

  width: 40px;
  z-index: 98;

  @media (min-width: 1200px) {
    .ani-n-a {
      animation-name: ${containerTranslate};
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
    }
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  top: 200px;
  cursor: pointer;

  @media (min-width: 1200px) {
    position: absolute;
    top: 350px;

    .ani-arrow {
      animation-name: ${containerTranslate};
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
    }
  }
`;

function SamplNextArrow(props) {
  const { onClick, triggerAnimation } = props;

  return (
    <ArrowWrapper>
      <NextArrow className={triggerAnimation ? "ani-arrow" : ""} onClick={onClick}>
        <img style={{ width: 40, height: 40 }} src={rightArrow}></img>
      </NextArrow>
    </ArrowWrapper>
  );
}

function SamplePrevArrow(props) {
  const { onClick, triggerAnimation } = props;

  return (
    <ArrowWrapper>
      <PrevArrow className={triggerAnimation ? "ani-arrow" : ""} onClick={onClick}>
        <img style={{ width: 40, height: 40 }} src={leftArrow}></img>
      </PrevArrow>
    </ArrowWrapper>
  );
}
export default class CarouselsConnectsNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState((state) => {
      return {
        nav1: this.slider1,
        nav2: this.slider2,
      };
    });
  }

  render() {
    const { posts } = this.props;

    return (
      <Wrapper>
        <div className={this.props.triggerAnimation ? "back-scene ani-left" : "back-scene"} />
        <SliderTextContainer
          dots={false}
          className="text-container"
          autoplay={true}
          nextArrow={<SamplNextArrow className="next-arrow" triggerAnimation={this.props.triggerAnimation} />}
          prevArrow={<SamplePrevArrow className="prev-arrow" triggerAnimation={this.props.triggerAnimation} />}
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
        >
          {posts.map((post, index) => {
            const { category, main_content, title } = post;

            return (
              <div className={index === 0 && this.props.triggerAnimation ? "ani-left" : ""}>
                <div className="main-content">
                  <span className="first-child">{category}</span>
                  <span className="second-child">{title}</span>
                  <span className="third-child">{main_content}</span>
                </div>
              </div>
            );
          })}
        </SliderTextContainer>

        <SliderContainer
          dots={false}
          className={this.props.triggerAnimation ? "ani-image" : "images-wrapper"}
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
          slidesToShow={3}
          swipeToSlide={true}
        >
          {posts.map((post, index) => {
            return (
              <Link
                to={{
                  pathname: `/project/${post.id}`,
                  state: {
                    attachmentURL: post.attachmentURL,
                    category: post.category,
                    createdAt: post.createdAt,
                    id: post.id,
                    main_content: post.main_content,
                    sub_content: post.sub_content,
                    table_dueDate: post.table_dueDate,
                    table_place: post.table_place,
                    table_title: post.table_title,
                    title: post.title,
                  },
                }}
              >
                <div className={index === 0 ? "image-container" : ""} key={index + 1}>
                  <div className={index === 0 ? "image-content" : ""}>
                    <Divimage src={post.attachmentURL} />
                  </div>
                </div>
              </Link>
            );
          })}
        </SliderContainer>
      </Wrapper>
    );
  }
}
