import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mobileMediaQueryBoolean: true,
};

export const mobileMediaQuerySlice = createSlice({
  name: "mobileMediaQuery",
  initialState,
  reducers: {
    saveMobileMediaQueryBoolean(state, action) {
      state.mobileMediaQueryBoolean = action.payload;
    },
  },
});
