import React from "react";

import BusinessProcess from "./Business/BusinessProcess";
import BusinessAreaText from "./MonsInfo/BusinessAreaText";
import MonsInfoCertification from "./MonsInfo/MonsInfoCertification";
import MonsInfoHistory from "./MonsInfo/MonsInfoHistory";
import BusinessExample from "./Business/BusinessExample";
import BusinessFeature from "./Business/BusinessFeature";

const ChangeableBottomText = ({ innerText, query }) => {
  // business
  if (innerText === "사업절차") {
    return <BusinessProcess query={query} />;
  }
  if (innerText === "시공사례") {
    return <BusinessExample query={query} />;
  }
  if (innerText === "사업특징") {
    return <BusinessFeature />;
  }
  // MonsInfo
  if (innerText === "사업분야") {
    return <BusinessAreaText />;
  }
  if (innerText === "연혁") {
    return <MonsInfoHistory />;
  }
  if (innerText === "인증서") {
    return <MonsInfoCertification />;
  }
};

export default ChangeableBottomText;
