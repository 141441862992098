import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "@emotion/styled";

const Container = styled.div`
  width: 100%;
  display: ${(props) => (props.businessExampleLayout ? "flex" : "")};
  z-index: 9;

  .view-more {
    margin-left: auto;
    margin-right: auto;
    font-size: 1.1rem;
    font-family: "Pretendard-Medium";
    z-index: 989889898;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    width: ${(props) => (props.productLayout ? "8rem" : props.businessExampleLayout ? "90%" : "8.3rem")};
    height: ${(props) => (props.productLayout ? "2rem" : "2.5rem")};

    color: ${(props) => (props.borderAndFontColor === "white" ? "#ffffff" : "black")}; // 글 색깔
    border: ${(props) => (props.borderAndFontColor === "white" ? "1px solid #ffffff" : "1px solid black")};

    border-radius: 5px;
    overflow: hidden;

    .btn-child {
      font-size: 0.85rem;
      font-family: "Pretendard-Medium";
      position: relative;
      z-index: 989898989898;
    }
  }

  .view-more::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: var(--y);
    left: var(--x);
    width: 0;
    height: 0;
    background-color: black; // 배경
    border-radius: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transition: width 0.5s, height 0.5s;
    color: red;
  }
  .view-more:hover::before {
    width: ${(props) => (props.businessExampleLayout ? "900px" : "300px")};
    height: ${(props) => (props.businessExampleLayout ? "900px" : "300px")};
    z-index: -1;
  }

  .color-hover {
    color: ${(props) => (props.borderAndFontColor === "white" ? "#ffffff" : "black")}; // 글 색깔
  }
  .color-hover:hover {
    color: #ffffff;
    transition-delay: 0.1s; // 살짝 딜레이 주기 -> 왜냐하면, 검은 원이 퍼지는 찰나에 color 가 바로 바뀌어 버리면, 순간적으로 텍스트가 사라지는 ui가 나옴
  }

  @media (min-width: 765px) {
    .view-more {
      border-radius: 0px;
      width: ${(props) => (props.productLayout ? "8rem" : props.businessExampleLayout ? "100%" : "8.3rem")};
    }
  }
`;

const ViewMoreButton = ({
  businessExampleLayout,
  productLayout,
  borderAndFontColor,
  pagePath,
  componentPath,
  others,
  triggerAnimation,
}) => {
  const path = !Boolean(componentPath) ? `/${pagePath}` : `/${pagePath}?name=${componentPath}`;
  const btnRef = useRef();
  const [applyColorHover, setApplyColorHover] = useState(false);
  const { pathname } = useLocation();

  // 애니메이션이 작동 중일 때, pseudo code는 작동되지 않음. 그렇기 때문에, hover 했을 시, color: white를 적용하는 것은, 마치 빈 상자를 보게 하는 결과를 낳게 됨
  // 그렇기 때문에 animationDuration을 사용하여, color-hover 라는 클래스네임을 따로 생성하고, 해당 클래스네임이 생성된 후에야 color: white 속성이 적용됨.
  // 즉, 애니메이션 중일 때는 hover 효과가 없고, 애니메이션이 끝난 다음에야 hover 효과가 생김.
  useEffect(() => {
    if (pathname !== "/monsinfo") {
      // 몬스인포에는 애니메이션이 없기 때문에 즉각 color-hover를 적용해야 함.
      setTimeout(() => {
        setApplyColorHover((prev) => true);
      }, 1600);
    } else {
      setApplyColorHover((prev) => true);
    }
  }, [pathname]);

  useEffect(() => {
    btnRef.current.onmousemove = function (e) {
      const x = e.pageX - btnRef.current.offsetLeft;
      const y = e.pageY - btnRef.current.offsetTop;

      btnRef.current.style.setProperty("--x", x + "px");
      btnRef.current.style.setProperty("--y", y + "px");
    };
  }, []);

  return (
    <Container
      businessExampleLayout={businessExampleLayout}
      productLayout={productLayout}
      borderAndFontColor={borderAndFontColor}
      fontSize={others}
    >
      <Link to={path} className={applyColorHover ? "view-more color-hover" : "view-more"} ref={btnRef}>
        <span className="btn-child">VIEW MORE</span>
      </Link>
    </Container>
  );
};

export default ViewMoreButton;
