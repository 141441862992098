import React from "react";
import { Link } from "react-router-dom";

const LinkContainer = ({ children, componentPath, pagePath }) => {
  const path = !Boolean(componentPath) ? `/${pagePath}` : `/${pagePath}?name=${componentPath}`;

  return <Link to={path}>{children}</Link>;
};

export default LinkContainer;
