import styled from "@emotion/styled";

// sun
import dtSunGyengnam1 from "../../assets/sun/pc/gyeongnam.png";
import tbSunGyengnam1 from "../../assets/sun/tablet/gyeongnam.png";
import mbSunGyengnam1 from "../../assets/sun/mobile/gyeongnam.png";

import dtSunUlsan1 from "../../assets/sun/pc/ulsan.png";
import tbSunUlsan1 from "../../assets/sun/tablet/ulsan.png";
import mbSunUlsan1 from "../../assets/sun/mobile/ulsan.png";

import dtSunJeonnam1 from "../../assets/sun/pc/jeonnam.png";
import tbSunJeonnam1 from "../../assets/sun/tablet/jeonnam.png";
import mbSunJeonnam1 from "../../assets/sun/mobile/jeonnam.png";

// building
import dtBuildingSangga from "../../assets/building_component/pc/sangga.png";
import tbBuildingSangga from "../../assets/building_component/tablet/sangga.png";
import mbBuildingSangga from "../../assets/building_component/mobile/sangga.png";

import dtBuildingSookbak from "../../assets/building_component/pc/sookbak.png";
import tbBuildingSookbak from "../../assets/building_component/tablet/sookbak.png";
import mbBuildingSookbak from "../../assets/building_component/mobile/sookbak.png";

import dtBuildingChooksa from "../../assets/building_component/pc/chooksa.png";
import tbBuildingChooksa from "../../assets/building_component/tablet/chooksa.png";
import mbBuildingChooksa from "../../assets/building_component/mobile/chooksa.png";

const DivImage = styled.img`
  display: none;

  @media (min-width: 1100px) {
    display: block;
    width: 100%;
  }
`;

const TabletImage = styled.img`
  display: none;

  @media (min-width: 565px) {
    display: block;
    width: 100%;
  }
  @media (min-width: 1100px) {
    display: none;
  }
`;

const MobileImage = styled.img`
  width: 100%;

  @media (min-width: 565px) {
    display: none;
  }
`;

export const sunDataContainer = [
  <div className="content">
    <DivImage src={dtSunGyengnam1} alt={"사업사례=경남"} />
    <TabletImage src={tbSunGyengnam1} />
    <MobileImage src={mbSunGyengnam1} />
    <div className="content-text">
      <span className="content-main-title">경남 함안 185kW 태양광 발전소</span>
      <span className="content-sub-title">[태양광 발전사업]</span>
    </div>
  </div>,
  <div className="content">
    <DivImage src={dtSunUlsan1} />
    <TabletImage src={tbSunUlsan1} />
    <MobileImage src={mbSunUlsan1} />
    <div className="content-text">
      <span className="content-main-title">울산광역시 64kW 태양광 발전소</span>
      <span className="content-sub-title">[태양광 발전사업]</span>
    </div>
  </div>,
  <div className="content">
    <DivImage src={dtSunJeonnam1} />
    <TabletImage src={tbSunJeonnam1} />
    <MobileImage src={mbSunJeonnam1} />
    <div className="content-text">
      <span className="content-main-title">전남 광양 400kW 태양광 발전소</span>
      <span className="content-sub-title">[태양광 발전사업]</span>
    </div>
  </div>,
];

//
export const buildingDataContainer = [
  <div className="content">
    <DivImage src={dtBuildingSangga} />
    <TabletImage src={tbBuildingSangga} />
    <MobileImage src={mbBuildingSangga} />
    <div className="content-text">
      <span className="content-main-title">9kW 상가 주택 건물지원사업</span>
      <span className="content-sub-title">[건물지원사업]</span>
    </div>
  </div>,
  <div className="content">
    <DivImage src={dtBuildingSookbak} />
    <TabletImage src={tbBuildingSookbak} />
    <MobileImage src={mbBuildingSookbak} />
    <div className="content-text">
      <span className="content-main-title">9kW 숙박 시설 건물지원사업</span>
      <span className="content-sub-title">[건물지원사업]</span>
    </div>
  </div>,
  <div className="content">
    <DivImage src={dtBuildingChooksa} />
    <TabletImage src={tbBuildingChooksa} />
    <MobileImage src={mbBuildingChooksa} />
    <div className="content-text">
      <span className="content-main-title">20kW 축사 건물지원사업</span>
      <span className="content-sub-title">[건물지원사업]</span>
    </div>
  </div>,
];

export const homeDataContainer = [
  <div className="content">
    <span style={{ fontFamily: "Pretendard-Medium" }}>준비 중입니다</span>
  </div>,
];
