import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import ChangeableBottomText from "../ChangeableBottomText";
import { useLocation } from "react-router";

import banner from "../../assets/home_component/banner.jpg";
import tablet_home_banner from "../../assets/businesspage/tablet_home_banner.png";

const SectionTopContainer = styled.section`
  margin-top: 100px;

  overflow: hidden;

  .main-title {
    font-size: 1.7rem;
    font-family: "Pretendard-ExtraBold";
    margin-left: 1rem;
    width: 100%;
    height: 100px;
  }

  .main-bottom-container {
    margin-top: 35px;
    display: flex;
    flex-direction: column;

    .main-bottom-content {
      margin-bottom: 45px;

      display: flex;
      flex-direction: column;

      .content-left {
        font-size: 1.45rem;
        font-family: "Pretendard-ExtraBold";
        margin-right: 50px;
        margin-bottom: 30px;
        padding-left: 1rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        color: #222222;

        span {
          line-height: 30px;
        }
      }

      .content-right {
        font-size: 1.1rem;
        font-family: "Pretendard-Medium";
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
        padding-right: 1rem;

        .mobile-content-right {
          line-height: 25px;
          span {
            padding-left: 0rem;
          }
        }

        .desktop-content-right {
          display: none;
        }

        span {
          padding-left: 0rem;
          line-height: 25px;
          color: #222222;
        }
      }

      .content-bottom-right {
        display: flex;
        flex-direction: column;

        width: calc(100% - 2rem);
        margin-left: auto;
        margin-right: auto;

        .left-part {
          width: 100%;
          border-bottom: 1px solid #c5c5c5;
          padding-bottom: 30px;
        }

        .right-part {
          margin-top: 35px;
          width: 100%;
        }

        .content-bottom-right-left {
          display: flex;
          flex-direction: column;

          .desktop {
            display: none;
          }

          .small-title {
            font-size: 1.22rem;
            font-family: "Pretendard-ExtraBold";
            margin-bottom: 15px;
            color: #000000;
          }

          .small-content {
            font-size: 1.1rem;
            font-family: "Pretendard-Medium";
            line-height: 25px;
            color: #222222;
          }
        }
      }
    }
  }

  @media (min-width: 1100px) {
    margin-top: 207px;
    margin-bottom: 80px;
    max-width: 1900px;
    overflow: visible;

    .dt-wrapper-main-title {
      max-width: 1050px;
      margin-left: auto;
      margin-right: auto;
    }

    .only-dt-image-container {
      overflow: hidden;
    }

    .main-title {
      font-size: 2.1rem;
      font-family: "Pretendard-ExtraBold";
      margin-left: 0rem;
      width: 100%;
    }

    .main-bottom-container {
      margin-left: auto;
      margin-right: auto;
      max-width: 1000px;

      .first-mbc {
        margin-top: 20px;
        margin-bottom: 25px;
      }

      .main-bottom-content {
        flex-direction: row;

        .content-left {
          font-size: 1.6rem;
          font-family: "Pretendard-ExtraBold";
          padding-left: 0rem;
          padding-bottom: 7px;
          margin-right: 0px;
          width: 550px;
        }

        .content-right {
          font-size: 1.1rem;
          font-family: "Pretendard-Medium";
          width: 100%;

          padding-left: 1rem;
          padding-right: 0rem;

          .desktop-content-right {
            display: block;
          }

          .mobile-content-right {
            display: none;
          }

          span {
            line-height: 28px;
          }
        }

        .content-bottom-right {
          font-size: 1.1rem;
          font-family: "Pretendard-Medium";
          margin-top: 50px;
          padding-left: 1rem;
          padding-right: 0rem;
          flex-direction: row;
          width: 100%;

          .left-part {
            padding-bottom: 0px;
            border-bottom: none;
            border-right: 1px solid #c5c5c5;

            .small-title,
            .small-content {
              padding-right: 0rem;
            }
          }

          .right-part {
            width: 100%;
            margin-top: 0px;

            .small-title,
            .small-content {
              padding-left: 2rem;
            }
          }

          .content-bottom-right-left {
            width: 100%;
            padding-left: 0rem;

            .desktop {
              display: block;
            }
            .mobile {
              display: none;
            }

            .small-title {
              font-size: 1.3rem;
              font-family: "Pretendard-ExtraBold";
            }

            .small-content {
              font-size: 1rem;
              font-family: "Pretendard-Medium";
            }
          }
        }
      }
    }
  }
`;

const MobileDivImageContainer = styled.img`
  margin-top: 20px;
  margin-left: 1.3rem;
  /* height: 200px; */
  width: 100%;

  @media (min-width: 500px) {
    display: none;
  }
`;

const TabletDivImageContainer = styled.img`
  display: none;

  @media (min-width: 500px) {
    display: block;
    margin-top: 20px;
    margin-left: 1.3rem;
    /* border: 3px solid red; */
    width: 100%;
  }

  @media (min-width: 800px) {
    display: none;
  }
`;

const DivImageContainer = styled.div`
  display: none;

  @media (min-width: 800px) {
    display: block;
    margin-top: 20px;
    margin-left: 2%; // 얘가 이미지를 오른쪽으로 치우치게 하는 역할이다.
    margin-bottom: 76px;
    height: 340px;
    background: url(${banner}) no-repeat center;
    background-size: cover;
  }

  @media (min-width: 1100px) {
    margin-left: 7%;
    margin-bottom: 76px;
  }
`;

const UlContainer = styled.div`
  width: 100%;
  @media (min-width: 1100px) {
    width: 95%;
    margin: 0 auto;
  }
`;

const Ul = styled.ul`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
  max-width: 100%;
  border-top: 1px solid rgba(178, 171, 170, 0.3);
  border-bottom: 1px solid rgba(178, 171, 170, 0.3);

  .li-list {
    border: none;
    padding: 1rem 0;
    cursor: pointer;
  }

  .list-process {
    font-family: ${(props) => (props.innerText === "사업절차" ? "Pretendard-ExtraBold" : "Pretendard-Light")};
    border-bottom: ${(props) => (props.innerText === "사업절차" ? "2px solid black" : "")};
  }

  .list-example {
    font-family: ${(props) => (props.innerText === "시공사례" ? "Pretendard-ExtraBold" : "Pretendard-Light")};
    border-bottom: ${(props) => (props.innerText === "시공사례" ? "2px solid black" : "")};
  }

  .list-feature {
    font-family: ${(props) => (props.innerText === "사업특징" ? "Pretendard-ExtraBold" : "Pretendard-Medium")};
    border-bottom: ${(props) => (props.innerText === "사업특징" ? "2px solid black" : "")};
  }

  @media (min-width: 1100px) {
    max-width: 1000px;
    justify-content: flex-start;
    border-top: 1px solid rgba(178, 171, 170, 0.9);
    border-bottom: none;

    .li-list {
      padding: 1.5rem 0;
      margin-right: 20px;
    }

    .list-process {
      font-family: ${(props) => (props.innerText === "사업절차" ? "Pretendard-ExtraBold" : "Pretendard-Light")};
      color: ${(props) => (props.innerText === "사업절차" ? "" : "#B2ABAA")};
      border-bottom: none;
    }

    .list-example {
      font-family: ${(props) => (props.innerText === "시공사례" ? "Pretendard-ExtraBold" : "Pretendard-Light")};
      color: ${(props) => (props.innerText === "시공사례" ? "" : "#B2ABAA")};
      border-bottom: none;
    }

    .list-feature {
      font-family: ${(props) => (props.innerText === "사업특징" ? "Pretendard-ExtraBold" : "Pretendard-Light")};
      color: ${(props) => (props.innerText === "사업특징" ? "" : "#B2ABAA")};
      border-bottom: none;
    }
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Building = () => {
  let query = useQuery();
  const [innerText, setInnerText] = useState("사업절차");

  const onCheckLiInnerText = useCallback((e) => {
    setInnerText(e.target.innerText);
  }, []);

  return (
    <div style={{ maxWidth: "1600px", margin: "0 auto", paddingBottom: 50 }}>
      <SectionTopContainer>
        <div className="dt-wrapper-main-title">
          <span className="main-title">주택지원사업</span>
        </div>
        <div className="only-dt-image-container">
          <MobileDivImageContainer src={tablet_home_banner} alt={"몬스솔라 메인"} />
          <TabletDivImageContainer src={tablet_home_banner} />

          <DivImageContainer />
        </div>

        <div className="main-bottom-container">
          <div className="main-bottom-content first-mbc">
            <div className="content-left">
              <span>한국에너지공단 신·재생에너지</span>
              <span>보급(주택지원)사업</span>
            </div>
            <div className="content-right">
              <span>
                주택용 태양광은 정부지원금을 받아 설치할 수 있는 사업으로 '신재생에너지 보급(주택지원)사업'을 통해
                정부지원금과 지자체 지원금을 지원받아 전기를 저렴하게 사용하기 위한 목적으로 태양광을 설치할 수 있는
                보조사업입니다.
              </span>
            </div>
          </div>

          <div className="main-bottom-content">
            <div className="content-left" />

            <div className="content-bottom-right">
              <div className="content-bottom-right-left left-part">
                <span className="small-title">사업대상</span>
                <span className="small-content mobile">단독주택의 소유자 또는 신축주택의 소유예정자</span>
                <span className="small-content desktop">
                  단독주택의 소유자 또는 신축주택의<br></br>소유예정자
                </span>
              </div>
              <div className="content-bottom-right-left right-part">
                <span className="small-title">신청기간</span>
                <span className="small-content mobile">매년 진행하는 사업으로 보조금 소진 시까지 선착순 접수</span>
                <span className="small-content desktop">
                  매년 진행하는 사업으로 보조금<br></br>소진 시까지 선착순 접수
                </span>
              </div>
            </div>
          </div>
        </div>
      </SectionTopContainer>

      {/* 여기부터 사업절차, 시공사례 시작됨 */}
      <section>
        <UlContainer>
          <Ul innerText={innerText}>
            <li className="li-list list-process" onClick={onCheckLiInnerText}>
              사업절차
            </li>
            <li className="li-list list-feature" onClick={onCheckLiInnerText}>
              사업특징
            </li>
          </Ul>
        </UlContainer>
        <ChangeableBottomText query={query.get("name")} innerText={innerText} />
      </section>
    </div>
  );
};
export default Building;
