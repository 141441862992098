import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";

import Modal from "react-modal";

// 사파리에서 애니메이션을 가능하게 + 더 부드럽게 해 주는 로직
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();
window.__forceSmoothScrollPolyfill__ = true;

Modal.setAppElement(document.getElementById("root"));
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<React.StrictMode><Provider store={store}>
    <App />
  </Provider></React.StrictMode>, rootElement);
} else {
  ReactDOM.render(<React.StrictMode><Provider store={store}>
    <App />
  </Provider></React.StrictMode>, rootElement);
}
