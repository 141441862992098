import React from "react";

import styled from "@emotion/styled";

import SunAtMonsInfo from "./SunAtMonsInfo";
import BuildingAtMonsInfo from "./BuildingAtMonsInfo";
import HomeAtMonsInfo from "./HomeAtMonsInfo";

const DivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;

  .desktop {
    display: none;
  }

  @media (min-width: 840px) {
    margin-top: 30px;
    max-width: 1000px;
    padding-left: 0rem;
    padding-right: 0rem;

    .desktop {
      display: flex;
    }

    .mobile {
      display: none;
    }
  }
`;

const SectionMainText = styled.section`
  width: 100%;

  .main-text-container {
    display: flex;
    flex-direction: column;

    .main-text {
      font-size: 1.4rem;
      font-family: "Pretendard-ExtraBold";
      margin-top: 35px;
      margin-bottom: 15px;
    }

    .sub-text {
      font-size: 1rem;
      font-family: "Pretendard-Light";
      color: #222222;
      line-height: 25px;
    }
  }
  @media (min-width: 831px) {
    .main-text-container {
      .main-text {
        font-size: 1.7rem;
        font-family: "Pretendard-ExtraBold";
        margin-right: 240px;
        margin-bottom: 20px;
      }

      .sub-text {
        line-height: 25px;
      }
    }
  }
`;

const DivDesktop = styled.div`
  display: flex;

  .left-align {
    margin-right: 50px;
    width: 95%;
  }

  .right-align {
    margin-top: 35px;
    width: 95%;
  }
`;

const BusinessAreaText = () => {
  return (
    <DivWrapper>
      {/* 왼쪽 */}

      {/* 모바일일 떄 */}
      <div className="mobile">
        {/* 왼쪽 최상단 메인 텍스트 부분 */}
        <SectionMainText>
          <div className="main-text-container">
            <span className="main-text">사업분야</span>
            <span className="sub-text">
              다년간의 노하우를 통해 고객의 니즈에 적합한 최적의 발전시스템 설계는 고객만족을 목표로 하는 주식회사
              몬스솔라의 핵심역량입니다.
            </span>
          </div>
        </SectionMainText>

        {/* 하단부에 있는 모든 썸네일 부분 */}
        <SunAtMonsInfo />
        <BuildingAtMonsInfo />
        <HomeAtMonsInfo />
      </div>

      {/* 데스크톱일 떄 */}
      <DivDesktop className="desktop">
        <div className="left-align">
          {/* 왼쪽 최상단 메인 텍스트 부분 */}
          <SectionMainText>
            <div className="main-text-container">
              <span className="main-text">사업분야</span>
              <span className="sub-text">
                다년간의 노하우를 통해 고객의 니즈에 적합한 최적의 발전시스템 설계는 고객만족을 목표로 하는 주식회사
                몬스솔라의 핵심역량입니다
              </span>
            </div>
          </SectionMainText>

          <HomeAtMonsInfo />
        </div>

        <div className="right-align">
          {/* 하단부에 있는 모든 썸네일 부분 */}
          <SunAtMonsInfo />
          <BuildingAtMonsInfo />
        </div>
      </DivDesktop>
    </DivWrapper>
  );
};

export default BusinessAreaText;
