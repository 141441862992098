import makeCarousel from "react-reveal/makeCarousel";

import styled from "@emotion/styled";

import Fade from "react-reveal/Fade";

import oneImage from "../../assets/productpage/eclair/modified_carousel01.jpg";
import twoImage from "../../assets/productpage/eclair/modified_carousel02.jpg";
import threeImage from "../../assets/productpage/eclair/modified_carousel03.jpg";
import fourImage from "../../assets/productpage/eclair/modified_carousel04.jpg";

import bottomArrowImage from "../../assets/productpage/eclair/bottomArrow.png";
import rightArrowImage from "../../assets/productpage/eclair/rightArrow.png";
import rightLongArrowImage from "../../assets/productpage/eclair/rightLongArrow.png";
import leftLongArrowImage from "../../assets/productpage/eclair/leftLongArrow.png";

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 35rem;

  .arrow-left {
    padding-left: 1rem;
    z-index: 99;
    position: absolute;
    top: 23%; // 이 수치를 통해서 이미지에 있는 사진들의 네비바 위치를 조정
    left: 0%;
    text-align: center;
    cursor: pointer;
  }
  .arrow-right {
    padding-right: 1rem;
    z-index: 99;
    top: 23%; // 이 수치를 통해서 이미지에 있는 사진들의 네비바 위치를 조정
    right: 0%;
    cursor: pointer;
    position: absolute;
  }

  .desktop {
    display: none;
  }

  @media (min-width: 765px) {
    height: 100%;

    .desktop {
      display: block;
    }

    .dt-img-nav-container {
      position: absolute;
      bottom: 21.5%;

      display: flex;

      justify-content: space-between;
      align-items: center;
      z-index: 9999;
      width: 100%;
      height: 60px;

      .nav {
        width: 100%;
        color: #ffffff;

        div {
          height: 50px;
          background-color: rgba(70, 70, 70, 0.3);
        }

        .nav-child {
          display: flex;
          justify-content: space-around;
          align-items: center;
          font-family: "Pretendard-Medium";
          cursor: pointer;
        }

        .apply-bold-0 {
          font-family: ${(props) => (props.position === 0 ? "Pretendard-ExtraBold" : "Pretendard-Light")};
        }
        .apply-bold-1 {
          font-family: ${(props) => (props.position === 1 ? "Pretendard-ExtraBold" : "Pretendard-Light")};
        }
        .apply-bold-2 {
          font-family: ${(props) => (props.position === 2 ? "Pretendard-ExtraBold" : "Pretendard-Light")};
        }
        .apply-bold-3 {
          font-family: ${(props) => (props.position === 3 ? "Pretendard-ExtraBold" : "Pretendard-Light")};
        }
      }
    }

    .mobile {
      display: none;
    }
  }
`;

const DivImage = styled.div`
  width: 100%;
  height: 18rem;
  background-size: cover;
  position: relative;

  .img-container {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .desktop-img {
    display: none;
  }
  .tablet-img {
    display: none;
  }

  @media (min-width: 765px) {
    height: 70vh;

    .tablet-img {
      display: block;
    }

    .mobile-img {
      display: none;
    }

    .mobile {
      display: none;
    }
  }
  @media (min-width: 1100px) {
    .desktop-img {
      display: block;
    }

    .tablet-img {
      display: none;
    }
  }
`;

const SpanImageText = styled.span`
  font-size: 1.1rem;
  font-family: "Pretendard-Medium";
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 3rem;
  color: #ffffff;
  background-color: rgba(70, 70, 70, 0.3);
`;

const DivText = styled.div`
  margin-top: 37px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 1.38rem;
    font-family: "Pretendard-ExtraBold";
    margin-bottom: 17px;
    color: rgba(34, 34, 34, 1);
  }
  .content {
    height: 100px;
    font-size: 1.05rem;
    font-family: "Pretendard-Light";
    color: rgba(34, 34, 34, 1);
    line-height: 25px;

    .content-title {
      font-size: 1.4rem;
      font-family: "Pretendard-ExtraBold";
      margin-bottom: 17px;
      display: flex;
      flex-direction: column;
    }
  }

  @media (min-width: 765px) {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5.5vh;

    .content {
      display: flex;

      div {
        font-size: 1.1rem;
        font-family: "Pretendard-Medium";
      }

      .content-title {
        font-size: 1.7rem;
        font-family: "Pretendard-ExtraBold";
        width: 400px;
        margin-bottom: 0px;
        margin-right: 20px;
      }
    }
  }
`;

const ArrowStyled = styled.img`
  width: 10px;
  height: 10px;
`;

// 공통적으로 적용 될 요소들을 여기서 뿌린다
const CarouselUI = ({ position, total, handleClick, children }) => {
  return (
    <Container position={position}>
      <div>{children}</div>
      {/* 모바일용 */}
      <div className="arrow-left mobile" onClick={handleClick} data-position={position - 1}>
        <span>
          <img src={leftLongArrowImage} style={{ width: 40, height: 40 }} />
        </span>
      </div>
      <div className="arrow-right mobile" right onClick={handleClick} data-position={position + 1}>
        <span>
          <img src={rightLongArrowImage} style={{ width: 40, height: 40 }} />
        </span>
      </div>

      {/* 데스크톱용 */}
      <div className="dt-img-nav-container desktop">
        <div className="dt-one nav" onClick={handleClick} data-position={0}>
          <div className="nav-child apply-bold-0">
            <span>01_ 7kW</span>
            {position === 0 ? <ArrowStyled src={rightArrowImage} /> : <ArrowStyled src={bottomArrowImage} />}
          </div>
        </div>
        <div className="dt-two nav" right onClick={handleClick} data-position={1}>
          <div className="nav-child apply-bold-1">
            <span>02_ LED&Buttonless</span>
            {position === 1 ? <ArrowStyled src={rightArrowImage} /> : <ArrowStyled src={bottomArrowImage} />}
          </div>
        </div>
        <div className="dt-three nav" onClick={handleClick} data-position={2}>
          <div className="nav-child  apply-bold-2">
            <span>03_ Security&Safety</span>
            {position === 2 ? <ArrowStyled src={rightArrowImage} /> : <ArrowStyled src={bottomArrowImage} />}
          </div>
        </div>
        <div className="dt-four nav" right onClick={handleClick} data-position={3}>
          <div className="nav-child  apply-bold-3">
            <span>04_Convenience</span>
            {position === 3 ? <ArrowStyled src={rightArrowImage} /> : <ArrowStyled src={bottomArrowImage} />}
          </div>
        </div>
      </div>
    </Container>
  );
};

const Carousel = makeCarousel(CarouselUI);

const ImageCarousel = () => {
  return (
    <Carousel maxTurns={100000000000} duration={2000}>
      <Fade>
        <DivImage>
          <img className="img-container " src={oneImage} />
          <SpanImageText className="mobile">01_ 7kW</SpanImageText>
        </DivImage>
        <DivText>
          <div className="content">
            <span className="content-title">7kW</span>
            <div>
              이클레어는 국내외 모든 EV차 충전이 가능한 AC 7kW 완속충전기로 3시간 기준 22kW급 충전이 가능합니다. 자동차
              베터리에 낮은 부하를 주어 위험이 적고 내구성이 높으며, 안정적인 충전을 지원합니다. 또한 2020 iF Design
              award 수상을 통해 다른 충전기와는 차별화된 디자인을 제공합니다.
            </div>
          </div>
        </DivText>
      </Fade>
      <Fade>
        <DivImage>
          <img className="img-container " src={twoImage} />
          <SpanImageText className="mobile">02_ LED&Buttonless</SpanImageText>
        </DivImage>
        <DivText>
          <div className="content">
            <span className="content-title">LED& Buttonless</span>
            <div>
              이클레어는 별도의 버튼없이 8개의 LED가 탑재되어 상태에 따라 변하는 LED 색상으로 전기차 사용자가 쉽게
              육안으로 충전상태 및 모든 동작의 상태표시가 가능하고 어떤 기후조건에서도 완벽한 LED 제어를 제공합니다.
              또한 이상발생 정보 를 쉽게 파악할수 있어 문제해결을 할 수 있습니다.
            </div>
          </div>
        </DivText>
      </Fade>
      <Fade>
        <DivImage>
          <img className="img-container " src={threeImage} />
          <SpanImageText className="mobile">03_ Security&Safety</SpanImageText>
        </DivImage>
        <DivText>
          <div className="content">
            <span className="content-title">Security & Safety</span>
            <div>
              이클레어는 RFID Module 탑재로 기본 제품 구매시 기본으로 제공되는 동양이엔피 eclair 전용카드로만 충전이
              가능하여 사용자 외 사용접근을 차단합니다. 또한 국제규격인 IEC와 국내 KC인증이 통과한 향상된 내부시스템
              설계로 별도 누설차단기(ELCB) 시공없이 누설을 감지합니다.
            </div>
          </div>
        </DivText>
      </Fade>
      <Fade>
        <DivImage>
          <img className="img-container " src={fourImage} />
          <SpanImageText className="mobile">04_Convenience</SpanImageText>
        </DivImage>
        <DivText>
          <div className="content">
            <span className="content-title">Convenience</span>
            <div>
              이클레어는 충전기 안쪽공간을 활용해 충전건을 거치함으로써 충전기의 레이어드 공간에 선 정리를 도울수 있고
              또한 충전 케이블 일체형 구조로 고객의 편의성을 반영하여 설계 되었습니다. 또한 AC 캡을 별도로 오픈하지 않고
              외부에서 연결이 가능하여 간단히 시공 할 수 있는 장점을 가지고 있습니다.
            </div>
          </div>
        </DivText>
      </Fade>
    </Carousel>
  );
};

export default ImageCarousel;
