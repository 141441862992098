import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";

import SubMenuUlList from "./SubMenuUlList";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { keyframes } from "@emotion/react";

import NavAtNotHome from "./NavAtNotHome";

import blackHeader from "../../assets/header/mb_black_hamburger.png";
import whiteHeader from "../../assets/header/mb_white_hamburger.png";
import whiteX from "../../assets/header/white_x.png";

const customAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(300px, 0, 0);
  }
`;

const ClosingIcon = styled.div`
  margin-top: 5px;
`;

const OpenSubMenu = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 1rem;
  padding-left: 2rem;
  height: 100vh;
  width: 280px;
z-index:989899889989889898998898989988998899898899898;
  background-color: #171717;
  opacity: 0.98;

  // animation
  animation-name: ${customAnimation};
  animation-duration: 0.7s;
  -moz-animation-name: ${customAnimation};
  -moz-animation-duration: 0.7s;
  -o-animation-name: ${customAnimation};
  -o-animation-duration: 0.7s;
  -webkit-animation-name: ${customAnimation};
  -webkit-animation-duration: 0.7s;

  .close-menu {
    display: flex;
    justify-content: space-between;
    padding-right: 1.4rem;
    margin-bottom: 2em;
  }

  .sub-menu {
    z-index:989899889989889898998898989988998899898899898;
    position: absolute;
    left: 30px;
  }
`;

const DesktopHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .li-list {
    width: 67px;
    font-size: 1.1rem;
    padding: 0.8rem;

    span {
      color: ${(props) => (props.pathname === "/" && props.headerHeight === 0 ? "white" : "#5a5a5a")};
    }
  }

  .only-width {
    width: 50px;
  }

  .li-list {
    .clicked-text {
      padding-bottom: 23px;
      font-family: "Pretendard-ExtraBold";
      border-bottom: 3px solid navy;
      color: navy;
    }
  }
`;

const Wrapper = styled.div`
  color: #ffffff;
  
  .mobile {
    z-index:989899889989889898998898989988998899898899898;
    .black-header {
      display: ${(props) => (props.pathname === "/" && props.headerHeight === 0 ? "block" : "none")};
    }

    .white-header {
      display: ${(props) => (props.pathname === "/" && props.headerHeight === 0 ? "none" : "block")};
    }
  }

  .desktop {
    display: none;
  }

  @media (min-width: 1100px) {
    .desktop {
      display: flex;
      position: relative;
    }

    .mobile {
      display: none;
    }
  }
`;

const SubHeader = () => {
  const { pathname } = useLocation();

  const { headerHeight } = useSelector((state) => state.headerHeight);
  const [triggerMobileHeaderStyles, setTriggerMobileHeaderStyle] = useState(false); // false -> 메인페이지에서 스크롤이 없는 경우, true -> 그 외 나머지 모든 경우
  // 모바일용
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const onMobileMenuOpen = useCallback(() => {
    setMobileMenuOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    if (pathname === "/" && headerHeight === 0) {
      setTriggerMobileHeaderStyle((prev) => false);
    } else {
      setTriggerMobileHeaderStyle((prev) => true);
    }
  }, [headerHeight, pathname]);

  // 아래 로직을 통해서 SubMenuList에 들어가는 onMobileMenuOpen 관련 로직을 전부 제거했음
  // url 주소가 바뀌면 mobileMenuOpen을 무조건 false로 만들어주는 로직임.
  useEffect(() => {
    setMobileMenuOpen(false);
  }, [pathname]);

  return (
    <Wrapper pathname={pathname} headerHeight={headerHeight} triggerMobileHeaderStyles={triggerMobileHeaderStyles}>
      <div className="mobile">
        {!mobileMenuOpen ? (
          <span onClick={onMobileMenuOpen}>
            <img className="white-header" style={{ width: 30, height: 30, cursor: "pointer" }} src={blackHeader} alt={'몬스솔라 헤더'}/>
            <img className="black-header" style={{ width: 30, height: 30, cursor: "pointer" }} src={whiteHeader} alt={'몬스솔라 헤더'}/>
          </span>
        ) : (
          <OpenSubMenu>
            <section className="close-menu">
              <div>
                <div>{/* 간격을 위해 빈 텍스트를 입력함 */}</div>
              </div>
              <span className="close-icon">
                <ClosingIcon onClick={onMobileMenuOpen}>
                  <img src={whiteX} style={{ width: 25, height: 25, cursor: "pointer" }} />
                </ClosingIcon>
              </span>
            </section>
            <div className="sub-menu">
              <SubMenuUlList onMobileMenuOpen={onMobileMenuOpen} />
            </div>
          </OpenSubMenu>
        )}
      </div>
      {/* ---------  데스크톱 버전의 헤더  --------------- */}
      <div className="desktop">
        <DesktopHeader headerHeight={headerHeight} pathname={pathname}>
          <NavAtNotHome pathname={pathname} />
        </DesktopHeader>
      </div>
    </Wrapper>
  );
};

export default SubHeader;
