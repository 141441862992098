import { useCallback, useEffect, useRef, useState } from "react";
import { dbService, storageService } from "../../fbase";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import editorIcon from "../../assets/projectpage/editorIcon.png";
import cancelIcon from "../../assets/projectpage/cancel_icon.png";
import blackArrow from "../../assets/contactpage/blackArrow.png";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  height: 550px;
  z-index: 999999;
  max-width: 80vw;

  @media (min-width: 765px) {
    max-width: 1100px;
  }
`;

const Container = styled.div`
  width: 80vw;

  .form-content {
    display: flex;
    flex-direction: column;

    .only-dt-wrapper,
    .only-dt-buttons {
      display: none;
    }

    .image-part {
      width: 100%;
      margin-bottom: 60px;

      .img-input {
        display: none;
      }

      // 진짜
      .rendering-img-wrapper {
        width: 100%;
        height: 15rem;
        border: 1px solid #d6d6d6;
        border-radius: 4px;
        background-color: white;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .rendering-file-name-delete {
        font-size: 16.5px;
        font-family: "Pretendard-Light";
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 30px;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        cursor: pointer;

        span {
          padding-left: 15px;
          padding-right: 15px;
          display: flex;

          img {
            opacity: 0.5;
          }
        }
      }

      /* 가짜 */
      .input-img-wrapper {
        width: 100%;
        height: 15rem;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        background-color: white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.7;

        .input-file-button {
          // 진짜
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          span {
            font-family: "Pretendard-Light";
            margin-top: 5px;
            color: #aeaeae;
          }
        }
      }

      .rendering-unnamed {
        font-size: 16.5px;
        font-family: "Pretendard-Light";
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 40px;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        color: #a1a1a1;

        span {
          padding-left: 15px;
        }
      }
    }

    .text-part {
      width: 100%;
      display: flex;
      flex-direction: column;

      select {
        font-size: 16.5px;
        margin-bottom: 20px;
        padding-left: 15px;
        height: 40px;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        color: #a1a1a1;

        -webkit-appearance: none; /* for chrome */

        -moz-appearance: none; /*for firefox*/

        appearance: none;
      }

      select {
        background: url(${blackArrow}) no-repeat 96.5% 50%/15px auto;
      }

      select::-ms-expand {
        display: none; /*for IE10,11*/
      }

      input {
        font-size: 16.5px;
        font-family: "Pretendard-Medium";
        margin-bottom: 20px;
        padding-left: 15px;
        height: 40px;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        color: #a1a1a1;
      }

      input::placeholder {
        font-size: 16.5px;
        font-family: "Pretendard-Medium";
        color: #a1a1a1;
      }

      .submit-editor {
        font-size: 16.5px;
        font-family: "Pretendard-Medium";
        margin-bottom: 20px;
        padding-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        color: #ffffff;
        background-color: #242424;
        cursor: pointer;
      }

      .close-editor {
        font-size: 16.5px;
        font-family: "Pretendard-Medium";
        margin-bottom: 20px;
        padding-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        color: #ffffff;
        background-color: #c6c6c6;
        cursor: pointer;
      }
    }
  }

  @media (min-width: 765px) {
    position: relative;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    .only-dt-buttons-wrapper {
      position: absolute;
      bottom: -80px;
      left: 0px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-around;
      max-width: 500px;
      right: 0px;
      margin-left: auto;
      margin-right: auto;
      height: 10%;

      input {
        width: 100%;
        height: 44px;
      }
    }

    .form-content {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .image-part {
        width: 40%;
        margin-bottom: 0px;

        .rendering-img-wrapper {
          height: 88.7%;
        }
      }

      .text-part {
        width: 50%;

        .close-editor {
          margin-right: 30px;
          width: 100%;
          height: 40px;
        }
        .submit-editor {
          width: 100%;
          height: 43px;
        }
      }
    }
  }
`;

const ToastEditor = ({ initValue, postId, toggleModalEditorOpen, editArticle }) => {
  const refSelect = useRef();

  const { register, getValues, handleSubmit } = useForm();
  const history = useHistory();
  const userInfo = useSelector((state) => state.user.userInfo);

  const [attachment, setAttachment] = useState(""); // 이미지 처리하기 위한 state
  const [loadingForSubmit, setLoadingForSubmit] = useState(false); //리액트 훅폼의 로딩 상태를 체크하기 위한 state
  const [editAttachmentURL, setEditAttachmentURL] = useState(initValue?.attachmentURL); // 수정 페이지에서, 사진을 초기화 했을 떄, 해당 컴포넌트를 리렌더링 시키기 위한 장치 -> 파일 선택하는 영역이 나오게 됨

  useEffect(() => {
    if (editArticle) {
      let { category } = initValue;

      let slicedStr = "";
      if (category.indexOf("(") !== -1) {
        //태양광 때문에
        slicedStr = category.slice(0, category.indexOf("("));
      } else {
        slicedStr = category;
      }

      let findSelectedIndexNumber = refSelect.current.innerText.split("\n").indexOf(slicedStr);
      refSelect.current[findSelectedIndexNumber].selected = true;
    }
  }, [editArticle, initValue, initValue?.category]);

  const onSubmit = useCallback(
    async (e) => {
      setLoadingForSubmit((prev) => true);

      let attachmentURL = "";

      if (attachment !== "") {
        const attachmentRef = await storageService.ref().child(`${userInfo.uid}/${Date.now()}`);
        const response = await attachmentRef.putString(attachment, "data_url");
        attachmentURL = await response.ref.getDownloadURL();
      }

      let postContents = {
        category: refSelect.current.value,
        title: getValues("title"),
        main_content: getValues("main_content"),
        // sub_content: getValues("sub_content"),
        table_title: getValues("table_title"),
        table_dueDate: getValues("table_dueDate"),
        table_place: getValues("table_place"),
      };

      // 프로젝트 페이지에서 -> 글 클릭 -> 수정 하는 로직
      if (initValue) {
        // attachment 가 없을 때 -> 유저가 수정 페이지에서 이미지를 교체하지 않았을 경우,  => attachment가 존재하지 않음
        if (!attachment && editAttachmentURL === "") {
          alert("사진을 등록 해 주세요");
          setLoadingForSubmit((prev) => false);
          return;
        } else if (attachment === "") {
          await dbService
            .doc(`projects/${postId}`)
            .update(Object.assign(postContents, { attachmentURL: initValue.attachmentURL }));
        } else {
          // attachment 가 있을 때 -> 유저가 수정 페이지에서 이미지를 교체했을 경우, => attachment가 존재함 -> attachment 가 있다면 attachmentURL이 생성됨.
          postContents.attachmentURL = attachmentURL;
          await dbService.doc(`projects/${postId}`).update(postContents);
        }
      } else {
        if (attachment === "") {
          alert("사진을 등록 해 주세요");
          setLoadingForSubmit((prev) => false);
          return;
        }
        postContents.attachmentURL = attachmentURL;
        postContents.createdAt = Date.now();
        await dbService.collection("projects").add(postContents);
      }

      toggleModalEditorOpen(); // 플로팅 액션버튼에서 에디터로 진입 했을 때, 에디터의 켜짐과 꺼짐을 담당하는 함수 -> 수정 상황에서도 onEditClick의 로직을 toggleModalEditorOpen 이랑 똑같이 하니까 중복해서 써도 문제 없네?

      setLoadingForSubmit((prev) => false);
      if (editArticle) {
        history.goBack();
      }
    },
    [
      attachment,
      editArticle,
      editAttachmentURL,
      getValues,
      history,
      initValue,
      postId,
      toggleModalEditorOpen,
      userInfo.uid,
    ]
  );

  const onFileChange = (e) => {
    const {
      target: { files },
    } = e;
    const theFile = files[0];

    const reader = new FileReader();
    reader.onloadend = (finishEvent) => {
      const {
        currentTarget: { result },
      } = finishEvent;
      setAttachment(result);
    };
    reader.readAsDataURL(theFile);
  };

  const onCloseEditorModal = useCallback(() => {
    toggleModalEditorOpen();
  }, [toggleModalEditorOpen]);

  const onClearPhotoClick = useCallback(() => {
    setAttachment("");
    setEditAttachmentURL("");
  }, []);

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <Wrapper>
      <Container>
        <form className="form-content" onSubmit={handleSubmit(onSubmit)}>
          <div className="image-part">
            {/* image */}
            {attachment || editAttachmentURL ? (
              <>
                <div className="rendering-img-wrapper">
                  <img
                    src={attachment || editAttachmentURL}
                    alt="mainImage"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>

                <div className="rendering-file-name-delete" onClick={onClearPhotoClick}>
                  <span>눌러서 사진 초기화하기</span>
                  <span>
                    <img src={cancelIcon} style={{ width: 16, height: 16 }} />
                  </span>
                </div>
              </>
            ) : (
              <>
                <label className="input-img-wrapper" for="input-file">
                  <div className="input-file-button">
                    <img src={editorIcon} style={{ width: 40, height: 40 }} />
                    <span>파일선택</span>
                    <span style={{ fontSize: "0.7rem" }}>사진 1:1비율 권장</span>
                  </div>
                </label>
                <input id="input-file" className="img-input" type="file" accept="image/*" onChange={onFileChange} />
                <div className="rendering-unnamed">
                  <span>선택된 파일 없음</span>
                </div>
              </>
            )}
          </div>

          {/* text */}
          <div className="text-part">
            <select {...register("category")} ref={refSelect}>
              <option value="태양광발전사업(RPS/FIT)">태양광발전사업</option>
              <option value="주택지원사업">주택지원사업</option>
              <option value="건물지원사업">건물지원사업</option>
            </select>

            {!initValue ? (
              <>
                {/* 최초 입력 시에 기본 값이 없게끔 */}
                <input placeholder={"제목 입력"} {...register("title", { required: true })} />
                <input placeholder={"메인 컨텐트"} {...register("main_content", { required: true })} />
                {/*<input placeholder={"서브 컨텐트"} {...register("sub_content", { required: true })} />*/}
                {/* table */}
                <input placeholder={"표에 들어갈 형태/용량"} {...register("table_title", { required: true })} />
                <input placeholder={"표에 들어갈 기간"} {...register("table_dueDate", { required: true })} />
                <input placeholder={"표에 들어갈 시공장소"} {...register("table_place", { required: true })} />
              </>
            ) : (
              <>
                {/* 수정할 때는 기존의 값을 그대로 가지고 와서, 수정할 부분만 수정하기 */}
                <input defaultValue={initValue?.title} {...register("title", { required: true })} />
                <input defaultValue={initValue?.main_content} {...register("main_content", { required: true })} />
                {/*<input defaultValue={initValue?.sub_content} {...register("sub_content", { required: true })} />*/}
                {/* table */}
                <input defaultValue={initValue?.table_title} {...register("table_title", { required: true })} />
                <input defaultValue={initValue?.table_dueDate} {...register("table_dueDate", { required: true })} />
                <input defaultValue={initValue?.table_place} {...register("table_place", { required: true })} />
              </>
            )}

            <div className="only-dt-buttons-wrapper">
              <input className="submit-editor" type="submit" value={loadingForSubmit ? "글 등록 중..." : "글 등록"} />
              <div className="close-editor" onClick={onCloseEditorModal}>
                <span>창닫기</span>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </Wrapper>
  );
};

export default ToastEditor;
