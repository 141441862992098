import React, { useCallback, useState } from "react";

import styled from "@emotion/styled";

import ScrollTrigger from "react-scroll-trigger";
import ViewMoreButton from "../ViewMoreButton/ViewMoreButton";
import * as Ani from "./animation";
import modifiedHomeImage from "../../assets/building_component/f_home.jpg";
import modifiedMobileHomeImage from "../../assets/building_component/f_mobile_home.jpg";

const MonsWrapper = styled.div`
  margin-top: 61px;
  padding-left: 1rem;
  padding-right: 1rem;

  .mons-second-business {
    margin-top: 28px;
    display: flex;
    flex-direction: column;

    .only-business-component {
      font-size: 1.07rem;
      font-family: "Pretendard-Light";
      margin-top: 30px;
      margin-bottom: 30px;
      line-height: 25px;
      color: #222222;
    }

    .mons-first-child {
      font-size: 1.5rem;
      font-family: "Pretendard-ExtraBold";
      color: #222222;
    }
    .mons-second-child {
      font-size: 1.2rem;
      font-family: "Pretendard-Medium";
      margin-top: 16px;
    }
  }

  .ani-one {
    animation-duration: 1.5s;
    animation-name: ${Ani.firstOpacityAndTranslateY};
  }
  .ani-two {
    animation-duration: 1.7s;
    animation-name: ${Ani.secondOpacityAndTranslateY};
  }
  .ani-three {
    animation-duration: 1.7s;
    animation-name: ${Ani.thirdOpacityAndTranslateY};
  }
  .ani-four {
    animation-duration: 1.9s;
    animation-name: ${Ani.thirdOpacityAndTranslateY};
  }
  .ani-five {
    animation-duration: 1.5s;
    animation-name: ${Ani.thirdOpacityAndTranslateY};
  }

  @media (min-width: 840px) {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    padding-left: 0rem;
    padding-right: 0rem;

    .mons-second-business {
      padding-left: 4rem;
      margin-top: 0px;
      width: 100%;
      justify-content: center;

      .only-business-component {
        font-size: 1.1rem;
        margin-top: 30px;
        margin-bottom: 20px;
        padding-right: 2rem;
        color: #666666;
      }

      .mons-first-child {
        font-size: 2.1rem;
        color: #222222;
        width: 45%;
        line-height: 40px;
      }
      .mons-second-child {
        font-size: 1.3rem;
        color: #222222;
      }
    }
  }
`;

const DivImageContainer = styled.div`
  width: 100%;
  overflow: hidden;

  .desktop {
    display: none;
  }

  .mons-second-business-image {
    width: 100%;
  }

  @media (min-width: 765px) {
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }

  @media (min-width: 840px) {
    padding-right: 3rem;
  }
`;

const HomeAtBusiness = () => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const onTriggerAnimate = useCallback(() => {
    if (!triggerAnimation) setTriggerAnimation((prev) => !prev);
  }, [triggerAnimation]);

  return (
    <ScrollTrigger onEnter={onTriggerAnimate}>
      <MonsWrapper>
        {/* 이미지 섹션 */}
        <DivImageContainer>
          <img
            className={
              triggerAnimation ? "desktop mons-second-business-image ani-one" : "desktop mons-second-business-image"
            }
            src={modifiedHomeImage}
            alt={"사업영역-주택지원사업"}
          />
          <img
            className={
              triggerAnimation ? "mobile mons-second-business-image ani-one" : "mobile mons-second-business-image"
            }
            src={modifiedMobileHomeImage}
            alt={"사업영역-주택지원사업"}
          />
        </DivImageContainer>

        {/* 글자 섹션 */}
        <div className="mons-second-business">
          <span className={triggerAnimation ? "mons-first-child ani-two" : "mons-first-child"}>
            건물지원사업(정부지원)
          </span>
          <span className={triggerAnimation ? "mons-second-child ani-three" : "mons-second-child"}>
            200kW까지 지원받아 설치하는 정부지원사업
          </span>
          <div className={triggerAnimation ? "only-business-component ani-four" : "only-business-component"}>
            <span>
              상가, 공장, 축사 등 주거용 건물을 제외한 모든 건물에 200kW까지 지원받아 설치할 수 있는 사업입니다.
            </span>
          </div>

          <div className={triggerAnimation ? "ani-five" : ""}>
            <ViewMoreButton others={"0.7rem"} pagePath={"business"} componentPath={"building"} />
          </div>
        </div>
      </MonsWrapper>
    </ScrollTrigger>
  );
};

export default HomeAtBusiness;
