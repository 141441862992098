import React, { useEffect, useRef, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import emailjs from "emailjs-com";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import Modal from "react-modal";

import ScrollTrigger from "react-scroll-trigger";
import * as Ani from "./Home/animation";

import katalkConsulting from "../assets/contactpage/katalkConsulting.png";
import basicConsulting from "../assets/contactpage/basicConsulting.png";
import blackArrow from "../assets/contactpage/blackArrow.png";
import { headerHeightSlice } from "../reducers/headerHeight";
import { offChannelTalkSlice } from "../reducers/offChannelTalk";

const DivWrapper = styled.div`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;

  // 애니메이션 시작
  .first-ani {
    animation-duration: 1.5s;
    animation-name: ${Ani.firstOpacityAndTranslateY};
  }
  .second-ani {
    animation-duration: 1.7s;
    animation-name: ${Ani.thirdOpacityAndTranslateY};
  }
  .third-ani {
    animation-duration: 1.7s;
    animation-name: ${Ani.thirdOpacityAndTranslateY};
  }

  @media (min-width: 765px) {
    max-width: ${(props) => (props.pathname === "/" ? "1000px" : "700px")};
    display: ${(props) => (props.pathname === "/" ? "flex" : "")};
  }
`;

const SectionTitleContainer = styled.section`
  margin-bottom: 20px;
  width: 100%;

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #707070;

    .main-title {
      font-size: 1.8rem;
      font-family: "Pretendard-ExtraBold";
      margin-top: 10px;
      margin-bottom: 15px;
      color: #000000;
    }

    .sub-title {
      font-size: 1.1rem;
      font-family: "Pretendard-Medium";

      margin-top: 10px;
      color: #707070;
    }
  }

  .desktop {
    display: none;
  }

  @media (min-width: 765px) {
    width: ${(props) => (props.pathname === "/" ? "25%" : "")};
    .desktop {
      display: block;
    }

    .title {
      flex-direction: ${(props) => (props.pathname === "/" ? "column-reverse" : "")};
      align-items: ${(props) => (props.pathname === "/" ? "flex-start" : "center")};

      .main-title {
        font-size: ${(props) => (props.pathname === "/" ? "1.9rem" : "1.6rem")};
        margin-top: 13px;
        font-family: "Pretendard-ExtraBold";
      }

      .sub-title {
        font-size: ${(props) => (props.pathname === "/" ? "" : "1.05rem")};
        margin-top: ${(props) => (props.pathname === "/" ? "0px" : "10px")};
      }
    }

    .mobile {
      display: none;
    }
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 765px) {
    width: ${(props) => (props.pathname === "/" ? "70%" : "")};
  }
`;

const SectionUsageContainer = styled.section`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 93%;

  .usage-label {
    font-size: 1.05rem;
    font-family: "Pretendard-Medium";
    margin-bottom: 10px;
    color: #707070;
  }
  .usage-select {
    font-family: "Pretendard-Medium";
    padding-left: 10px;
    height: 35px;
    outline: 1px solid #d6d6d6;
    border: 0;

    -webkit-appearance: none; /* for chrome */

    -moz-appearance: none; /*for firefox*/

    appearance: none;
  }

  .usage-select::-ms-expand {
    display: none; /*for IE10,11*/
  }

  select {
    background: url(${blackArrow}) no-repeat 98.5% 50%/15px auto;
  }

  @media (min-width: 765px) {
    .labels-property {
      margin-bottom: 12px;
      font-size: 1.05rem;
    }

    .usage-select {
      height: 40px;
      padding: 0px 10px;
    }
  }
`;

const SectionMiddleContainer = styled.div`
  margin-bottom: 20px;
  display: flex;

  flex-direction: column;
  width: 93%;

  .left-part {
    width: 100%;
    display: flex;
    flex-direction: column;

    .left-title {
      font-size: 1.05rem;
      font-family: "Pretendard-Medium";
      margin-bottom: 10px;
      color: #707070;
    }

    .left-input {
      margin-bottom: 20px;
      height: 35px;
      border: 0;
      outline: 1px solid #d6d6d6;
    }
  }
  .right-part {
    display: flex;
    flex-direction: column;
    width: 100%;
    .right-title {
      font-size: 1.05rem;
      font-family: "Pretendard-Medium";
      margin-bottom: 10px;
      color: #707070;
    }
    .right-input {
      height: 35px;
      outline: 1px solid #d6d6d6;
      border: 0;
    }
  }

  @media (min-width: 765px) {
    flex-direction: row;

    .left-part {
      margin-right: 20px;
      .labels-property {
        margin-bottom: 12px;
        font-size: 1.05rem;
      }

      .left-input {
        height: 40px;
        padding: 0px 10px;
      }
    }
    .right-part {
      .labels-property {
        margin-bottom: 12px;
        font-size: 1.05rem;
      }

      .right-input {
        height: 40px;
        padding: 0px 10px;
      }
    }
  }
`;

const DivConsultignContentContainer = styled.div`
  width: 93%;
  display: flex;
  flex-direction: column;

  .consulting-title {
    font-size: 1.05rem;
    font-family: "Pretendard-Medium";
    margin-bottom: 10px;
    color: #707070;
  }
  .consulting-content {
    height: 100px;
    outline: 1px solid #d6d6d6;
    border: 0;
    background-color: #fcfcfc;
  }

  @media (min-width: 765px) {
    .labels-property {
      margin-bottom: 12px;
      font-size: 1.05rem;
    }
  }
`;

const SectionButtonsContainer = styled.section`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 93%;

  .email-part {
    margin-bottom: 15px;
    width: 100%;

    .email-button {
      font-size: 1.3rem;
      font-family: "Pretendard-Medium";
      width: 100%;
      height: 62px;
      border-radius: 8px;
      border: 0;
      color: #ffffff;
      background-color: #242424;
      cursor: pointer;
      outline: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .kakao-part {
    margin-bottom: 30px;
    width: 100%;

    .kakao-button {
      font-size: 1.3rem;
      font-family: "Pretendard-Medium";
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 62px;
      border-radius: 8px;
      border: 0;
      color: #302108;
      background-color: #ffd500;
      cursor: pointer;
      outline: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 765px) {
    flex-direction: row;

    .email-part {
      margin-right: 20px;
    }
  }
`;

const SectionTerms = styled.section`
  position: relative;
  font-family: "Pretendard-Medium";
  margin-top: 20px;

  .terms-click-off {
    display: none;
  }

  .terms-click-on {
    /* background-color: red; */
  }

  .info-modal {
    cursor: pointer;
    font-family: "Pretendard-ExtraBold";
    border-bottom: 1px solid black;
  }
`;

const DivTermsModal = styled.div`
  line-height: 20px;
  z-index: 9898989898989898989898989898989898989898989898989898989;

  .wrapper {
    height: 50vh;
    overflow: auto;
    padding-top: 10px;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      font-family: "Pretendard-Medium";

      span {
        text-align: center;
        line-height: 30px;
        width: 50%;
      }
    }

    .apply-layout {
      line-height: 25px;
      padding-right: 13px;
    }

    .mgt {
      margin-top: 30px;
    }

    .container {
      font-size: 1.1rem;
      font-family: "Pretendard-Light";
      margin-bottom: 20px;

      .content-specific {
        color: #707070;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .term-buttons {
    margin-top: 20px;

    .buttons-wrapper {
      width: 100%;
      display: flex;

      flex-direction: column-reverse;

      .term-cancel {
        font-size: 1.3rem;
        font-family: "Pretendard-Medium";
        margin-right: 30px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 53px;
        border-radius: 6px;
        cursor: pointer;
        color: #ffffff;
        background-color: #c6c6c6;
      }

      .term-confirm {
        font-size: 1.3rem;
        font-family: "Pretendard-Medium";
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 53px;
        border-radius: 6px;
        color: #ffffff;
        cursor: pointer;
        background-color: #242424;
      }
    }
  }

  @media (min-width: 765px) {
    width: 100%;

    .wrapper {
      overflow: auto;

      .title {
        font-size: 1.7rem;
        font-family: "Pretendard-ExtraBold";
        justify-content: flex-start;

        span {
          text-align: left;
        }
      }

      .mgt {
        margin-top: 39px;
      }

      .container {
        display: flex;
        flex-direction: column;
        color: #707070;
        font-size: 1.1rem;
        font-family: "Pretendard-Medium";
        margin-bottom: 30px;
        line-height: 30px;

        .content-specific {
          color: #707070;
          display: flex;
          flex-direction: column;
        }
      }
    }

    .term-buttons {
      display: flex;
      justify-content: space-around;
      width: 100%;

      .buttons-wrapper {
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        width: 50%;

        .term-cancel {
          font-size: 1.4rem;
          font-family: "Pretendard-Medium";
          margin-right: 30px;
          margin-bottom: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70%;
          height: 53px;
          border-radius: 6px;
          color: #ffffff;
          cursor: pointer;
          background-color: #c6c6c6;
        }
        .term-confirm {
          font-size: 1.4rem;
          font-family: "Pretendard-Medium";
          margin-bottom: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70%;
          height: 53px;
          border-radius: 6px;
          color: #ffffff;
          cursor: pointer;
          background-color: #242424;
        }
      }
    }
  }
`;

const SpanErrorMessage = styled.span`
  margin-top: 20px;
  color: red;
  font-size: 0.8rem;
  font-family: "Pretendard-Medium";
`;

const ConsultingForm = () => {
  const { pathname } = useLocation();
  const refWrapper = useRef();
  const [termBoolean, setTermBoolean] = useState(false);
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [termsTrigger, setTermsTrigger] = useState(false);
  const [counterTerms, setCounterTerms] = useState(0);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (pathname.includes("contact")) {
      refWrapper.current.style.marginTop = "60px";
    }
  }, [pathname]);

  // emailjs
  const sendFeedback = (serviceID, templateId, variables) => {
    const userId = "user_B41BcRmwBTHZQ8qnw3a1o";
    emailjs.init(userId);

    emailjs
      .send(serviceID, templateId, variables)
      .then((res) => {
        if (res.text) alert("문의가 정상적으로 등록되었습니다.");
      })
      .catch((err) => {
        alert("문의 등록에 실패했습니다. 카카오톡 상담이나 전화 문의를 이용 해 주세요");
        console.error("에러 발생 시 참고:", err);
      });
  };

  const onSubmit = () => {
    setCounterTerms((prev) => ++prev);

    const templateId = "template_wk17e1n";
    const serviceID = "service_k3pyvae";

    const tempFormContent = {
      address: getValues("address"),
      consulting: getValues("consulting"),
      contactNumber: getValues("contact-number"),
      email: getValues("email"),
      name: getValues("name"),
      usage: getValues("usage"),
    };

    if (termBoolean) sendFeedback(serviceID, templateId, tempFormContent);
  };

  const onTriggerAnimate = useCallback(() => {
    if (!triggerAnimation) setTriggerAnimation(true);
  }, [triggerAnimation]);

  // 디스패치 -취소 버튼이나 확인 버튼 누르고 나가더라도 헤더의 상태가 유지 될 수 있도록 하는 로직
  const onTermsToggle = useCallback(() => {
    if (pathname === "/") {
      dispatch(headerHeightSlice.actions.saveHeaderHeight(1));
    }

    setTermsTrigger((prev) => !prev);
  }, [dispatch, pathname]);

  useEffect(() => {
    if (termsTrigger) {
      dispatch(offChannelTalkSlice.actions.saveOffChannelTalk(true));
    } else {
      dispatch(offChannelTalkSlice.actions.saveOffChannelTalk(false));
    }
  }, [dispatch, termsTrigger]);

  // 모달 오버레이에서 스크롤 방지
  useEffect(() => {
    if (termsTrigger) {
      document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [termsTrigger]);

  // 디스패치 - 취소 버튼이나 확인 버튼 누르고 나가더라도 헤더의 상태가 유지 될 수 있도록 하는 로직
  const onTermsConfirm = useCallback(() => {
    if (pathname === "/") {
      dispatch(headerHeightSlice.actions.saveHeaderHeight(1));
    }
    setCounterTerms((prev) => ++prev);
    setTermBoolean((prev) => true);

    setTermsTrigger((prev) => false);
  }, [dispatch, pathname]);

  const onTermsAndCounterToggle = useCallback(() => {
    setCounterTerms((prev) => ++prev);
    setTermBoolean((prev) => !prev);
  }, []);

  return (
    <ScrollTrigger onEnter={onTriggerAnimate}>
      <DivWrapper pathname={pathname} ref={refWrapper}>
        <SectionTitleContainer pathname={pathname}>
          <div className="title">
            <span className={triggerAnimation ? "mobile sub-title second-ani" : "mobile sub-title"}>
              언제든지 문의주세요!
            </span>

            <span className={triggerAnimation ? "main-title first-ani" : "main-title"}>무료상담 신청</span>

            <span className={triggerAnimation ? "desktop sub-title first-ani" : "desktop sub-title"}>
              {pathname === "/"
                ? "언제든지 문의주세요!"
                : "궁금한 내용에 대해 작성해주시면 전화 또는 이메일로 답변드립니다."}
            </span>
          </div>
        </SectionTitleContainer>

        <FormContainer
          pathname={pathname}
          onSubmit={handleSubmit(onSubmit)}
          className={triggerAnimation ? "third-ani" : ""}
        >
          <SectionUsageContainer>
            <label className="usage-label labels-property">구분</label>
            <select className="usage-select" {...register("usage")}>
              <option value="발전사업(상업용 태양광)">발전사업(상업용 태양광)</option>
              <option value="주택/건물(정부지원)">주택/건물(정부지원)</option>
              <option value="기타">기타</option>
            </select>
          </SectionUsageContainer>

          <SectionMiddleContainer>
            <div className="left-part">
              <label className="left-title labels-property">
                성명
                <span
                  style={{ fontSize: "1rem", color: "#FF4343", marginLeft: "5px", fontFamily: "Pretendard-Medium" }}
                >
                  *
                </span>
              </label>
              <input className="left-input" {...register("name", { required: true })} />
            </div>
            <div className="right-part">
              <label className="right-title labels-property">
                연락처
                <span
                  style={{ fontSize: "1rem", color: "#FF4343", marginLeft: "5px", fontFamily: "Pretendard-Medium" }}
                >
                  *
                </span>
              </label>
              <input type="number" className="right-input" {...register("contact-number", { required: true })} />
            </div>
          </SectionMiddleContainer>

          <SectionMiddleContainer>
            <div className="left-part">
              <label className="left-title labels-property">이메일</label>
              <input className="left-input" type="email" {...register("email")} />
            </div>
            <div className="right-part">
              <label className="right-title labels-property">주소</label>
              <input className="right-input" {...register("address")} />
            </div>
          </SectionMiddleContainer>

          {/* 폼 문의 내용 */}
          <DivConsultignContentContainer>
            <label className="consulting-title labels-property">
              문의내용
              <span style={{ fontSize: "1rem", color: "#FF4343", marginLeft: "5px", fontFamily: "Pretendard-Medium" }}>
                *
              </span>
            </label>
            <textarea className="consulting-content" {...register("consulting", { required: true })} />
          </DivConsultignContentContainer>

          <SectionTerms>
            <span className="checked-control" onClick={onTermsAndCounterToggle}>
              <input
                defaultChecked={termBoolean}
                defaultValue={termBoolean}
                checked={termBoolean}
                className="check-box"
                type="checkbox"
              />
            </span>
            <span className="terms-click-parent">
              이용약관 및&nbsp;
              <span className="info-modal" onClick={onTermsToggle}>
                개인정보
              </span>
              &nbsp;취급방침 동의
              <span style={{ fontSize: "1rem", color: "#FF4343", marginLeft: "5px", fontFamily: "Pretendard-Medium" }}>
                *
              </span>
            </span>
            <div className={termsTrigger ? "terms-click-on" : "terms-click-off"}>
              <Modal
                shouldCloseOnOverlayClick={true}
                isOpen={termsTrigger}
                onRequestClose={onTermsToggle}
                style={customStyles}
              >
                <DivTermsModal>
                  <div className="wrapper">
                    <div className="title">
                      <span>개인정보의 수집 및 이용목적</span>
                    </div>

                    <div className="container mgt apply-layout">
                      <span className="content-title">▶ 개인정보의 수집 및 이용목적</span>
                      <div className="content-specific">
                        <span>- 서비스 이용에 따른 본인식별, 실명확인, 가입의사 확인, 연령제한 서비스 이용</span>
                        <span>- 고지사항 전달, 불만처리 의사소통 경로 확보, 물품배송 시 정확한 배송지 정보 확보</span>
                        <span>- 신규 서비스 등 최신정보 안내 및 개인맞춤서비스 제공을 위한 자료</span>
                        <span>- 기타 원활한 양질의 서비스 제공 등</span>
                      </div>
                    </div>

                    <div className="container apply-layout">
                      <span className="content-title">▶ 수집하는 개인정보의 항목</span>
                      <div className="content-specific">
                        <span>- 이름, 이메일, 주민등록번호, 주소, 연락처, 핸드폰번호, 그 외 선택항목</span>
                      </div>
                    </div>

                    <div className="container apply-layout">
                      <span className="content-title">▶ 개인정보의 보유 및 이용기간</span>
                      <div className="content-specific">
                        <span>- 원칙적으로 개인정보의 수집 또는 제공받은 목적 달성 시 지체 없이 파기합니다.</span>
                        <span>
                          - 다만, 원활한 서비스의 상담을 위해 상담 완료 후 내용을 3개월간 보유할 수 있으며<br></br>
                          전자상거래에서의 소비자보호에 관한 법률 등 타법률에 의해 보존할 필요가 있는 경우에는 일정기간
                          보존합니다.
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="term-buttons">
                    <div className="buttons-wrapper">
                      <div className="term-cancel" onClick={onTermsToggle}>
                        취&nbsp;&nbsp;소
                      </div>
                      <div className="term-confirm" onClick={onTermsConfirm}>
                        확&nbsp;&nbsp;인
                      </div>
                    </div>
                  </div>
                </DivTermsModal>
              </Modal>
            </div>
          </SectionTerms>

          {(errors.name ||
            errors["contact-number"] ||
            errors.consulting ||
            (counterTerms === 0 ? false : !termBoolean)) && (
            <SpanErrorMessage>* 필수 입력 사항을 입력 해 주세요</SpanErrorMessage>
          )}

          {/* 전송버튼 */}
          <SectionButtonsContainer>
            <div className="email-part">
              <button className="email-button" type="submit">
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  <img style={{ width: 23, height: 23 }} src={basicConsulting} alt="고객문의" />
                </span>
                <span>문의하기</span>
              </button>
            </div>

            <div
              className="kakao-part"
              onClick={() => window.open("https://bizmessage.kakao.com/chat/open/@몬스솔라?extra=lRJpkeYx", "_blank")}
            >
              <div className="kakao-button">
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  <img style={{ width: 23, height: 23 }} src={katalkConsulting} alt={"카카오톡고객문의"} />
                </span>
                <span>카톡 상담하기</span>
              </div>
            </div>
          </SectionButtonsContainer>
        </FormContainer>
      </DivWrapper>
    </ScrollTrigger>
  );
};

export default ConsultingForm;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "3rem",
  },
};
