import React from "react";
import { Link } from "react-router-dom";
import Collapsible from "react-collapsible";
import styled from "@emotion/styled";

const Ul = styled.ul`
  z-index:989899889989889898998898989988998899898899898;
  .menu-bar-content {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    z-index:989899889989889898998898989988998899898899898;
  }

  @media (min-width: 1100px) {
    display: none;
  }
`;

const SubMenuTitle = styled.span`
  color: #ffffff;
  z-index:989899889989889898998898989988998899898899898;
`;

const CollapsibleMenu = styled.div`
  margin-top: 2rem;
  font-size: 1rem;
  .collapsible-menu {
    margin-bottom: 1rem;
  }
`;

const DesktopUl = styled.ul`
  display: none;
  @media (min-width: 1100px) {
    display: block;
  }
`;

// 커럽서블 메뉴에 사용 될 기호들
const plus = "+";
const minus = "-";

const SubMenuUlList = ({ onMobileMenuOpen }) => {
  return (
    <>
      <Ul className="menu-bar-contents">
        {/* 사업영역 메뉴 */}
        <li className="menu-bar-content">
          <SubMenuTitle>
            <Collapsible
              trigger={
                <div style={{ cursor: "pointer" }}>
                  <span style={{ fontWeight: "bold", fontSize: "1.15rem" }}>사업영역</span>
                  &nbsp;&nbsp;
                  <span style={{ color: "#8A8A8A" }}>{plus}</span>
                </div>
              }
              triggerWhenOpen={
                <div style={{ cursor: "pointer" }}>
                  <span style={{ fontWeight: "bold", fontSize: "1.15rem" }}>사업영역</span>
                  &nbsp;&nbsp;
                  <span style={{ color: "#8A8A8A" }}>{minus}</span>
                </div>
              }
            >
              <CollapsibleMenu>
                <li className="collapsible-menu" onClick={onMobileMenuOpen}>
                  <Link to="/business?name=sun">태양광발전사업 (RPS/FIT)</Link>
                </li>
                <li className="collapsible-menu" onClick={onMobileMenuOpen}>
                  <Link to="/business?name=home">주택지원사업</Link>
                </li>
                <li onClick={onMobileMenuOpen}>
                  <Link to="/business?name=building">건물지원사업</Link>
                </li>
              </CollapsibleMenu>
            </Collapsible>
          </SubMenuTitle>
        </li>

        {/* 프로젝트 */}
        <li className="menu-bar-content">
          <Link to="/project">
            <SubMenuTitle onClick={onMobileMenuOpen}>프로젝트</SubMenuTitle>
          </Link>
        </li>

        {/* 제품안내 */}
        <li className="menu-bar-content">
          <SubMenuTitle>
            <Collapsible
              trigger={
                <div style={{ cursor: "pointer" }}>
                  <span style={{ fontWeight: "bold", fontSize: "1.15rem" }}>제품 안내</span>
                  &nbsp;&nbsp;
                  <span style={{ color: "#8A8A8A" }}>{plus}</span>
                </div>
              }
              triggerWhenOpen={
                <div style={{ cursor: "pointer" }}>
                  <span style={{ fontWeight: "bold", fontSize: "1.15rem" }}>제품 안내</span>
                  &nbsp;&nbsp;
                  <span style={{ color: "#8A8A8A" }}>{minus}</span>
                </div>
              }
            >
              <CollapsibleMenu>
                <li className="collapsible-menu" style={{ marginBottom: "15px" }} onClick={onMobileMenuOpen}>
                  <Link to="/product?name=eclair">Eclair 이클레어</Link>
                </li>
                <li className="collapsible-menu">
                  <Collapsible
                    trigger={
                      <div style={{ cursor: "pointer", color: "#8A8A8A" }}>
                        <span>Inverter 인버터</span>
                        &nbsp;&nbsp;
                        <span style={{ color: "#8A8A8A" }}>{plus}</span>
                      </div>
                    }
                    triggerWhenOpen={
                      <div style={{ cursor: "pointer", color: "#8A8A8A" }}>
                        <span>Inverter 인버터</span>
                        &nbsp;&nbsp;
                        <span style={{ color: "#8A8A8A" }}>{minus}</span>
                      </div>
                    }
                  >
                    <CollapsibleMenu>
                      <li className="collapsible-menu" onClick={onMobileMenuOpen}>
                        <Link to="/product?name=kra-krb">ESP3K5-KRA/KRB</Link>
                      </li>
                      <li className="collapsible-menu" onClick={onMobileMenuOpen}>
                        <Link to="/product?name=krd">ESP3K5-KRD</Link>
                      </li>
                      <li className="collapsible-menu" onClick={onMobileMenuOpen}>
                        <Link to="/product?name=kra">ESP25K</Link>
                      </li>
                      <li className="collapsible-menu" onClick={onMobileMenuOpen}>
                        <Link to="/product?name=50k">ESP50K</Link>
                      </li>
                      <li className="collapsible-menu" onClick={onMobileMenuOpen}>
                        <Link to="/product?name=500k">ESP500K</Link>
                      </li>
                    </CollapsibleMenu>
                  </Collapsible>
                </li>
              </CollapsibleMenu>
            </Collapsible>
          </SubMenuTitle>
        </li>

        {/* 블로그 */}
        <li className="menu-bar-content">
          <Link to="/blog">
            <SubMenuTitle onClick={onMobileMenuOpen}>블로그</SubMenuTitle>
          </Link>
        </li>

        {/* 고객문의 */}
        <li className="menu-bar-content">
          <Link to="/contact">
            <SubMenuTitle onClick={onMobileMenuOpen}>고객문의</SubMenuTitle>
          </Link>
        </li>

        {/* 몬스소개 */}
        <li className="menu-bar-content">
          <Link to="/monsinfo">
            <SubMenuTitle onClick={onMobileMenuOpen}>몬스소개</SubMenuTitle>
          </Link>
        </li>
      </Ul>

      <DesktopUl className="menu-bar-contents">
        <li className="menu-bar-content">
          <Link to="/business">
            <span>사업영역</span>
          </Link>
        </li>
        <li className="menu-bar-content">
          <Link to="/project">
            <span>프로젝트</span>
          </Link>
        </li>
        <li className="menu-bar-content">
          <Link to="/product">
            <span>제품안내</span>
          </Link>
        </li>
        <li className="menu-bar-content">
          <Link to="/blog">
            <span>블로그</span>
          </Link>
        </li>
        <li className="menu-bar-content">
          <Link to="/contact">
            <span>고객문의</span>
          </Link>
        </li>
        <li className="menu-bar-content">
          <Link to="/monsinfo">
            <span>몬스소개</span>
          </Link>
        </li>
      </DesktopUl>
    </>
  );
};

export default SubMenuUlList;
