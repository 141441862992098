import React from "react";
import goConsulting from "../../assets/etc/modified_goConsulting.png";
import styled from "@emotion/styled";
import LinkContainer from "../LinkContainer/LinkContainer";

const Wrapper = styled.div`
  margin-top: 10px;
  width: 55px;
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: black;

  box-shadow: rgb(0 0 0 / 12%) 0px 3px 12px 0px !important;
  color: black !important;

  .icon {
    img {
      width: 15px;
      height: 15px;
    }
  }

  .text {
    font-size: 11.5px;
    font-family: "Pretendard-Light";
    color: #ffffff;
  }

  @media (min-width: 400px) {
    width: 65px;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    background-color: black;

    box-shadow: rgb(0 0 0 / 12%) 0px 3px 12px 0px !important;
    color: black !important;

    .icon {
      img {
        opacity: 0.5;
        width: 18px;
        height: 18px;
        margin-bottom: 3px;
        
      }
    }

    .text {
      font-size: 11.5px;
      font-family: "Pretendard-Light";
      color: #ffffff;
    }
  }
`;

const WebConsulting = () => {
  return (
    <LinkContainer pagePath={"contact"}>
      <Wrapper>
        <span className="icon">
          <img src={goConsulting} />
        </span>
        <span className="text">문의하기</span>
      </Wrapper>
    </LinkContainer>
  );
};

export default WebConsulting;
