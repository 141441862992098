import React from "react";

import styled from "@emotion/styled";

import ViewMoreButton from "../../components/ViewMoreButton/ViewMoreButton";

import modifiedSunImage from "../../assets/monsinfopage/f_info_sun.jpg";
import modifiedMbSunImage from "../../assets/monsinfopage/f_info_mb_sun.jpg";

const MonsWrapper = styled.div`
  margin-top: 61px;

  overflow: hidden;

  .desktop-img {
    display:none;
  }
  
  @media (min-width:1100px) {
    .desktop-img {
      display:block; 
    }
    .mobile-img {
      display:none;
    }
  }
  
  @media (min-width: 900px) {
    margin-top: 0px;
    padding-left: 0rem;
    padding-right: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .mons-second-business-image {
    width: 100%;
  }

  .mons-second-business {
    margin-top: 28px;
    display: flex;
    flex-direction: column;

    @media (min-width: 765px) {
      margin-top: 0px;
      width: 100%;
      justify-content: center;
    }

    .only-business-component {
      font-size: 1.07rem;
      font-family: "Pretendard-Light";
      margin-top: 30px;
      line-height: 25px;
      color: #222222;

      @media (min-width: 765px) {
        font-size: 1.1rem;
        font-family: "Pretendard-Light";
        width: 500px;
        font-weight: 200;
        color: #666666;
      }
    }

    .mons-first-child {
      font-size: 1.5rem;
      font-family: "Pretendard-ExtraBold";
      color: #222222;

      @media (min-width: 765px) {
        font-size: 1.7rem;

        margin-top: 60px;
        color: #222222;
        width: 400px;
      }
    }
    .mons-second-child {
      margin-top: 16px;
      font-size: 1.2rem;
      margin-bottom: 28px;
      font-family: "Pretendard-Medium";

      @media (min-width: 765px) {
        font-size: 1.2rem;
        color: #222222;
      }
    }
  }
`;

const SunAtMonsInfo = () => {
  return (
    <MonsWrapper>
      {/* 이미지 섹션 */}
      <img className="desktop-img mons-second-business-image" src={modifiedSunImage} />
      <img className="mobile-img mons-second-business-image" src={modifiedMbSunImage} />

      {/* 글자 섹션 */}
      <div className="mons-second-business">
        <span className="mons-first-child">태양광발전사업 (RPS/FIT)</span>
        <span className="mons-second-child">저렴한 유지관리 비용으로 안정적인 수익</span>

        <ViewMoreButton pagePath={"business"} componentPath={"sun"}></ViewMoreButton>
      </div>
    </MonsWrapper>
  );
};

export default SunAtMonsInfo;
