import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";

import CompanyLogoAndHeader from "./companyLogoAndSubHeader";
import { useDispatch, useSelector } from "react-redux";
import SubNavHeader from "./SubNavHeader";

import { headerHeightSlice } from "../../reducers/headerHeight";

const DivWrapper = styled.div`
  margin-bottom: 40px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: fixed;
  z-index: 99999123423499;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  height: 40px;

  border-bottom: ${(props) =>
    props.pathname !== "/" || (props.pathname === "/" && props.headerHeight === 1)
      ? "1px solid rgba(178, 171, 170, 0.3)"
      : ""};

  background-color: ${(props) =>
    props.pathname === "/" && props.headerHeight === 0
      ? "none"
      : props.pathname === "/" && props.headerHeight === 1
      ? "white"
      : "white"};

  .company-logo {
    margin-left: 1rem;
    width: 150px;
    display: flex;
    align-items: center;
    z-index: 99999898;

    .img-container {
      width: 160px;
      height: 71px;
    }
  }

  .menu-bar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 99954645699;
    padding-right: 1rem;
    font-family: "Pretendard-Light";
  }

  .only-dtSize-container {
    display: none;
  }

  .sub-nav-wrapper {
    display: none;
  }

  @media (min-width: 1100px) {
    .company-logo {
      .img-container {
        width: 180px;
        height: 71px;
      }
    }

    .sub-nav-wrapper {
      position: fixed;
      top: 72px;
      display: ${(props) => (props.search && props.search !== "?name=eclair" ? "block" : "none")};
      width: 100%;
      height: 50px;
      z-index: 999;
      background-color: ${(props) => (props.pathname === "/business" ? "#F8F8F8" : "#707070")};
    }
  }
`;

const MobileCompanyLogoAndHeader = styled(CompanyLogoAndHeader)`
  @media (min-width: 1100px) {
    display: none;
  }
`;

const businessLists = ["태양광발전사업(RPS/FIT)", "주택지원사업", "건물지원사업"];
const productLists = ["ESP3K5-KRA/KRB", "ESP3K5-KRD", "ESP25K", "ESP50K", "ESP500K"]; // 주석 삭제 금지 : esp25k가 2개일 때, 리액트는 반응 하지 못 한다.

const Header = () => {
  // 헤더는 총 3가지 경우로 나뉨 // pathname 과  triggerHeaderStyle로 케이스 분류함.
  // 1. 메인페이지에서 스크롤을 95vh만큼 아래로 내리기 전까지 -> 배경색 투명, 로고와 글자 흰색
  // 2. 1의 경우에서, 그 이상으로 스크롤을 했을 경우 -> 배경색 흰색, 로고와 글자 네이비
  // 3. 메인 페이지 말고, 나머지 페이지의 헤더. -> 배경색 흰색, 로고와 글자 네이비
  const { headerHeight } = useSelector((state) => state.headerHeight); // {headerHeight : 0 }
  const dispatch = useDispatch();
  const headerRef = useRef();

  const { pathname, search } = useLocation();

  const [scrollPosition, setScrollPosition] = useState(0);
  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };
  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
    return () => window.addEventListener("scroll", updateScroll);
  }, []);

  useEffect(() => {
    if (scrollPosition < 530 && pathname === "/") {
      dispatch(headerHeightSlice.actions.saveHeaderHeight(0));
    }
  }, [dispatch, pathname, scrollPosition]);

  return (
    <DivWrapper headerHeight={headerHeight} ref={headerRef} pathname={pathname} search={search}>
      <MobileCompanyLogoAndHeader />

      <div className="only-dtSize-container">
        <CompanyLogoAndHeader />
      </div>

      {/* 사업영역의 컴포넌트 페이지와 제품안내 중에서 inverter에만 있는 서브 헤더임 */}
      <div className="sub-nav-wrapper">
        <SubNavHeader
          search={search.slice(search.indexOf("=") + 1)}
          pathname={pathname}
          lists={pathname === "/business" ? businessLists : productLists}
        />
      </div>
    </DivWrapper>
  );
};

export default Header;
