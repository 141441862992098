import React, { useCallback } from "react";
import styled from "@emotion/styled";
import scrollArrow from "../../../assets/mainpage/scrollArrow.png";

const Wrapper = styled.div`
  position: absolute;
  bottom: 115px;
  right: 50%;
  transform: translate(50%, 50%);
  -moz-transform: translate(50%, 50%);
  -webkit-transform: translate(50%, 50%);
  -o-transform: translate(50%, 50%);
  width: 43px;
  height: 43px;

  cursor: pointer;

  display: ${(props) => (props.editorOpen ? "none" : "block")};

  img {
    font-size: 1.6rem;
    font-family: "Pretendard-Medium";
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1.5px solid #ffffff;
    color: #ffffff;
  }

  &:hover {
    background-color: rgba(145, 145, 145, 0.3);
  }

  @media (min-width: 765px) {
    bottom: ${(props) => (props.isTablet ? "90px" : "30px")};
  }
`;

const Arrow = ({ editorOpen, refObj, isTablet, place }) => {
  // 메인 이미지에서 스크롤을 아래로 내려주는 함수
  const onMoveScrollTo = useCallback(() => {
    const currentOffset = refObj.current.offsetTop;
    const policyAboutHeight = 80; //임의대로 100을 더 뺐음. //currentWidth > 1023 ? -600 :
    window.scrollTo({ top: currentOffset - policyAboutHeight, behavior: "smooth" });
  }, [refObj]);

  return (
    <Wrapper
      className="arrow-to-down-ref"
      onClick={onMoveScrollTo}
      isTablet={isTablet}
      place={place}
      editorOpen={editorOpen}
    >
      <img src={scrollArrow} alt={'몬스솔라 화살표'}/>
    </Wrapper>
  );
};

export default Arrow;
