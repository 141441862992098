import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offChannelTalk: false,
};

export const offChannelTalkSlice = createSlice({
  name: "offChannelTalk",
  initialState,
  reducers: {
    saveOffChannelTalk(state, action) {
      state.offChannelTalk = action.payload;
    },
  },
});
