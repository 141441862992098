import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";

import ImageCarouselTwo from "./ImageCarouselTwo";

import banner from "../../assets/productpage/inverter/banner.jpg";
import bannerSub from "../../assets/productpage/inverter/deleted_mdf/mdf_50K.png";
import mbMachineImages from "../../assets/productpage/inverter/deleted_mb_mdf/mb_only_machine_50k.png";
import feature from "../../assets/productpage/inverter/feature.jpg";
import specImage from "../../assets/productpage/inverter/new_spec.jpg";
import mbSpecImage from "../../assets/productpage/inverter/mb_spec.jpg";
import icon01 from "../../assets/productpage/inverter/01_inverterIcon.png";
import icon02 from "../../assets/productpage/inverter/02_inverterIcon.png";
import icon03 from "../../assets/productpage/inverter/03_inverterIcon.png";

const DivWrapper = styled.div`
  .desktop-carousel {
    display: none;
  }

  @media (min-width: 820px) {
    .desktop-carousel {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40vh;
      position: relative;

      .feature-wrapper {
        width: 100%;
        max-width: 1000px;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .img-container {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      .container {
        .title {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          width: 100%;

          span {
            &:nth-child(1) {
              font-size: 2.1rem;
              font-family: "Pretendard-ExtraBold";
              margin-right: 5px;

              color: #ffffff;
            }

            &:nth-child(2) {
              font-size: 1.35rem;
              font-family: "Pretendard-Medium";
              color: #ffffff;
            }
          }
        }

        .contents {
          margin-top: 65px;
          display: flex;
          justify-content: space-between;

          .content {
            color: #ffffff;
            font-size: 1.1rem;
            font-family: "Pretendard-Medium";
            display: flex;
            line-height: 30px;

            &:nth-child(2) {
              margin-left: 45px;
              margin-right: 45px;
            }

            span {
              &:nth-child(1) {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    .mobile-carousel {
      display: none;
    }
  }
`;

// div banner text
const DivBannerText = styled.div`
  margin-top: 73px;
  margin-bottom: 10vh;
  position: relative;
  width: 100%;
  height: 80vh;
  z-index: 99;

  .text-container {
    position: absolute;

    top: 4rem;
    left: 2rem;

    .first-text {
      font-size: 1.3rem;
      font-family: "Pretendard-ExtraBold";
      margin-bottom: 27px;
      margin-right: 5px;
      color: #ffffff;
    }
    .second-text {
      font-size: 0.9rem;
      font-family: "Pretendard-Light";
      margin-bottom: 27px;
      color: #ffffff;
    }
    .third-text {
      margin-top: 27px;
      margin-bottom: 14px;
      font-size: 2.1rem;
      font-family: "Pretendard-ExtraBold";

      color: #ffffff;
    }
    .fourth-text {
      font-size: 1.1rem;
      font-family: "Pretendard-Light";
      color: #ffffff;
    }
  }

  .bottom-container {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    color: #ffffff;

    .title {
      font-size: 1rem;
      font-family: "Pretendard-Medium";
      margin-bottom: 10px;
      color: #ffffff;
    }
    .content {
      font-size: 1.35rem;
      font-family: "Pretendard-ExtraBold";

      color: #ffffff;
    }

    .vertical-line {
      border-right: thin solid #e5e5e5;

      .only-padding-bottom {
        padding-bottom: 1rem;
      }
    }

    .only-padding-left {
      padding-left: 1rem;
    }

    .top-content {
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex;

      div {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: thin solid white;
      }
    }
    .bottom-content {
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex;

      div {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .only-padding-top {
        padding-top: 1rem;
      }
    }
  }

  .mb-machine {
    position: absolute;
    bottom: -50px;
    right: 20px;
    /* width:80%; */
  }

  .tb-machine {
    display: none;
  }

  @media (min-width: 440px) {
    .tb-machine {
      position: absolute;
      bottom: -50px;
      right: 30px;
      display: block;
      /* width:100px; */
    }
    .mb-machine {
      display: none;
    }
  }

  @media (min-width: 765px) {
    .tb-machine {
      display: none;
    }
  }

  @media (min-width: 965px) {
    .text-wrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      width: 100%;
      height: 100%;
      .text-container {
        position: static;

        top: 40%;
        left: 200px;

        .first-text {
          font-size: 2.1rem;
          font-family: "Pretendard-ExtraBold";
        }
        .secont-text {
          font-size: 1.6rem;
          font-family: "Pretendard-Medium";
        }
        .third-text {
          font-size: 2.5rem;
          font-family: "Pretendard-ExtraBold";
          margin-top: 27px;
          margin-bottom: 14px;
        }
        .fourth-text {
          font-size: 1.3rem;
          font-family: "Pretendard-Medium";
        }
      }
    }
  }
`;

const DivBannerImageContainer = styled.div`
  margin-bottom: 10rem;
  position: absolute;
  top: 72px;
  width: 100%;
  height: 80vh;

  @media (min-width: 765px) {
    height: calc(86vh - 72px);
  }
`;

// div banner image
const DivBannerImage = styled.img`
  margin-bottom: 10vh;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: brightness(46%);
`;

const SectionTextConatiner = styled.section`
  margin-top: 50px;
  margin-bottom: 50px;

  .sub-img {
    display: none;
  }

  .text-container {
    margin-bottom: 50px;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;

    .first-text {
      font-size: 1.65rem;
      font-family: "Pretendard-ExtraBold";
      margin-bottom: 12px;

      color: #222222;
    }
    .second-text {
      font-size: 1.27rem;
      font-family: "Pretendard-Medium";
      margin-bottom: 28px;
      color: #222222;
    }
    .third-text {
      font-size: 1.05rem;
      font-family: "Pretendard-Light";
      color: #222222;
      line-height: 25px;
    }
  }

  .bottom-container {
    display: flex;
    width: 100%;

    .position-wrapper {
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex;
      width: 100%;
    }

    .horizontal-line {
      border-bottom: 1px solid #b4b4b4;
    }

    .top-content,
    .bottom-content {
      width: 100%;
      border-top: 2px solid #222222;
      border-bottom: 2px solid #222222;
      margin-bottom: 50px;
    }

    .bottom-content {
      border-left: 2px solid #b4b4b4;
    }

    .box-content {
      padding: 1rem;
      display: flex;
      flex-direction: column;
    }

    .box {
      display: flex;
      justify-content: space-between;
    }

    .box-title {
      font-size: 1.02rem;
      font-family: "Pretendard-Medium";
      margin-bottom: 28px;
      color: #222222;
    }

    .box-spec {
      font-size: 1.1rem;
      font-family: "Pretendard-ExtraBold";
      font-weight: bold;
      color: #222222;
    }
  }

  @media (min-width: 765px) {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 1000px;

    .sub-img {
      display: block;
      position: absolute;
      /* border: 3px solid red; */
      /* top: -510px; */
      bottom: 390px;
      right: 10px;
      width: 320px;
      /* height: 700px; */
    }

    .text-container {
      width: 33%;
      height: 100%;

      .first-text {
        font-size: 2.1rem;
        margin-bottom: 14px;
      }

      .second-text {
        font-size: 1.4rem;
        margin-bottom: 28px;
      }

      .third-text {
        padding-top: 0px;
      }
    }
    .bottom-container {
      position: absolute;
      right: 0;

      width: 55%;
      .position-wrapper {
        .top-content,
        .bottom-content {
          border-top: 1px solid #b4b4b4;
          border-bottom: 1px solid #b4b4b4;
        }
      }
    }
  }
`;

const SectionSpec = styled.section`
  position: relative;
  display: flex;
  justify-content: center;

  .vanish-open-height-button {
    display: none;
  }

  .open-height-button {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    cursor: pointer;
    background-color: rgba(242, 242, 242, 0.98);
    box-shadow: 0px -15px 21px 21px rgba(242, 242, 242, 0.98);
  }

  .clickable-button {
    display: flex;
    height: 80%;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 300px;
    font-family: "Pretendard-Medium";
    cursor: pointer;
    opacity: 0.5;
  }

  .hide-height {
    height: 500px;
    overflow: hidden;
  }

  .bottom-text-wrapper {
    position: absolute;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .dt-image {
    display: none;
  }

  @media (min-width: 1400px) {
    position: relative;
    display: flex;
    justify-content: center;

    .vanish-open-height-button {
      display: none;
    }

    .open-height-button {
      display: none;
    }

    .dt-image {
      display: block;
      filter: brightness(91%);
    }
    .mb-image {
      display: none;
    }
    .bottom-text-wrapper {
      padding-left: 0rem;
      padding-right: 0rem;

      width: 70vw;
      height: 100%;

      position: absolute;

      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const DivImageText = styled.div`
  opacity: 0.9;
  min-width: 350px;

  .content-container {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    z-index: 999;

    .title {
      font-size: 1.7rem;
      font-family: "Pretendard-ExtraBold";
      margin-top: 70px;
      margin-bottom: 33px;

      color: white;
    }

    .spec {
      display: flex;
      flex-direction: column;
      background-color: white;

      .spec-position {
        padding-left: 1rem;
        margin-top: 40px;
      }

      .spec-title {
        font-size: 1.1rem;
        font-family: "Pretendard-ExtraBold";
        margin-bottom: 20px;
        color: rgba(34, 34, 34, 1);
      }
      .spec-sub-title {
        font-size: 1.05rem;
        font-family: "Pretendard-Medium";
        margin-bottom: 50px;
        color: rgba(34, 34, 34, 1);
      }

      .bottom-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .position-wrapper {
          padding-left: 1rem;
          padding-right: 1rem;
          display: flex;
          width: 100%;
        }

        .horizontal-line {
          border-bottom: 1px solid #b4b4b4;
        }

        .top-content,
        .bottom-content {
          width: 100%;
          border-top: 2px solid #222222;
          border-bottom: 2px solid #222222;
          margin-bottom: 50px;
        }

        .bottom-content {
          border-left: 2px solid #b4b4b4;
        }

        .box-content {
          padding: 1rem;
          display: flex;
          flex-direction: column;
        }

        .box {
          display: flex;
          justify-content: space-between;
        }

        .box-title {
          font-size: 1.02rem;
          font-family: "Pretendard-Medium";
          margin-bottom: 28px;
          color: #222222;
        }

        .box-spec {
          font-size: 1.1rem;
          font-family: "Pretendard-ExtraBold";

          color: #222222;
        }

        .item-wrapper {
          display: flex;
          flex-direction: column;

          padding-left: 1rem;

          .item-container {
            display: flex;
            padding-right: 1rem;

            .item {
              font-size: 0.9rem;
              font-family: "Pretendard-ExtraBold";

              display: flex;
              flex-direction: column;
              width: 50%;
              margin-bottom: 20px;
              color: #646464;
              line-height: 15px;

              .item-padding-left {
                font-size: 0.9rem;
                font-family: "Pretendard-Medium";
                margin-top: 5px;
                padding-left: 0.7rem;
                color: #646464;
              }
            }
          }
        }
      }
    }

    .qualification {
      padding-left: 1rem;

      .first-qualification,
      .second-qualification {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
      }

      .first {
        font-size: 1.28rem;
        font-family: "Pretendard-ExtraBold";
        margin-bottom: 15px;
        color: #000000;
      }

      .second {
        font-size: 1.05rem;
        font-family: "Pretendard-Medium";
        line-height: 25px;
        color: #222222;
      }
    }
  }

  @media (min-width: 1400px) {
    .content-container {
      height: 50%;
      width: 100%;

      padding-top: 4rem;
      padding-bottom: 4rem;

      .title {
        color: white;
        font-size: 2.1rem;
        font-family: "Pretendard-ExtraBold";
        padding-left: 2rem;
      }

      .spec {
        padding-left: 1rem;

        padding-right: 1rem;
        padding-bottom: 1rem;

        .spec-title {
          font-size: 1.5rem;
          font-family: "Pretendard-ExtraBold";
          margin-top: 8px;
          margin-bottom: 0px;
        }

        .bottom-container {
          margin-top: 20px;
          flex-direction: row;
          height: 100%;

          .item-wrapper {
            width: 100%;

            .item-container {
              padding-right: 0rem;

              .item {
                /* border:1px s */
                font-size: 1rem;
                font-family: "Pretendard-ExtraBold";
                color: #444444;
                width: 100%;
                height: 60px;
                /* height:15%; */

                line-height: 15px;

                .item-padding-left {
                  font-size: 0.95rem;
                  font-family: "Pretendard-Medium";
                  width: 90%;
                  line-height: 20px;
                  margin-top: 10px;
                  color: #444444;
                }
              }

              .only-padding {
                padding-right: 5rem;

                .item-padding-left {
                  width: 120%;
                }
              }
            }
          }
        }
      }
    }
  }
`;
const DivImage = styled.div`
  width: 100%;
  height: 57rem;
  opacity: 0.95;
  background: url(${specImage}) no-repeat center;
  background-size: cover;

  @media (min-width: 1400px) {
    height: 50rem;
    width: 100%;
  }
`;

const BannerImagesContainer = styled.div`
  overflow: hidden;

  .dt-banner {
    display: none;
  }

  .tb-banner {
    display: none;
  }

  @media (min-width: 540px) {
    .tb-banner {
      display: block;
    }
    .mb-banner {
      display: none;
    }
  }
  @media (min-width: 765px) {
    .dt-banner {
      display: block;
    }

    .mb-banner {
      display: none;
    }
    .tb-banner {
      display: none;
    }
  }
`;

const OpenIcon = styled.img`
  margin-right: 20px;
  width: 55px;
  height: 55px;
`;

const K50 = () => {
  const [openHeight, setOpenHeight] = useState(false);

  const onOpenHeight = useCallback(() => {
    setOpenHeight((prev) => true);
  }, []);

  return (
    <DivWrapper>
      <DivBannerText>
        <div className="text-wrapper">
          <div className="text-container">
            <span className="first-text">Inverter</span>
            <span className="second-text">인버터</span>
            <div className="third-text">ESP50K</div>
            <div className="fourth-text">지락 차단장치 내장 인버터</div>
          </div>
          <div>{/* 간격을 맞추기 위해 의미 없는 div를 만듦 */}</div>
        </div>
        <img className="mb-machine" src={mbMachineImages} alt={'k50'}/>
        <img className="tb-machine" src={mbMachineImages} alt={'k50'}/>
      </DivBannerText>

      <DivBannerImageContainer>
        <DivBannerImage className="dt-banner" src={banner} alt={"k50"} />
      </DivBannerImageContainer>

      <SectionTextConatiner>
        <img className="sub-img" src={bannerSub} alt="mons" />
        <div className="text-container">
          <span className="first-text">ESP50K</span>
          <span className="second-text">[품질보증 7년]</span>
          <span className="third-text">
            지락 시공기준 만족<br></br>
            고효율 PWM 소자를 사용하여 효율 극대화<br></br>
            3상3선식 및 3상4선식 계통연계 가능 <br></br>
            4MPPT 방식의 입력구성으로 전기 생산량 증대<br></br>
            다수대 설치 가능 KIT 화 제품<br></br>
            스트링 및 IoT 단말기 APP 모니터링 기능 탑재<br></br>
            다양한 Array 구현 가능
          </span>
        </div>

        {/* 표 */}
        <div className="bottom-container">
          <div className="position-wrapper">
            <div className="top-content">
              <div className="box-content horizontal-line">
                <div className="box">
                  <div className="box-title">최대입력전력</div>
                  <div></div>
                </div>

                <div className="box">
                  <div></div>
                  <div className="box-spec">52,000W</div>
                </div>
              </div>
              <div className="box-content">
                <div className="box">
                  <div className="box-title">규격(mm)</div>
                  <div></div>
                </div>

                <div className="box">
                  <div></div>
                  <div className="box-spec">665X263X860</div>
                </div>
              </div>
            </div>

            <div className="bottom-content">
              <div className="box-content horizontal-line">
                <div className="box">
                  <div className="box-title">최대효율</div>
                  <div></div>
                </div>

                <div className="box">
                  <div></div>
                  <div className="box-spec">98% 이상</div>
                </div>
              </div>
              <div className="box-content">
                <div className="box">
                  <div className="box-title">중량</div>
                  <div></div>
                </div>

                <div className="box">
                  <div></div>
                  <div className="box-spec">60kg</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SectionTextConatiner>

      <div className="mobile-carousel">
        <ImageCarouselTwo />
      </div>

      <div className="desktop-carousel">
        <div
          className="img-container"
          src={feature}
          style={{ background: `url(${feature}) no-repeat center`, backgroundSize: "cover" }}
        />

        <div className="feature-wrapper">
          <div className="container">
            <div className="title">
              <span>Inverter</span>
              <span>인버터 제품특징</span>
            </div>
            <div className="contents">
              <div className="content">
                <OpenIcon src={icon01} />
                <span>
                  인버터 및 접속함<br></br>KS인증 취득
                </span>
              </div>
              <div className="content">
                <OpenIcon src={icon02} />
                <span>
                  한글 LCD 적용으로 누구나<br></br>인버터 상태 확인 가능
                </span>
              </div>
              <div className="content">
                <OpenIcon src={icon03} />
                <span>
                  에너지공단 보급사업<br></br>시공기준 만족
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SectionSpec>
        <div className={openHeight ? "vanish-open-height-button" : "open-height-button"} onClick={onOpenHeight}>
          <div className="clickable-button">VIEW MORE</div>
        </div>
        <DivImage className="dt-image" src={specImage} />
        <DivImage className={openHeight ? "mb-image" : "mb-image hide-height"} src={mbSpecImage} />
        <DivImageText className={openHeight ? "bottom-text-wrapper " : "bottom-text-wrapper hide-height"}>
          <div className="content-container">
            <span className="title">제품 상세 스펙</span>
            <div className="spec">
              <div className="spec spec-position">
                <span className="spec-title">ESP50K</span>
              </div>

              {/* 표 나오는 부분 */}
              <div className="bottom-container">
                {/* 브레이크포인트를 위해 div를 놔둠 */}
                <div className="item-wrapper">
                  {/* 개별 아이템 */}
                  <div className="item-container">
                    <div className="item">
                      <span>· 항목</span>
                      <span className="item-padding-left">ESP50K</span>
                    </div>
                    <div className="item">
                      <span>· MPPT 전압 범위</span>
                      <span className="item-padding-left">540~800V</span>
                    </div>
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· 최대 입력전압/최대입력전류</span>
                      <span className="item-padding-left">52,000W / 1,000V</span>
                    </div>
                    <div className="item">
                      <span>· MPPT 수 / MPPT당 입력수</span>
                      <span className="item-padding-left">4/3-2-3-2</span>
                    </div>
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· THD</span>
                      <span className="item-padding-left">종합 5% 이하</span>
                    </div>
                    <div className="item">
                      <span>· 최대출력 전류</span>
                      <span className="item-padding-left">80A</span>
                    </div>
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· 기동전압</span>
                      <span className="item-padding-left">250V</span>
                    </div>
                    <div className="item">
                      <span>· 정격출력</span>
                      <span className="item-padding-left">50,000W</span>
                    </div>
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· 동작전압 범위</span>
                      <span className="item-padding-left">200V~1,000V</span>
                    </div>
                    <div className="item">
                      <span>· 최대피상전력</span>
                      <span className="item-padding-left">50,000VA</span>
                    </div>
                  </div>
                </div>
                {/* 브레이크포인트를 위해 div를 놔둠 */}
                <div className="item-wrapper">
                  {/* 개별 아이템 */}
                  <div className="item-container">
                    <div className="item">
                      <span>· 상수</span>
                      <span className="item-padding-left">3상 3선식 및 3상 4선식</span>
                    </div>
                    <div className="item only-padding">
                      <span>· 인버터</span>
                      <span className="item-padding-left">
                        입력 과전압, 출력 단락 보호, 과부하, 인버터 과열, DC 출력 방지
                      </span>
                    </div>
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· 정격 출력범위</span>
                      <span className="item-padding-left">335V~419V(선간 전압)</span>
                    </div>
                    <div className="item only-padding">
                      <span>· 계통</span>
                      <span className="item-padding-left">
                        계통 과전압/저전압, 계통 저주파수/과주파수, 단독 운전 방지, 인버터 과전류
                      </span>
                    </div>
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· 정격 주파수</span>
                      <span className="item-padding-left">60Hz</span>
                    </div>
                    <div className="item only-padding">
                      <span>· 냉각 방식</span>
                      <span className="item-padding-left">강제 공냉식</span>
                    </div>
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· 주파수 범위</span>
                      <span className="item-padding-left">57.0Hz~60.5Hz</span>
                    </div>
                    <div className="item only-padding">
                      <span>· 설치 형태</span>
                      <span className="item-padding-left">옥외형</span>
                    </div>
                  </div>

                  <div className="item-container">
                    <div className="item">
                      <span>· 역률</span>
                      <span className="item-padding-left">0.99 이상</span>
                    </div>
                    <div className="item only-padding">
                      <span>· 동작온도/보존온도</span>
                      <span className="item-padding-left">-25℃~50℃ / -30℃~70℃</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DivImageText>
      </SectionSpec>
    </DivWrapper>
  );
};

export default K50;
