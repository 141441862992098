import React, { useEffect, useState } from "react";
import SubHeader from "../SubHeader";
import { Link } from "react-router-dom";

import styled from "@emotion/styled";
import monsLogo from "../../assets/header/mdf_white_logo.png";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import navyMainBanner from "../../assets/header/mdf_blue_logo.png";

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  .none-scroll {
    display: ${(props) => (props.resultOfHeaderShown ? "none" : "block")};
  }

  .scrolling {
    display: ${(props) => (props.resultOfHeaderShown ? "block" : "none")};
  }

  @media (min-width: 1100px) {
    max-width: 1100px;
    margin: 0 auto;
  }
`;

const CompanyLogoAndHeader = () => {
  const { pathname } = useLocation();

  const { headerHeight } = useSelector((state) => state.headerHeight);

  const [resultOfHeaderShown, setResultOfHeaderShown] = useState(false); // false ? ->메인페이지에서 아무 스크롤 하지 않았을 때,

  useEffect(() => {
    if (pathname === "/" && headerHeight === 0) {
      setResultOfHeaderShown((prev) => false);
    } else {
      setResultOfHeaderShown((prev) => true);
    }
  }, [headerHeight, pathname]);

  return (
    <Wrapper resultOfHeaderShown={resultOfHeaderShown}>
      <span className="company-logo" style={{ width: "230px" }}>
        <Link to="/">
          <img className="img-container none-scroll" alt="몬스솔라 로고" src={monsLogo} />
          <img className="img-container scrolling" alt="mons" src={navyMainBanner} />
        </Link>
      </span>

      <section className="menu-bar">
        <SubHeader />
      </section>
    </Wrapper>
  );
};

export default CompanyLogoAndHeader;
