import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import reducer from "./reducers";

const firstMiddleware = (store) => (next) => (action) => {
  next(action);
};

export default configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [firstMiddleware, ...getDefaultMiddleware()],
});
