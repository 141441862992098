import React from "react";
import styled from "@emotion/styled";

const DivWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  color: #707070;
`;

const DivLeftArrows = styled.div`
  display: flex;

  .first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 18px;
    cursor: pointer;
  }

  .second-child {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    cursor: pointer;
  }
`;

const DivMiddlePageIndex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  /* 페이지 인덱스가 클릭되는 것만 애니메이션 효과주기 */
  .clicked {
    border-radius: 100%;
    color: white;
    background-color: black;
    transition: all 0.3s linear;
  }
`;

const DivRightArrows = styled.div`
  display: flex;

  .first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 18px;
    cursor: pointer;
  }
  .second-child {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    cursor: pointer;
  }
`;

const Pagination = ({
  crawlResult,
  currentPageNumber,
  onClickPageLastAndFirst,
  onClickPageArrowPrevAndNext,
  onClickPageNumber,
}) => {
  return (
    <DivWrapper>
      {/* 왼쪽 화살표들 */}
      <DivLeftArrows>
        <div className="first-child" onClick={onClickPageLastAndFirst}>
          &lt;&lt;
        </div>
        <div className="second-child" onClick={onClickPageArrowPrevAndNext}>
          &lt;
        </div>
      </DivLeftArrows>

      {/* 숫자로 된 페이지 넘버링 */}
      <DivMiddlePageIndex>
        {crawlResult.map((_, index) => (
          <div className={currentPageNumber === index ? "clicked" : ""} key={index} onClick={onClickPageNumber}>
            {index + 1}
          </div>
        ))}
      </DivMiddlePageIndex>

      {/* 오른쪽 화살표들 */}
      <DivRightArrows>
        <div className="second-child" onClick={onClickPageArrowPrevAndNext}>
          &gt;
        </div>
        <div className="first-child" onClick={onClickPageLastAndFirst}>
          &gt;&gt;
        </div>
      </DivRightArrows>
    </DivWrapper>
  );
};

export default Pagination;
